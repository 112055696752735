import React, { useEffect, useState } from 'react';

export const SetPasswordForm = ({ email, setEmail, password, setPassword, confirmPassword, setConfirmPassword, pinCode, setPinCode, errorMessage, handleVerify }) => {
    const [passwordMatch, setPasswordMatch] = useState(true);

    useEffect(() => {
        if (confirmPassword !== password) {
            setPasswordMatch(false)
        } else {
            setPasswordMatch(true)
        }
    }, [password, confirmPassword])

    return (
        <form
            className='login-container'
            onSubmit={(e) => {
                e.preventDefault()
                if (passwordMatch) {
                    handleVerify();
                }
            }}
        >
            <div>Email address</div>
            <input
                required
                className='input-text mt10'
                type='email'
                placeholder='Email address'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <div className='mt10'>Password</div>
            <input
                required
                className='input-text mt10'
                type='password'
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <div className='mt10'>Confirm Password</div>
            <input
                required
                type='password'
                className={`input-text mt10 ${!passwordMatch && 'bRed oRed'}`}
                placeholder='Confirm Password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className='mt10'>Pin Code</div>
            <input
                required
                className='input-text mt10'
                type='text'
                placeholder='Pin Code'
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)}
            />

            <button
                type='submit'
                className='primary-button mt20'
            >
                Send
            </button>
            {errorMessage !== '' && <div className='error-message mt10'>{errorMessage}</div>}
        </form>
    )
}