

const getToken = async () => {
    return JSON.parse(localStorage.getItem('auth'));
}

export const createHotelProfile = async (hotel_sub, hotelProfile, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/profiles/${hotel_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "POST",
        body: JSON.stringify(hotelProfile)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getHotelProfileList = async (hotel_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/profiles/${hotel_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getHotelProfile = async (sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/profiles/profile/${sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const updateHotelProfile = async (sub, hotelProfile, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/profiles/profile/${sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "PATCH",
        body: JSON.stringify(hotelProfile)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const deleteHotelProfile = async (hotel_profile_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/profiles/profile/${hotel_profile_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "DELETE",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};