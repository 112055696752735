import React from 'react'

import logoTextblack from '../../assets/logos/logo-text-black.svg'
import { Link } from 'react-router-dom'
import './../../iltm2023.css'
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer'
import welcomeText from '../../assets/iltm2023/welcomeText.svg'

export const WelcomeIltm = ({ setActiveTab }) => {
    const videoSource =
        'https://storage.googleapis.com/mhm-medias/bo-hotels/videos/f988b85d-ca75-49f9-94a6-80891ad55680_120f19b8-d867-4006-94cb-5b113167c59c'

    return (
        <div className='column-view justify-between' style={{ padding: '2vw', height: '90vh' }}>
            <div className='flex02'>
                <div className="column-view items-center flex03 mt20">
                    <img
                        src={logoTextblack}
                        alt="logoTextWhite"
                        style={{ width: '14rem' }}
                    />
                </div>
            </div>
            <div className='flex1 row-view wrap'>
                <div className='column-view flex1 justify-center items-center mt20'>
                    <img src={welcomeText} alt="welcomeText" style={{ width: '40vh' }} />
                </div>
                <div className='column-view flex1 justify-center items-center mt20'>
                    <VideoPlayer videoSource={videoSource} style={{ width: '40vh' }} />
                </div>
            </div>
            <div className='flex01 column-view text-center'>
                <div className='row-view justify-center'>
                    <Link
                        className="primary-button none-decoration-link row-view items-center mt50"
                        style={{ borderRadius: '25px', paddingLeft: 100, paddingRight: 100 }}
                        onClick={() => setActiveTab(1)}
                    >
                        <div>Continue</div>
                    </Link>
                </div>
                <div className='mt20'>
                    By continuing, you agree to the{' '}
                    <a
                        href="https:mymatchingcompany.com/mentions-legales/"
                        target="_blank"
                        rel="noreferrer"
                        className="link"
                    >
                        Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                        href="https:mymatchingcompany.com/mentions-legales/"
                        target="_blank"
                        rel="noreferrer"
                        className="link"
                    >
                        Privacy policy
                    </a>
                </div>
                <div className="mt20">All rights reserved.</div>
                <div>©Myhotelmatch {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}
