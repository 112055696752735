import React from 'react'

import logoTextblack from '../../assets/logos/logo-text-black.svg'
import './../../iltm2023.css'
import heart from '../../assets/iltm2023/Heart.svg'
import thanks from '../../assets/iltm2023/Thank.svg'

export const ThanksIltmAmadeus = () => {

    return (
        <div className='column-view justify-between' style={{ padding: '2vw', height: '90vh' }}>
            <div className='flex02'>
                <div className="column-view items-center flex03 mt20">
                    <img
                        src={logoTextblack}
                        alt="logoTextWhite"
                        style={{ width: '14rem' }}
                    />
                </div>
            </div>
            <div className='flex1 row-view justify-center'>
                <div className='column-view justify-center items-center text-center'>
                    <img src={heart} alt="welcomeText" style={{ width: '35vh' }} />
                    <img src={thanks} alt="welcomeText" style={{ width: '35vh' }} />
                    <div className='mt50 bold'>
                        We have taken your information into account.
                    </div>
                    <div className='mt10 bold'>
                        As soon as you return to the office, you'll receive an e-mail containing your back-office login details!
                    </div>
                </div>
            </div>
            <div className='flex01 column-view text-center'>
                <a
                    className="primary-text-color text-underline size2"
                    rel='noreferrer'
                    href='https:myhotelmatch.com'
                    target="_blank"
                >
                    <div>Go to MyHotelMatch</div>
                </a>
            </div>
        </div>
    )
}
