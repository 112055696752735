import React, { useState } from 'react';
import { ProgressLine } from '../ProgressLine/ProgressLine';
import { PiUserRectangleLight } from 'react-icons/pi'
import chatGptIcon from '../../assets/icons/ChatGPT.svg'
import colors from '../../colors/colors';

export const ReviewCard = ({ review }) => {
    const [respond, setRespond] = useState(false);

    return (
        <div className='column-view border-container r8 p20 mb20'>
            <div className='row-view justify-between flex1'>
                <div className='column-view flex03 justify-between'>
                    <div>
                        <div className='row-view'>
                            <div className='p20 bgPrimary r8 white-text bold mr20 h100'>{(review.score.staff + review.score.equipments) / 2}</div>
                        </div>
                        <div className='mt20'>
                            <ProgressLine
                                rate={review.score.staff}
                                text="Staff of the hotel"
                            />
                            <ProgressLine
                                rate={review.score.equipments}
                                text="Equipments and services"
                            />
                        </div>
                    </div>
                    <div>
                        <div className='subtitle black-text mt50'>{review.title}</div>
                        <div className='section-subtitle'>{review.author} <span className='bold'>- {review.room}</span></div>
                    </div>
                </div>
                <div className='v-separator bg161616' />
                <div className='column-view flex06 justify-between'>
                    <divv className='paragraph'>{review.comment}</divv>
                    <div className='row-view justify-between items-center'>
                        <div className='date'>Posted {review.date}</div>
                        {!respond &&
                            <div div className='row-view items-center'>
                                <button className='row-view items-center secondary-button borderless h100'>
                                    <PiUserRectangleLight size={25} className='mr5' color={colors.primary} />
                                    <div className='primary-text-color'>Share with my staff</div>
                                </button>
                                <button
                                    className='primary-button h100'
                                    onClick={() => setRespond(true)}
                                >
                                    Respond
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                respond &&
                <div className='column-view mt20'>
                    <div className='bg161616 h-separator mt20' />
                    <div className='column-view flex1 mt20'>
                        <div className='subtitle black-text'>Your property response</div>
                        <textarea
                            rows={4}
                            className='input-text mt10'
                            placeholder='Reply in either English or the guest’s language (French)'
                        />
                    </div>
                    <div className='column-view flex06 justify-between mt20'>
                        <div className='row-view justify-between items-center'>
                            <div className='row-view items-center cursor-pointer'>
                                <img
                                    src={chatGptIcon}
                                    alt='chatGptIcon'
                                    className='mr5'
                                />
                                <div className='subtitle'>Respond with ChatGPT</div>
                            </div>
                            <div className='row-view items-center'>
                                <button
                                    className='row-view items-center secondary-button primary-text-color borderless h100'
                                    onClick={() => setRespond(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className='primary-button h100'
                                    onClick={() => setRespond(true)}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}