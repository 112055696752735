import React, { useCallback, useEffect, useRef, useState } from 'react';
import useHotelApi from '../../api/hotel';

import { MdCheck, MdClose, MdFilterAlt, MdSave, MdSearch } from 'react-icons/md';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { Table } from '../../components/Table/Table';
import { Link, useNavigate } from 'react-router-dom';

import { Pagination } from '../../components/Generic/Paginations';
import colors from '../../colors/colors';
import { FaHotel } from 'react-icons/fa6';
import { CustomAlert } from '../../components/Generic/CustomAlert';
import { Loader } from '../../components/Generic/Loader';

const tabs = [
    'Hotels',
    'New hotels',
    'Requests',
];

const cells = [
    'Name',
    'Owner',
    'Manager',
    'City',
    'Zip code',
    'Address',
    'Website',
    'Active'
];

const newHotelsCells = [
    'Hotel',
    'amadeus',
    'Name',
    ''
];

const requestsCells = [
    'Hotel',
    'Name',
    'Role',
    ''
];

export const Hotels = ({ handleLogout }) => {
    const { getHotelListLazy, activeInactiveHotels, getHotelRequests, getNewHotelList } = useHotelApi();
    const navigate = useNavigate();
    const filterRef = useRef(null);

    const myRoles = JSON.parse(localStorage.getItem('my_roles'));

    const [filter, setFilter] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [isFadingOut, setIsFadingOut] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [hotels, setHotels] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hotelsCount, setHotelsCount] = useState(0);
    const [newHotelList, setNewHotelList] = useState([]);
    const [newHotelListCount, setNewHotelListCount] = useState(0);
    const [requests, setRequests] = useState([]);
    const [requestsCount, setRequestsCount] = useState(0);
    const queryParams = new URLSearchParams(window.location.search);
    const [currentPage, setCurrentPage] = useState(parseInt(queryParams.get("page")));
    const [hotelsPerPage, setHotelsPerPage] = useState(parseInt(queryParams.get("limit")));
    const [searchText, setSearchText] = useState(queryParams.get("search"));
    const [pagesNb, setPagesNb] = useState(0);
    const [edit, setEdit] = useState(false);
    const [updates, setUpdates] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    const formatData = (data) => {
        let res = [];
        data.forEach((h) => {
            res.push({
                'id': h?.sub,
                'img': h?.logo || null,
                'name': h?.name ?? '',
                'owner': h?.owner.length !== 0 ? `${h?.owner[0]?.firstname} ${h?.owner[0]?.lastname}` : '',
                'manager': h?.manager.length !== 0 ? `${h?.manager[0]?.firstname} ${h?.manager[0]?.lastname}` : '',
                'city': h?.address?.city ?? '',
                'zipcode': h?.address?.zipCode ?? '',
                'address': h?.address?.street ?? '',
                'website': h?.website ?? '',
                'active': h?.active
            });
        });
        return res;
    };

    const formatNewHotels = (data) => {
        let res = [];
        data.forEach((h) => {
            res.push({
                'hotel': h?.name,
                'amadeus': h?.amadeus,
                'id': h?.sub,
                'img': null,
                'name': `${h?.user?.firstname ?? ''} ${h?.user?.lastname ?? ''}`,
                'state': h?.state,
            });
        });
        return res;
    };

    const formatDataRequests = (data) => {
        let res = [];
        data.forEach((r) => {
            res.push({
                'hotel': r?.hotel?.name,
                'id': r?.sub,
                'img': null,
                'name': `${r?.user?.firstname ?? ''} ${r?.user?.lastname ?? ''}`,
                'role': r?.user?.roles?.[0],
                'state': r?.state
            });
        });
        return res;
    };

    const handleEdit = () => {
        if (updates?.length > 0) {
            activeInactiveHotels(updates, response => {
                if (response?.status !== 'success') {
                    alert(response?.message);
                    return;
                }
            });
        }
        navigate(`/hotels?page=${currentPage}&limit=${hotelsPerPage}&search=${searchText}`);
        retrieveHotels((currentPage - 1) * hotelsPerPage, hotelsPerPage, searchText);
        setEdit(false);
        setUpdates([]);
        setShowAlert(true);
        setTimeout(() => { setIsFadingOut(true); }, 3000);
    };

    useEffect(() => {
        if (isFadingOut) {
            setTimeout(() => {
                setShowAlert(false);
                setIsFadingOut(false);
            }, 500);
        }
    }, [isFadingOut]);

    const retrieveHotels = useCallback((offset, limit, search, active) => {
        getHotelListLazy(offset, limit, search, active, (response) => {
            setIsLoading(false);
            if (response.status === 'success') {
                setHotels(formatData(response.content.hotels));
                setHotelsCount(response.content.count);
                setPagesNb(Math.ceil(response?.content?.count / limit));
            }
        });
    }, []);

    const retrieveNewHotels = useCallback(() => {
        getNewHotelList(response => {
            setIsLoading(false);
            if (response.status === 'success') {
                setNewHotelList(formatNewHotels(response.content.requests));
                setNewHotelListCount(response.content.count);
            }
        });
    }, []);

    const retrieveRequests = useCallback(() => {
        getHotelRequests(response => {
            setIsLoading(false);
            if (response.status === 'success') {
                setRequests(formatDataRequests(response.content.requests));
                setRequestsCount(response.content.count);
            }
        });
    }, []);

    useEffect(() => {
        let token = (localStorage.getItem('auth'));
        if (token) {
            const expiration = JSON.parse(localStorage.getItem('expiration'));
            if (expiration < Date.now() / 1000) {
                handleLogout();
            }
        }
    }, [handleLogout]);

    useEffect(() => {
        if (activeTab === 0) {
            let page = 1;
            let limit = 10;
            if (currentPage > 1) {
                page = currentPage;
            }
            if (hotelsPerPage > 0 && hotelsPerPage < 26) {
                limit = hotelsPerPage;
            }
            setCurrentPage(page);
            setHotelsPerPage(limit);
            navigate(`/hotels?page=${page}&limit=${limit}&search=${searchText}`);
            retrieveHotels((page - 1) * limit, limit, searchText);
        } else if (activeTab === 1) {
            retrieveNewHotels();
        } else if (activeTab === 2) {
            retrieveRequests();
        }
    }, [currentPage, hotelsPerPage, navigate, retrieveHotels, searchText, activeTab, retrieveRequests, retrieveNewHotels]);

    useEffect(() => {
        let active = null;
        if (filter?.includes('active')) {
            active = true;
        } else if (filter?.includes('inactive')) {
            active = false;
        }
        retrieveHotels((currentPage - 1) * hotelsPerPage, hotelsPerPage, searchText, active);
    }, [filter]);

    useEffect(() => {
        if (isSearching) {
            navigate(`/hotels?page=${0}&limit=${hotelsPerPage}&search=${searchText}`);
            retrieveHotels(0, hotelsPerPage, searchText);
        }
    }, [hotelsPerPage, isSearching, navigate, retrieveHotels, searchText]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (filterOpen && filterRef.current && !filterRef.current.contains(event.target)) {
                setFilterOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [filterOpen, filterRef]);

    return (
        <>
            {isLoading ?
                <div className='row-view flex1 justify-center items-center'>
                    <Loader />
                </div>
                :
                <div className='page-container'>
                    {showAlert && (
                        <div className={`row-view alert-container justify-center ${isFadingOut ? 'fade-out-from-bottom' : 'fade-in-from-top'}`}>
                            <CustomAlert
                                message="Hotels updated successfully!"
                                handleClose={() => {
                                    setIsFadingOut(true);
                                }}
                            />
                        </div>
                    )}
                    <div className='row-view justify-between'>
                        <div>
                            <div className='size2 bold'>{activeTab === 0 && 'Hotels'}{activeTab === 1 && 'New Hotels'}{activeTab === 2 && 'Requests'}</div>
                            <div className='size1 bold today'><span>{activeTab === 0 && `${hotelsCount} Hotels`}{activeTab === 1 && `${newHotelListCount} New Hotels`}{activeTab === 2 && `${requestsCount} Requests`}</span></div>
                        </div>
                    </div>

                    <div className='row-view justify-between mt50'>
                        {myRoles?.includes('admin') ?
                            <div className='row-view'>
                                {tabs.map((tab, index) => {
                                    return (
                                        <div key={index} className="row-view pb20">
                                            <div
                                                className={`mr50 cursor-pointer pv4 ${activeTab === index && 'black-text bold bb161616'}`}
                                                onClick={() => setActiveTab(index)}
                                            >
                                                <div className={`mr5`}>
                                                    {tab}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            :
                            <div className='row-view'>
                                <div className="row-view pb20">

                                </div>
                            </div>
                        }
                        {activeTab === 0 &&
                            <div className='row-view items-center'>
                                <div className={`mr10 ${edit && 'opacity60'}`}>
                                    <div className='relative' style={{ paddingTop: "2%", paddingBottom: "2%", display: "inline-block" }}>
                                        <div className='w160'>
                                            <div className='row-view justify-center items-center f-size16 cursor-pointer' onClick={() => !edit && setFilterOpen(true)}>
                                                <div>
                                                    <MdFilterAlt color={colors.secondary} size={20} className='mr5' />
                                                </div>
                                                <div
                                                    className='secondary-text-color'
                                                >
                                                    <div>Filter</div>
                                                </div>
                                            </div>
                                            {filter?.length > 0 &&
                                                <div className='white-text' style={{ position: 'absolute', textAlign: 'center', top: 0, right: 0, width: 20, height: 20 }}>
                                                    <div style={{ backgroundColor: 'red', borderRadius: 100 }}>{filter?.length}</div>
                                                </div>
                                            }
                                        </div>
                                        <div className={`select-menu ${filterOpen && 'open'}`} ref={filterRef}>
                                            <div
                                                className='bgFAFAFA row-view items-center p20 b r8 cursor-pointer'
                                                onClick={() => {
                                                    let arr = filter;
                                                    if (arr.includes('active')) {
                                                        arr = arr.filter(el => el !== 'active');
                                                    } else {
                                                        arr = [...arr, 'active'];
                                                    }
                                                    if (arr.includes('inactive')) {
                                                        arr = arr.filter(el => el !== 'inactive');
                                                    }
                                                    setFilter(arr);
                                                }}
                                            >
                                                <div className={`checkbox r4 mr10 ${filter?.includes('active') && 'selected'}`}>
                                                    {filter?.includes('active') && <MdCheck color='white' />}
                                                </div>
                                                <div>Acive</div>
                                            </div>
                                            <div
                                                className='bgFAFAFA row-view items-center p20 b r8 cursor-pointer'
                                                onClick={() => {
                                                    let arr = filter;
                                                    if (arr.includes('inactive')) {
                                                        arr = arr.filter(el => el !== 'inactive');
                                                    } else {
                                                        arr = [...arr, 'inactive'];
                                                    }
                                                    if (arr.includes('active')) {
                                                        arr = arr.filter(el => el !== 'active');
                                                    }
                                                    setFilter(arr);
                                                }}
                                            >
                                                <div className={`checkbox r4 mr10 ${filter?.includes('inactive') && 'selected'}`}>
                                                    {filter?.includes('inactive') && <MdCheck color='white' />}
                                                </div>
                                                <div>Inactive</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`row-view mr10 ${edit && 'opacity60'}`}>
                                    <div className='secondary-search-bar-container row-view items-center justify-center'>
                                        <div className='mr10'><MdSearch size={20} /></div>
                                        <input
                                            type='text'
                                            value={searchText}
                                            placeholder='Search'
                                            className='secondary-search-bar'
                                            disabled={edit}
                                            onChange={(e) => {
                                                setIsSearching(true);
                                                setSearchText(e.target.value);
                                                setTimeout(() => {
                                                    setIsSearching(false);
                                                }, 300);
                                            }}
                                        />
                                    </div>
                                </div>
                                {edit ?
                                    <div className='row-view justify-center items-center f-size16 cursor-pointer w160 mr10'>
                                        <div
                                            className='row-view mr20'
                                            onClick={() => {
                                                setEdit(false);
                                                setUpdates([]);
                                            }}>
                                            <MdClose color={colors.secondary} size={20} className='mr5' />
                                            <div>Cancel</div>
                                        </div>
                                        <div className='row-view' onClick={() => handleEdit()}>
                                            <MdSave color={colors.secondary} size={20} className='mr5' />
                                            <div>Save</div>
                                        </div>
                                    </div>
                                    :
                                    <div className='row-view cursor-pointer mr10' onClick={() => setEdit(true)}>
                                        <FaHotel color={colors.secondary} size={20} className='mr5' />
                                        <div>Manage hotels</div>
                                    </div>
                                }
                                <div className='row-view'>
                                    {((myRoles.includes('admin') || myRoles.includes('chainManager') || myRoles.includes('hotelManager') || myRoles.includes('owner')) && (
                                        <Link
                                            className='primary-button none-decoration-link row-view items-center'
                                            to={{ pathname: '/hotels/add' }}
                                        >
                                            <AiOutlinePlusCircle color='white' size={20} className='mr10' />
                                            <div>Add Hotel</div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        }

                    </div>
                    <div className='column-view flex1'>
                        {activeTab === 0 &&
                            <div>
                                <div className='of-scroll mt20'>
                                    <Table cells={cells} data={hotels.length !== 0 ? hotels : 'No hotels yet...'} type='hotels' edit={edit} currentPage={currentPage} updates={updates} setUpdates={setUpdates} />
                                </div>
                                <Pagination
                                    type='Hotels'
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    itemsPerPage={hotelsPerPage}
                                    pagesNb={pagesNb}
                                    setItemsPerPage={setHotelsPerPage}
                                    path='hotels'
                                    retrieveData={retrieveHotels}
                                    searchText={searchText}
                                />
                            </div>
                        }
                    </div>
                    <div className='column-view flex1'>
                        {activeTab === 1 &&
                            <div className='of-scroll mt20'>
                                <Table cells={newHotelsCells} data={newHotelList.length !== 0 ? newHotelList : 'No new hotels yet...'} type='newHotels' retrieveData={retrieveNewHotels} />
                            </div>
                        }
                    </div>
                    <div className='column-view flex1'>
                        {activeTab === 2 &&
                            <div className='of-scroll mt20'>
                                <Table cells={requestsCells} data={requests.length !== 0 ? requests : 'No requests yet...'} type='hotelRequests' retrieveData={retrieveRequests} />
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
};