import React, { useEffect, useRef, useState } from "react";
import avatarImg from "../../assets/icons/Avatar.svg";

import { AiOutlineClose } from "react-icons/ai";
import { MdChevronLeft, MdSend } from "react-icons/md";
import { BiSolidChevronDown } from "react-icons/bi";
import moment from "moment/moment";
import { Messages } from "./Messages";
import {
    getConversation,
    getConversationList,
    sendMessage,
} from "../../api/conversation";
import socket from "../../socket";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useHotelApi from "../../api/hotel";
import colors from "../../colors/colors";

export const Discussions = ({ handleLogout }) => {
    const { getHotelListLazy, getHotelMatches } = useHotelApi();
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const [isLoading, setIsLoading] = useState(false);
    const [endOfMessages, setEndOfMessages] = useState(false);
    const [offset, setOffset] = useState(0);
    const [message, setMessage] = useState("");
    const [discussions, setDiscussions] = useState([]);
    const [selectedDiscussion, setSelectedDiscussion] = useState(null);
    const [activeDiscussion, setActiveDiscussion] = useState(null);
    const [activeTab, setActiveTab] = useState("all");
    const [mySub, setMySub] = useState(JSON.parse(localStorage.getItem("sub")));
    const chatContainerRef = useRef();
    const [fetchMessages, setFetchMessages] = useState(false);
    const [matches, setMatches] = useState([]);
    const [hotel, setHotel] = useState({});
    const [hotels, setHotels] = useState([]);
    const [hotelsOpen, setHotelssOpen] = useState(false);
    const hotelsRef = useRef(null);
    const [hotelsSearch, setHotelsSearch] = useState("");
    const [reloadKey, setReloadKey] = useState(0); // Utilisé pour forcer le rechargement de la partie du code

    useEffect(() => {
        setIsLoading(true);
        if (activeDiscussion !== null) {
            socket.emit("join", { room: selectedDiscussion });
            retrieveConversation(selectedDiscussion)
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset, selectedDiscussion]);


    function debounce(func, timeout = 2000) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    function saveInput() {
        setOffset((prevOffset) => prevOffset + 10);
        setFetchMessages(true);
    }

    const debouncedRetrieve = debounce(saveInput);

    const retrieveConversationList = () => {
        getConversationList((response) => {
            if (response?.status === "success") {
                setDiscussions(
                    response.content?.sort(
                        (a, b) =>
                            a?.messages[a?.messages?.length - 1]?.timestamp -
                            b?.messages[b?.messages?.length - 1]?.timestamp
                    )
                );
            }
        });
    };

    useEffect(() => {
        retrieveConversationList();
    }, []);

    useEffect(() => {
        let token = localStorage.getItem("auth");
        if (token) {
            const expiration = JSON.parse(localStorage.getItem("expiration"));
            if (expiration < Date.now() / 1000) {
                handleLogout();
            }
        }

        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, [handleLogout]);

    const retrieveHotels = (search) => {
        getHotelListLazy(0, 10, search, undefined, (response) => {
            if (response?.status === "success") {
                let hotels = [{ name: "All Hotels" }, ...response?.content?.hotels];
                setHotels(hotels);
                setHotel(response?.content?.hotels?.[0]);
            }
        });
    };

    useEffect(() => {
        retrieveHotels(hotelsSearch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getHotelMatches(hotel?.sub, (response) => {
            if (response?.status === "success") {
                setMatches(response?.content);
            }
        });
    }, [hotel]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                hotelsOpen &&
                hotelsRef.current &&
                !hotelsRef.current.contains(event.target)
            ) {
                setHotelssOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [hotelsOpen, hotelsRef]);

    const retrieveConversation = (d) => {
        getConversation(d, offset, (response) => {
            setIsLoading(false);
            if (response?.status === "success") {
                if (response?.content?.length === 0) {
                    setEndOfMessages(true);
                    return;
                }
                if (
                    activeDiscussion !== null &&
                    activeDiscussion.hasOwnProperty("messages")
                ) {
                    setActiveDiscussion((prevActiveDiscussion) => ({
                        ...prevActiveDiscussion,
                        messages: [...response?.content, ...prevActiveDiscussion?.messages],
                    }));
                } else {
                    setActiveDiscussion((prevActiveDiscussion) => ({
                        ...prevActiveDiscussion,
                        messages: response?.content,
                    }));
                }
            }
        });
    };

    useEffect(() => {
        socket.on("chat_message", (m) => {
            try {
                setActiveDiscussion((prevActiveDiscussion) => ({
                    ...prevActiveDiscussion,
                    messages: [...prevActiveDiscussion?.messages, m],
                }));
            } catch {
                setActiveDiscussion((prevActiveDiscussion) => ({
                    ...prevActiveDiscussion,
                    messages: [m],
                }));
            }

            retrieveConversationList();
            setFetchMessages(false);
        });
        return () => {
            socket.off("message");
        };
    }, []);


    const send = (sub) => {
        let messageObject = {
            message,
        };
        sendMessage(sub, messageObject, (response) => {
            if (response?.message === 'Message sent.') {
                // Si l'envoi via l'API est réussi, émettre le message via socket.io
                socket.emit("chat_message", { message: message, room: sub });
                // Mettre à jour la liste des conversations et réinitialiser le champ de message
                retrieveConversationList();
                setMessage("");
                setFetchMessages(false);
            }
        });
    };

    useEffect(() => {
        if (endOfMessages) return;
        const chatContainer = chatContainerRef.current;

        const handleScroll = () => {
            if (chatContainer?.scrollTop === 0) {
                debouncedRetrieve()
                const chatContainer = chatContainerRef.current;
                if (chatContainer) {
                    if (!endOfMessages) chatContainer.scrollTop += 80;
                }
            }
        };

        // const loadMoreMessages = () => {
        //     setOffset((prevOffset) => prevOffset + 10);
        //     setFetchMessages(true);
        // };

        chatContainer?.addEventListener("scroll", handleScroll);

        return () => {
            chatContainer?.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDiscussion]);

    return (
        // <div className='page-container'>
        <div className="discussion-container column-view flex-grow m20">
            {windowSize > 965 && (
                <div className="row-view justify-between">
                    <div>
                        <div className="size2 bold">Discussions</div>
                        <div className="size1 bold today">
                            <span>Inbox</span>
                        </div>
                    </div>
                    <div className="row-view">
                        {/* <div className='row-view'>
                        <button className='secondary-button borderless row-view items-center'>
                            <div className='mr5'><TbFilterPlus /></div>
                            <div>
                                Filter
                            </div>
                        </button>
                        <div className='secondary-search-bar-container row-view items-center justify-center'>
                            <div className='mr10'><MdSearch size={20} /></div>
                            <input type='text' placeholder='Search reservations' className='secondary-search-bar' />
                        </div>
                    </div> */}
                    </div>
                </div>
            )}

            {/* <div className={`row-view ${windowSize > 965 && 'mt50'}`}>
                <div
                    className={`row-view items-center mr20 cursor-pointer pv4 ${activeTab === 'all' && 'black-text bold bb161616'}`}
                    onClick={() => setActiveTab('all')}
                >
                    <div className={`mr5`}>
                        All messages
                    </div>
                    {discussions && discussions?.filter(el => el.messages[el?.messages?.length - 1]?.state !== 'Read' && el.messages[el?.messages?.length - 1]?.sender_sub !== mySub).length !== 0 && <div className="notif">{discussions?.filter(el => el.messages[el?.messages?.length - 1]?.state !== 'Read' && el.messages[el?.messages?.length - 1]?.sender_sub !== mySub).length}</div>}
                </div>
                <div className={`row-view items-cente mr20 cursor-pointer pv4 ${activeTab === 'starred' && 'black-text bold bb161616'}`}
                    onClick={() => setActiveTab('starred')}
                >
                    <div className={`mr5`}>
                        Starred
                    </div>
                    {starredDiscussions && starredDiscussions.length !== 0 && <div className="notif">{starredDiscussions.length}</div>}
                </div>
            </div> */}

            {(windowSize < 957 && !activeDiscussion) || windowSize > 965 ? (
                <div>
                    <div
                        className="relative"
                        style={{
                            paddingTop: "2%",
                            paddingBottom: "2%",
                            display: "inline-block",
                        }}
                    >
                        {hotelsOpen ? (
                            <input
                                type="text"
                                value={hotelsSearch}
                                className="input-text w300"
                                autoFocus
                                onChange={(e) => {
                                    setHotelsSearch(e.target.value);
                                    retrieveHotels(e.target.value);
                                }}
                            />
                        ) : (
                            <div
                                className="border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer"
                                onClick={() => setHotelssOpen(!hotelsOpen)}
                            >
                                <div>{hotel?.name ? hotel?.name : "Select hotel"}</div>
                                <BiSolidChevronDown />
                            </div>
                        )}
                        <div
                            className={`select-menu ${hotelsOpen && "open"}`}
                            style={{ maxHeight: 300, overflow: "scroll" }}
                            ref={hotelsRef}
                        >
                            {hotels?.length !== 0 ? (
                                <>
                                    {hotels?.map((h, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="select-item"
                                                onClick={() => {
                                                    setHotel(h);
                                                    setHotelssOpen(false);
                                                }}
                                            >
                                                <span className="section-title">
                                                    {h?.name}
                                                    <span className="grey-text">
                                                        {" "}
                                                        - {h?.address?.city}
                                                    </span>
                                                </span>
                                            </div>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    <div className="select-item">No hotels found</div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="black-text bold">New matches</div>
                    <div className="row-view gap20 items-center unselectable ofx-scroll-hb mt10">
                        {matches?.length > 0 ? (
                            <>
                                {matches?.map((match, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="bgWhite cursor-pointer"
                                            onClick={() => {
                                                setOffset(0);
                                                setEndOfMessages(false);
                                                setActiveDiscussion({
                                                    participants: [
                                                        {
                                                            photo: match?.photo,
                                                            firstname: match?.firstname,
                                                            lastname: match?.lastname,
                                                            sub: match?.sub,
                                                        },
                                                    ],
                                                    messages: [],
                                                });
                                                setSelectedDiscussion(match?.sub);
                                            }}
                                        >
                                            <div className="column-view justify-center items-center">
                                                {match?.photo ? (
                                                    <img
                                                        src={match?.photo}
                                                        alt={index}
                                                        className={``}
                                                        style={{
                                                            width: 120,
                                                            height: 120,
                                                            objectFit: "cover",
                                                            borderRadius: 8,
                                                        }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={avatarImg}
                                                        alt="avatar"
                                                        className={``}
                                                        style={{ width: 80 }}
                                                    />
                                                )}
                                                <div className="f-size16 wrap bold">
                                                    {match?.firstname} {match?.lastname}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <div>You don't have new matches...</div>
                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}

            <div
                className={`row-view items-center mr20 cursor-pointer pv4 mt20 black-text bold`}
                onClick={() => setActiveTab("all")}
            >
                <div className={``}>Messages</div>
            </div>

            {windowSize > 965 ? (
                <div className="row-view border-container mt20 r8 h70p">
                    <div
                        className={`brDADADA of-scroll ofx-hidden p10 ${windowSize > 1400 && "flex1"
                            }`}
                    >
                        {activeTab === "all" ? (
                            <div>
                                <div>
                                    {discussions?.map((d, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className={`row-view p10 cursor-pointer r8 ${d?.sub === activeDiscussion?.sub && "bgFAFAFA"
                                                    } ${windowSize < 1400
                                                        ? "column-view text-center"
                                                        : "row-view"
                                                    }`}
                                                onClick={() => {
                                                    if (activeDiscussion?.sub !== d?.sub) {
                                                        setOffset(0);
                                                        setEndOfMessages(false);
                                                        setActiveDiscussion(d);
                                                        setSelectedDiscussion(d?.sub);
                                                    }
                                                }}
                                            >
                                                <div
                                                    className={`r8 items-center relative ${windowSize > 1400 ? "mr10" : "ml10"
                                                        }`}
                                                >
                                                    {/* {(d?.messages?.length !== 0 && d?.messages[d?.messages?.length - 1]?.state !== 'Read' && d?.messages[d?.messages?.length - 1]?.sender_sub !== mySub) && <div className="notif absolute top-8px right-8px">{d?.messages?.filter(el => el?.state !== 'Read').length}</div>} */}
                                                    {d?.participants?.[0]?.photo ? (
                                                        <img
                                                            src={d?.participants?.[0]?.photo}
                                                            alt={i}
                                                            style={{
                                                                width: 80,
                                                                height: 80,
                                                                objectFit: "cover",
                                                                borderRadius: 8,
                                                            }}
                                                            className={`${windowSize < 1200 && "w40"}`}
                                                        />
                                                    ) : (
                                                        <img
                                                            src={avatarImg}
                                                            alt="avatar"
                                                            className={`${windowSize < 1200 && "w40"}`}
                                                        />
                                                    )}
                                                </div>
                                                {windowSize < 1400 && (
                                                    <div
                                                        className={`flex1 subtitle black-text ${windowSize < 1600 && "f-size16"
                                                            }`}
                                                    >
                                                        {d?.participants?.[0]?.firstname}{" "}
                                                        {d?.participants?.[0]?.lastname}
                                                    </div>
                                                )}
                                                {windowSize > 1400 && (
                                                    <div className="column-view justify-between flex1 of-hidden">
                                                        <div>
                                                            <div className="row-view flex1">
                                                                <div
                                                                    className={`flex1 subtitle black-text ${windowSize < 1600 && "f-size16"
                                                                        }`}
                                                                >
                                                                    {d?.participants?.[0]?.firstname}{" "}
                                                                    {d?.participants?.[0]?.lastname}
                                                                </div>
                                                                <div
                                                                    className={`text-end of-ellipsis grey-text ${windowSize < 1600 && "f-size12"
                                                                        }`}
                                                                >
                                                                    {moment(
                                                                        d?.messages?.[d?.messages?.length - 1]
                                                                            ?.timestamp
                                                                    ).fromNow()}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={`f-size14 of-ellipsis grey-text mt5`}
                                                            >
                                                                {d?.messages?.[d?.messages?.length - 1]
                                                                    ?.message ?? "Start a conversation..."}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            <div>
                                {/* <div className={` ${adminOpen ? 'open' : 'mb20'}`}>
                                {starredDiscussions?.length === 0 && <div className='p20'>No starred discussions...</div>}
                                {starredDiscussions?.map((d, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className={`row-view p10 cursor-pointer ${d.conversation_sub === activeDiscussion?.conversation_sub && 'bgFAFAFA'}`}
                                            onClick={() => {
                                                // setActiveDiscussion(d)
                                                setMessages(d.messages)
                                            }}
                                        >
                                            <div className='relative row-view mr10 bgDADADA r8 items-center'>
                                                {(d.messages[d?.messages?.length - 1]?.state !== 'Read' && d.messages[d?.messages?.length - 1]?.sender_sub !== mySub) && <div className="notif absolute top-8px right-8px">{d?.messages?.filter(el => el?.state !== 'Read' && el?.sender_sub !== mySub).length}</div>}
                                                {d?.img ?
                                                    <img src={d?.img} alt={i} />
                                                    :
                                                    <img src={avatarImg} alt='avatar' />
                                                }
                                            </div>
                                            <div className='column-view justify-between flex1 of-hidden'>
                                                <div>
                                                    <div className='row-view flex1'>
                                                        <div className='flex1 subtitle black-text'>{d?.title}</div>
                                                        <div className='flex05 flex05 text-end of-ellipsis f-size14 grey-text'>{moment(d?.messages[d?.messages?.length - 1]?.timestamp).format('L')}</div>
                                                    </div>
                                                    {d?.role === 'traveler' ?
                                                        <div className='f-size14 bold grey-text'>{d?.reservation?.state} • {moment(d?.reservation?.checkin).format('MMMM')} {moment(d?.reservation?.checkin).format('DD')}-{moment(d?.reservation?.checkout).format('DD')} • {moment(d?.reservation?.checkout).diff(d?.reservation?.checkin)} nights</div>
                                                        :
                                                        <div className='f-size14 bold grey-text'>{d?.company_name}</div>
                                                    }
                                                </div>
                                                <div
                                                    className={`of-ellipsis ${(d?.messages[d?.messages?.length - 1]?.state !== 'Read' && d?.messages[d?.messages?.length - 1]?.sender_sub !== mySub) ? 'bold black-text' : 'grey-text'}`}
                                                >{d?.messages[d?.messages?.length - 1]?.content}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div> */}
                            </div>
                        )}
                    </div>

                    <div className={`row-view flex2 brDADADA`}>
                        {activeDiscussion ? (
                            <div className="column-view flex1">
                                <div className="row-view items-center justify-between p20 bb">
                                    <div className="row-view">
                                        <div className="row-view mr10 r8 items-center relative">
                                            {activeDiscussion?.participants?.[0]?.photo ? (
                                                <img
                                                    src={activeDiscussion?.participants?.[0]?.photo}
                                                    alt={activeDiscussion.sub}
                                                    style={{
                                                        width: 80,
                                                        height: 80,
                                                        objectFit: "cover",
                                                        borderRadius: 8,
                                                    }}
                                                    className={`${windowSize < 1200 && "w40"}`}
                                                />
                                            ) : (
                                                <img
                                                    src={avatarImg}
                                                    alt="avatar"
                                                    className={`${windowSize < 1200 && "w40"}`}
                                                />
                                            )}
                                        </div>
                                        <div className="row-view">
                                            <div>
                                                <div className="row-view flex1">
                                                    <div
                                                        className={`flex1 subtitle black-text ${windowSize < 1600 && "f-size16"
                                                            }`}
                                                    >
                                                        {activeDiscussion?.participants?.[0]?.firstname}{" "}
                                                        {activeDiscussion?.participants?.[0]?.lastname}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-view">
                                        {/* <BiDotsVerticalRounded size={25} /> */}
                                        <AiOutlineClose
                                            size={25}
                                            className="cursor-pointer"
                                            onClick={() => setActiveDiscussion(null)}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="flex1 of-scroll ofx-hidden"
                                    ref={chatContainerRef}
                                    key={reloadKey}
                                >
                                    <div className="row-view flex1 justify-center items-center mt20 mb20">
                                        {isLoading && <div className="loader w20 h20" />}
                                    </div>
                                    <Messages
                                        messages={activeDiscussion?.messages}
                                        mySub={mySub}
                                        fetchMessages={fetchMessages}
                                    />
                                </div>
                                <div className="flex01 justify-between items-center pb10 ph20">
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            if (message) {
                                                send(activeDiscussion?.sub);
                                            }
                                        }}
                                        className="row-view border-container r8 bgWhite flex1"
                                    >
                                        <input
                                            className="input-text bgWhite borderless none-outline flex09"
                                            type="text"
                                            placeholder="Type your message..."
                                            value={message}
                                            onInput={(e) => {
                                                setMessage(e.target.value);
                                            }}
                                        />
                                        <div className="row-view items-center flex01">
                                            {/* <PiSmiley className='flex1' size={25} /> */}
                                            <button
                                                type="submit"
                                                className="borderless bgTransparent"
                                            >
                                                <MdSend
                                                    className="flex1"
                                                    size={25}
                                                    color={colors.primary}
                                                />
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        ) : (
                            <div className="p20 grey-text">
                                Click on a discussion to open it..
                            </div>
                        )}
                    </div>

                    <div className="column-view rr8 bgFAFAFA">
                        <div className="flex1"></div>
                        <div className="flex01 justify-between items-center ph20">
                            <div className="row-view justify-between border-container r8 bgWhite">
                                <input
                                    className={`input-text bgWhite borderless none-outline p10 ${windowSize < 1400 && "f-size12"
                                        }`}
                                    type="text"
                                    placeholder="Comment message..."
                                />
                                <div className="row-view items-center">
                                    {/* <PiSmiley className='' size={20} /> */}
                                    <MdSend size={20} color={colors.primary} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="column-view flex-grow border-container mt10 r8">
                    {activeDiscussion ? (
                        <div className="column-view flex-grow">
                            <div className="flex01 row-view items-center p10 bb">
                                <div className="row-view">
                                    <MdChevronLeft
                                        size={30}
                                        className="cursor-pointer"
                                        onClick={() => setActiveDiscussion(null)}
                                    />
                                </div>
                                <div className="row-view">
                                    <div className="row-view mr10 r8 items-center relative">
                                        {activeDiscussion?.img ? (
                                            <img
                                                src={activeDiscussion?.img}
                                                alt={activeDiscussion.title}
                                                className={`${windowSize < 1200 && "w40"}`}
                                            />
                                        ) : (
                                            <img
                                                src={avatarImg}
                                                alt="avatar"
                                                className={`${windowSize < 1200 && "w40"}`}
                                            />
                                        )}
                                    </div>
                                    <div className="row-view">
                                        <div>
                                            <div className="row-view">
                                                <div
                                                    className={`flex1 subtitle black-text ${windowSize < 1600 && "f-size16"
                                                        }`}
                                                >
                                                    {activeDiscussion?.firstname}{" "}
                                                    {activeDiscussion?.lastname}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex05 bgFAFAFA bb"></div>
                            <div
                                className="ofx-hidden of-scroll"
                                style={{ height: 400 }}
                                ref={chatContainerRef}
                            >
                                <div>
                                    {/* <div className='column-view flex1 justify-center items-center mt20 mb20'> */}
                                    {isLoading && (
                                        <div className="row-view justify-center">
                                            <div className="loader w20 h20" />
                                        </div>
                                    )}
                                    <Messages
                                        messages={activeDiscussion?.messages}
                                        mySub={mySub}
                                        fetchMessages={fetchMessages}
                                    />
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="flex05 row-view justify-between items-center pb10">
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        send(activeDiscussion?.sub);
                                    }}
                                    className="row-view border-container r8 bgWhite flex1"
                                >
                                    <input
                                        className="input-text bgWhite borderless none-outline flex09"
                                        type="text"
                                        placeholder="Type your message..."
                                        value={message}
                                        onInput={(e) => {
                                            setMessage(e.target.value);
                                        }}
                                    />
                                    <div className="row-view items-center flex01">
                                        {/* <PiSmiley className='flex1' size={25} /> */}
                                        <button type="submit" className="borderless bgTransparent">
                                            <MdSend
                                                className="flex1"
                                                size={25}
                                                color={colors.primary}
                                            />
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : (
                        <div className="column-view flex-grow">
                            {activeTab === "all" && (
                                <div>
                                    <div>
                                        {discussions?.map((d, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className={`row-view p10 cursor-pointer r8 ${d?.sub === activeDiscussion?.sub && "bgFAFAFA"
                                                        }`}
                                                    onClick={() => {
                                                        if (activeDiscussion?.sub !== d?.sub) {
                                                            setOffset(0);
                                                            setEndOfMessages(false);
                                                            setActiveDiscussion(d);
                                                            setSelectedDiscussion(d?.sub);
                                                        }
                                                    }}
                                                >
                                                    <div className={`r8 items-center relative ml10 mr10`}>
                                                        {d?.participants?.[0]?.photo ? (
                                                            <img
                                                                src={d?.participants?.[0]?.photo}
                                                                alt={i}
                                                                className={``}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={avatarImg}
                                                                alt="avatar"
                                                                className={``}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="column-view flex1 of-hidden">
                                                        <div>
                                                            <div className="row-view flex1">
                                                                <div className={`flex1 subtitle black-text`}>
                                                                    {d?.firstname} {d?.lastname}
                                                                </div>
                                                                <div
                                                                    className={`text-end of-ellipsis grey-text`}
                                                                >
                                                                    {moment(
                                                                        d?.messages?.[d?.message?.length - 1]
                                                                            ?.timestamp
                                                                    ).format("L")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`f-size14 of-ellipsis grey-text`}>
                                                            {d?.latest_message
                                                                ? d?.latest_message
                                                                : "Start a conversation..."}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
