import React, { useEffect, useState } from 'react';

import { AiOutlinePlusCircle } from 'react-icons/ai';
import { PiCaretUpDownLight } from 'react-icons/pi';
import { MdSearch } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Table } from '../../../components/Table/Table';

import arrowLeft from "../../../assets/icons/arrow-left.svg";
import arrowRight from "../../../assets/icons/arrow-right.svg";
import { getHotelProfileList } from '../../../api/hotel_profile';
import { AddProfile } from './AddProfile';
import { ProfileTab } from './ProfileTab';

const cells = [
    'Name',
    'Mood',
    'Period',
    'Nationalities',
    'Target audience',
    'Target mood',
    'Vibe',
    'Preferences'
]

const limit = [5, 10, 15, 20, 25]

export const ProfilesTab = ({ hotel_sub }) => {
    const [activeTab, setActiveTab] = useState(0)
    const [activeProfile, setActiveProifle] = useState('')
    const [profiles, setProfiles] = useState([]);
    const [profilesCount, setProfilesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [profilesPerPage, setProfilesPerPage] = useState(10);
    const [pagingOpen, setPagingOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [searchText, setSearchText] = useState("");

    const formatData = (data) => {
        let res = []
        data.map((p) => {
            return res.push({
                'id': p?.sub,
                'img': p?.images?.[0] ?? null,
                'name': p?.name ?? '',
                'mood': p?.mood?.[0] ? `${p?.mood[0].icon} ${p?.mood[0].label},${p?.mood?.length}` : '',
                'period': p?.period ?? [],
                'nationalities': p?.nationalities?.[0] ? `${p?.nationalities[0].icon} ${p?.nationalities[0].label},${p?.nationalities?.length}` : '',
                'targetMood': p?.targetMood?.[0] ? `${p?.targetMood[0].icon} ${p?.targetMood[0].label},${p?.targetMood?.length}` : '',
                'travelWith': p?.travelWith?.[0] ? `${p?.travelWith[0].icon} ${p?.travelWith[0].label},${p?.travelWith?.length}` : '',
                'vibe': p?.vibe?.[0] ? `${p?.vibe[0].icon} ${p?.vibe[0].label},${p?.vibe?.length}` : '',
                'preferences': p?.preferences?.[0] ? `${p?.preferences[0].icon} ${p?.preferences[0].label},${p?.preferences?.length}` : ''
            })
        })
        return res
    };

    useEffect(() => {
        if (activeTab === 0) {
            getHotelProfileList(hotel_sub, response => {
                if (response?.status === 'success') {
                    console.log(formatData(response?.content))
                    setProfiles(formatData(response?.content))
                    setProfilesCount(response?.content?.length)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab])

    return (
        <div>
            {activeTab === 0 &&
                <div>
                    <div className='row-view justify-between'>
                        <div>
                            <div className='size2 bold'>Profiles</div>
                            <div className='size1 bold today'><span>{profilesCount} Profiles</span></div>
                        </div>
                        <div className='row-view'>
                            <div className='row-view mr10'>
                                <div className='secondary-search-bar-container row-view items-center justify-centermr'>
                                    <div className='mr10'><MdSearch size={20} /></div>
                                    <input type='text' placeholder='Search' className='secondary-search-bar' onChange={(e) => setSearchText(e.target.value)} />
                                </div>
                            </div>
                            <div className='row-view'>
                                <Link
                                    className='primary-button none-decoration-link row-view items-center'
                                    onClick={() => setActiveTab(1)}
                                >
                                    <AiOutlinePlusCircle color='white' size={20} className='mr10' />
                                    <div>Add Profile</div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className='mt50 of-scroll'>
                        <Table cells={cells} data={profiles.length !== 0 ? profiles : 'No profiles yet...'} type='profiles' edit={edit} setActiveTab={setActiveTab} setActiveItem={setActiveProifle} />
                    </div>

                    <div className='row-view items-center justify-between mt50 '>
                        <div className='row-view items-center'>
                            <div className='relative secondary-button row-view p10 mr10' onClick={() => setPagingOpen(!pagingOpen)}>
                                <div className='row-view'>{profilesPerPage}</div>
                                <div className={`select-menu ${pagingOpen && 'open'}`} style={{ top: "100%", left: 0, marginTop: 5 }}>
                                    {limit.map((l, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className='select-item p4 text-center'
                                                onClick={() => {
                                                    setProfilesPerPage(l);
                                                }}
                                            >
                                                {l}
                                            </div>
                                        )
                                    })}
                                </div>
                                <PiCaretUpDownLight />
                            </div>
                            <div className='section-title'>Profiles per page</div>
                        </div>
                        <div className='row-view items-center'>
                            <div className='b161616 p4 r8 mr10' onClick={() => {
                                if (currentPage !== 0) {
                                    if (currentPage === 1) {
                                        setCurrentPage(0);
                                    } else {
                                        setCurrentPage(currentPage - 1);
                                    }
                                }
                            }
                            }>
                                <img
                                    src={arrowLeft}
                                    alt='dashboard'
                                    className='icon'
                                />
                            </div>
                            <div className='mr10'>Page {currentPage + 1}</div>
                            <div className='b161616 p4 r8' onClick={() => {
                                setCurrentPage(currentPage + 1);
                            }}>
                                <img
                                    src={arrowRight}
                                    alt='dashboard'
                                    fill='red'
                                    className='icon pagination--color'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {activeTab === 1 &&
                <AddProfile hotel_sub={hotel_sub} setActiveTab={setActiveTab} />
            }
            {activeTab === 2 &&
                <ProfileTab hotel_sub={hotel_sub} setActiveTab={setActiveTab} activeProfile={activeProfile} setActiveProfile={setActiveProifle} />
            }
        </div>
    )
}