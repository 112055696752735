export const getCurrentTime = (dateString) => {
  // const now = new Date();

  const now = dateString ? new Date(dateString) : new Date();
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours =
    hours > 12 ? (hours - 12).toString().padStart(2, "0") : hours;

  return `${formattedHours}:${minutes} ${ampm}`;
};

const getCurrentTimeRoundedHalfHour = () => {
  const now = new Date();
  let hours = now.getHours();
  let minutes = now.getMinutes();

  // If minutes are greater than or equal to 30, round to next hour, otherwise round to half-hour
  if (minutes >= 30) {
    hours++;
    minutes = 0;
  } else {
    minutes = 30;
  }

  // Convert to 12-hour format
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const formatDate = (date, time) => {
  //convert to 24 hr format
  const [hrs, min] = convertTo24Hour(time);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(hrs).padStart(2, "0"); //String(date.getHours()).padStart(2, '0');
  const minutes = String(min).padStart(2, "0"); //String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
};

function convertTo24Hour(time12h) {
  //24 hour convert
  const [time, period] = time12h.split(" ");
  const [hours, minutes] = time.split(":");

  let hours24 = parseInt(hours, 10);

  if (period.toUpperCase() === "PM" && hours24 !== 12) {
    hours24 += 12;
  } else if (period.toUpperCase() === "AM" && hours24 === 12) {
    hours24 = 0;
  }

  // return `${hours24.toString().padStart(2, '0')}:${minutes}`;
  return [hours24, minutes];
}

export var Current_Time = getCurrentTimeRoundedHalfHour(); //getCurrentTime();

const roles = [
  "admin",
  "hotelAdmin",
  "hotelOwner",
  "chainManager",
  "hotelManager",
  "hotelStaff",
  "hotelAccountant",
];

export const getHigherRole = (myRoles) => {
  let higherRole = myRoles[0];
  // eslint-disable-next-line array-callback-return
  myRoles.map((role) => {
    if (
      roles.indexOf(role) > -1 &&
      roles.indexOf(role) < roles.indexOf(higherRole)
    ) {
      higherRole = role;
    }
  });
  return higherRole;
};
