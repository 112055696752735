import React, { useRef } from 'react'

const VideoPlayer = ({ videoSource }) => {
    const videoRef = useRef(null)

    return (
        <div>
            <video
                className="w300 h50vh"
                ref={videoRef}
                controls
                autoPlay
                muted
            >
                <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    )
}

export default VideoPlayer
