import React from 'react'

export const ProgressLine = ({ text, rate }) => {
    const getPercent = (value) => {
        return (value / 5) * 100
    }

    return (
        <div>
            <div className='mt10'>
                <div>
                    <span className='primary-text-color bold'>{rate}</span> {text}
                </div>
                <div className='bgDADADA w100 r8 mt10' style={{ height: 8 }}>
                    <div className='bgPrimary r8' style={{ height: 8, width: `${getPercent(rate)}%` }} />
                </div>
            </div>
        </div>
    )
}
