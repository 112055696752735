import React, { useState, useEffect, useRef } from "react";

import { Table } from "../../components/Table/Table";
import { MdSearch } from "react-icons/md";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";

import "react-tooltip/dist/react-tooltip.css";
import { Pagination } from "../../components/Generic/Paginations";
import useHotelApi from "../../api/hotel";
import { getUserListByRole, getUsersRequest } from "../../api/user";
import moment from "moment";
import { getHigherRole } from "../../utils/utils";
import { Loader } from "../../components/Generic/Loader";

const tabs = ["Registered", "Requests"];

const cells = [
  "Name",
  "",
  "Availability",
  "Hotel",
  "Position",
  "Email",
  "Phone",
  "Manager",
  "Role",
];

const requestedCells = [
  "Source",
  "Name",
  "",
  "Position",
  "Email",
  "Amadeus",
  "Hotel",
  "Date",
];

export const Staff = ({ handleLogout }) => {
  const { getHotelListLazy } = useHotelApi();
  const [myRole] = useState(
    getHigherRole(JSON.parse(localStorage.getItem("my_roles")))
  );
  const [myRoles] = useState(JSON.parse(localStorage.getItem("my_roles")));

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [allStaff, setAllStaff] = useState([]);
  const [staff, setStaff] = useState([]);
  const [requested, setRequested] = useState([]);
  const [edit, setEdit] = useState(false);
  const [allStaffCount, setAllStaffCount] = useState(0);
  const [staffCount, setStaffCount] = useState(0);
  const [requestedCount, setRequestedCount] = useState(0);
  const queryParams = new URLSearchParams(window.location.search);
  const [currentPage, setCurrentPage] = useState(
    parseInt(queryParams.get("page"))
  );
  const [requestedCurrentPage, setRequestedCurrentPage] = useState(
    parseInt(queryParams.get("page"))
  );
  const [staffPerPage, setStaffPerPage] = useState(
    parseInt(queryParams.get("limit"))
  );
  const [requestedPerPage, setRequestedPerPage] = useState(
    parseInt(queryParams.get("limit"))
  );
  const [pagesNb, setPagesNb] = useState(0);
  const [requestedPagesNb, setRequestedPagesNb] = useState(0);
  const [hotles, setHotels] = useState([{ name: "All Hotels" }]);
  const [hotelsOpen, setHotelssOpen] = useState(false);
  const hotelsRef = useRef(null);
  const [hotelsSearch, setHotelsSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchText, setSearchText] = useState(queryParams.get("search"));
  const [requestedSearchText, setRequestedSearchText] = useState(
    queryParams.get("search")
  );

  const formatData = (data) => {
    let res = [];
    data.map((staff) => {
      return res.push({
        id: staff?.sub,
        img: staff?.img || null,
        verified: staff?.verified,
        name: `${staff?.firstname ? staff?.firstname : ""} ${
          staff?.lastname ? staff?.lastname : ""
        }`,
        availability: staff?.available,
        hotel: staff?.hotel?.name ?? "",
        position: staff?.position || "",
        email: staff?.email,
        phone:
          typeof staff?.phone === "string" ? staff?.phone : staff?.phone?.[0],
        manager:
          staff?.manager
            ?.map((el) => `${el.firstname} ${el.lastname}`)
            .join(", ") ?? "",
        role:
          staff?.roles[0]?.replace("hotel", "") ||
          staff?.roles?.[0]?.replace("mhm", ""),
        invited: staff?.invited ?? false,
        created_at: staff?.created_at
          ? moment(staff?.created_at).format("DD/MM/YYYY")
          : "",
      });
    });
    return res;
  };

  const formatRequestedData = (data) => {
    let res = [];
    data.map((staff) => {
      return res.push({
        source: staff?.source,
        id: staff?.sub,
        img: null,
        send: null,
        name: `${staff?.firstname ?? ""} ${staff?.lastname ?? ""}`,
        position: staff?.position ?? "",
        email: staff?.email,
        amadeus: staff?.amadeus ?? "",
        hotel: staff?.hotel ?? staff?.hotelName ?? "",
        invited: staff?.invited ?? false,
        created_at: staff?.createdAt
          ? moment(staff?.createdAt).format("DD/MM/YYYY")
          : "",
        invitedAt: staff?.invitedAt
          ? moment(staff?.invitedAt).format("DD/MM/YYYY")
          : "",
      });
    });
    return res;
  };

  // const handleEdit = () => {
  //     if (edit) {
  //         // TODO API call to save updates
  //         setEdit(false);
  //     } else {
  //         setEdit(true);
  //     }
  // }

  const retrieveStaff = (offset, limit, search) => {
    setIsLoading(true);
    getUserListByRole(
      offset,
      limit,
      search,
      "chainManager-hotelOwner-hotelManager-hotelStaff",
      (response) => {
        if (response.status === "success") {
          setAllStaff(response?.content?.users);
          setStaff(formatData(response?.content?.users));
          setStaffCount(response?.content?.count);
          setAllStaffCount(response?.content?.count);
          setPagesNb(Math.ceil(response?.content?.count / limit));
        }
        setIsLoading(false);
      }
    );
  };

  const retrieveUserRequests = (offset, limit, search) => {
    setIsLoading(true);
    getUsersRequest(offset, limit, search, (response) => {
      if (response.status === "success") {
        setRequested(formatRequestedData(response?.content?.users));
        setRequestedCount(response?.content?.count);
        setRequestedPagesNb(Math.ceil(response?.content?.count / limit));
      }
      setIsLoading(false);
    });
  };

  const retrieveHotels = (search) => {
    getHotelListLazy(0, 10, search, undefined, (response) => {
      if (response?.status === "success") {
        let hotels = [{ name: "All Hotels" }, ...response?.content?.hotels];
        setHotels(hotels);
      }
    });
  };

  useEffect(() => {
    retrieveHotels(hotelsSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("auth");
    if (token) {
      const expiration = JSON.parse(localStorage.getItem("expiration"));
      if (expiration < Date.now() / 1000) {
        handleLogout();
      }
    }
  }, [handleLogout]);

  useEffect(() => {
    let page = 1;
    let requestPage = 1;
    let limit = 10;
    let requestLimit = 10;
    let search = "";
    let requestSearch = "";
    if (currentPage > 1) {
      page = currentPage;
    }
    if (staffPerPage > 0 && staffPerPage < 26) {
      limit = staffPerPage;
    }
    if (searchText !== null) {
      search = searchText;
    }
    if (requestedCurrentPage > 1) {
      requestPage = requestedCurrentPage;
    }
    if (requestedPerPage > 0 && requestedPerPage < 26) {
      requestLimit = requestedPerPage;
    }
    if (requestedSearchText !== null) {
      requestSearch = requestedSearchText;
    }
    setCurrentPage(page);
    setStaffPerPage(limit);
    navigate(`/staff?page=${page}&limit=${limit}&search=${search}`);
    // setIsLoading(true)
    retrieveStaff((page - 1) * limit, limit, search);
    retrieveUserRequests(
      (requestPage - 1) * requestLimit,
      requestLimit,
      requestSearch
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //     if (isSelecting) {
  //         retrieveStaff(currentPage, staffPerPage, searchText)
  //     }
  // }, [hotel])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        hotelsOpen &&
        hotelsRef.current &&
        !hotelsRef.current.contains(event.target)
      ) {
        setHotelssOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hotelsOpen, hotelsRef]);

  useEffect(() => {
    if (isSearching) {
      navigate(`/staff?page=${0}&limit=${staffPerPage}&search=${searchText}`);
      retrieveStaff(0, staffPerPage, searchText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <>
      <div className="page-container relative">
        {isLoading && (
          <div className="row-view flex1 justify-center items-center filter-white-opacity">
            <Loader />
          </div>
        )}
        <div className="row-view justify-between">
          <div>
            <div className="size2 bold">Staff</div>
            <div className="size1 bold today">
              <span>
                {activeTab === 0
                  ? `${staffCount} employees`
                  : `${requestedCount} requests`}
              </span>
            </div>
          </div>
          <div className="row-view">
            <div className="row-view mr10">
              {/* <button className="secondary-button borderless row-view items-center">
                            <div className="mr5">
                                <TbFilterPlus />
                            </div>
                            <div>Filter</div>
                        </button> */}
              <div className="secondary-search-bar-container row-view items-center justify-center">
                <div className="mr10">
                  <MdSearch size={20} />
                </div>
                <input
                  type="text"
                  placeholder="Search"
                  className="secondary-search-bar"
                  value={searchText}
                  onChange={(e) => {
                    setIsSearching(true);
                    setSearchText(e.target.value);
                    setTimeout(() => {
                      setIsSearching(false);
                    }, 300);
                  }}
                />
              </div>
            </div>
            <div className="row-view justify">
              {/* <button
                            className="secondary-button w160 borderless row-view items-center justify-center"
                            onClick={() => handleEdit()}
                        >
                            <div className="mr5">
                                <BsPersonSquare />
                            </div>
                            <div>{edit ? 'Save' : 'Edit roles'}</div>
                        </button> */}
              {(myRoles.includes("admin") ||
                myRoles.includes("chainManager") ||
                myRoles.includes("hotelManager") ||
                myRoles.includes("owner")) && (
                <Link
                  className="primary-button none-decoration-link row-view items-center"
                  to={{ pathname: "/staff/add" }}
                >
                  <AiOutlinePlusCircle
                    color="white"
                    size={20}
                    className="mr10"
                  />
                  <div>Add Staff</div>
                </Link>
              )}
            </div>
          </div>
        </div>
        {/* <button className="secondary-button row-view items-center p10 mt20">
                <div
                    style={{
                        backgroundColor: 'gray',
                        width: 25,
                        height: 25,
                        borderRadius: 50,
                        marginRight: 5,
                    }}
                />
                <div className="mr5">The Mozart Hotel</div>
                <MdArrowDropDown size={20} />
            </button> */}
        {/* <div className='row-view flex1 items-center mt50'>
                        <div className='relative'>
                            {hotelsOpen ?
                                <input
                                    type='text'
                                    value={hotelsSearch}
                                    className='input-text w300'
                                    autoFocus
                                    onChange={(e) => {
                                        setHotelsSearch(e.target.value)
                                        retrieveHotels(e.target.value)
                                    }}
                                />
                                :
                                <div
                                    className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer'
                                    onClick={() => setHotelssOpen(!hotelsOpen)}
                                >
                                    <div>
                                        {hotel?.name
                                            ? hotel?.name
                                            : 'Select hotel'}
                                    </div>
                                    <BiSolidChevronDown />
                                </div>
                            }
                            <div className={`select-menu ${hotelsOpen && 'open'}`} style={{ maxHeight: 300, overflow: "scroll" }} ref={hotelsRef}>
                                {hotels?.length !== 0 ? (
                                    <>
                                        {hotels?.map((h, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className='select-item row-view justify-between'
                                                    onClick={() => {
                                                        setIsSelecting(true)
                                                        setHotel(h)
                                                        setHotelssOpen(false)
                                                        setTimeout(() => {
                                                            setIsSelecting(false)
                                                        }, 300)
                                                    }}
                                                >
                                                    <span className='section-title'>
                                                        {h?.name}<span className='grey-text'> - {h?.address?.city}</span>
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <div className='select-item'>
                                            No hotels found
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div> */}
        {myRole === "admin" ? (
          <div className="row-view mt20">
            {tabs.map((tab, index) => {
              return (
                <div key={index} className="row-view pb20">
                  {tab === "MyAgency" && (
                    <div className="v-separator bg161616 mr50" />
                  )}
                  <div
                    className={`mr50 cursor-pointer pv4 ${
                      activeTab === index && "black-text bold bb161616"
                    }`}
                    onClick={() => setActiveTab(index)}
                  >
                    <div className={`mr5`}>{tab}</div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="row-view mt20">
            <div className="row-view pb20"></div>
          </div>
        )}
        {activeTab === 0 && (
          <>
            <div className="of-scroll">
              <Table
                cells={cells}
                data={staff.length !== 0 ? staff : "No staff yet"}
                type="staff"
                retrieveData={() => {
                  setIsLoading(true);
                  retrieveStaff(currentPage, staffPerPage, searchText);
                }}
              />
            </div>

            <Pagination
              type="Staff"
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              itemsPerPage={staffPerPage}
              pagesNb={pagesNb}
              setItemsPerPage={setStaffPerPage}
              path="staff"
              retrieveData={retrieveStaff}
              searchText={searchText}
            />
          </>
        )}

        {activeTab === 1 && (
          <>
            <div className="of-scroll">
              <Table
                cells={requestedCells}
                data={requested.length !== 0 ? requested : "No requests yet"}
                type="Requested"
                retrieveData={() => {
                  setIsLoading(true);
                  retrieveUserRequests(currentPage, staffPerPage, searchText);
                }}
              />
            </div>

            <Pagination
              type="Requested"
              currentPage={requestedCurrentPage}
              setCurrentPage={setRequestedCurrentPage}
              itemsPerPage={requestedPerPage}
              pagesNb={requestedPagesNb}
              setItemsPerPage={setRequestedPerPage}
              path="staff"
              retrieveData={retrieveUserRequests}
              searchText={searchText}
            />
          </>
        )}
      </div>
    </>
  );
};
