import React, { useEffect, useState } from 'react';
import { HotelRooms } from './HotelRooms';
import { AddRooms } from './AddRooms';
import { RoomTab } from './RoomTab';

export const RoomsTab = ({ hotel_sub }) => {
    const [activeTab, setActiveTab] = useState(0)
    const [activeRoom, setActiveRoom] = useState('')

    return (
        <div>
            {activeTab === 0 && <HotelRooms hotelSub={hotel_sub} setActiveTab={setActiveTab} setActiveRoom={setActiveRoom} />}

            {activeTab === 1 && <AddRooms hotelSub={hotel_sub} setActiveTab={setActiveTab} />}

            {activeTab === 2 && <RoomTab roomSub={activeRoom} setActiveTab={setActiveTab} setActiveRoom={setActiveRoom} />}
        </div>
    )
}