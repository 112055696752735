import React, { useEffect, useState } from 'react';
import WelcomeTab from './WelcomeTab';
import PersonaleInformationTab from './PersonalInformationTab';
import TagsTab from './TagsTab';
import PhotoTab from './PhotoTab';
import { getUser } from '../../api/user';
import { type } from '@testing-library/user-event/dist/type';

const Welcome = ({ handleLogout }) => {
    const [activeTab, setActiveTab] = useState(0);

    const [staff, setStaff] = useState({
        phone: [''],
        tagAppreciations: [],
        tagLanguages: [],
        tagMusicStyle: [],
        tagFoodType: [],
        tagFoodRestrictions: [],
        tagAllergies: []
    });

    useEffect(() => {
        let sub = JSON.parse(localStorage.getItem('sub'));
        getUser(sub, response => {
            if (response?.status === 'success') {
                setStaff({
                    ...response?.content,
                    phone: response?.content?.phone ? (typeof (response?.content?.phone) === 'string' ? [response?.content?.phone] : (response?.content?.phone?.length > 0 ? response?.content?.phone : [''])) : ['']
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='page-wrapper'>
            {activeTab === 0 && <WelcomeTab setActiveTab={setActiveTab} />}
            {activeTab === 1 && <PersonaleInformationTab setActiveTab={setActiveTab} staff={staff} setStaff={setStaff} />}
            {activeTab === 2 && <TagsTab setActiveTab={setActiveTab} staff={staff} setStaff={setStaff} />}
            {activeTab === 3 && <PhotoTab setActiveTab={setActiveTab} staff={staff} setStaff={setStaff} />}
        </div>
    );
};

export default Welcome;