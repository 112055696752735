import React, { useEffect, useState } from 'react'
import searchsvg from '../../assets/icons/Search.svg'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { TicketCard } from '../../components/TicketCard/TicketCard'
import { ticketsData } from '../../utils/mock/TicketsData'
import TableFooter from '../../components/Generic/TableFooter'
import { TbFilterPlus } from 'react-icons/tb'

export const Tickets = ({ handleLogout }) => {

    const [currentPageData, setCurrentPageData] = useState(1)
    const [currentPageCountData, setCurrentPageCountData] = useState(5)

    const handlePaginationIncrement = () => {
        setCurrentPageData(currentPageData + 1)
    }

    const handlePaginationDecrement = () => {
        setCurrentPageData(
            currentPageData === 1 ? currentPageData : currentPageData - 1
        )
    }

    const handlePerPageDropdownChange = (e) => {
        setCurrentPageCountData(e.target.value);

    };

    useEffect(() => {
        let token = localStorage.getItem('auth')
        if (token) {
            const expiration = JSON.parse(localStorage.getItem('expiration'))
            if (expiration < Date.now() / 1000) {
                handleLogout()
            }
        }
    }, [handleLogout])

    return (
        <div className="page-container">
            <div className="body-head-cont">
                <div className="row-view justify-between">
                    <div>
                        <div className="size2 bold">Tickets</div>
                        <div className="size1 bold today">
                            <span>3 tickets</span>
                        </div>
                    </div>
                    <div className="row-view">
                        <button className="secondary-button borderless row-view items-center" style={{ width: '93px' }}>
                            <div className="mr5">
                                <TbFilterPlus />
                            </div>
                            <div>Filter</div>
                        </button>
                        <div className="float-right ">
                            <div
                                className="search-cont float-left"
                                style={{ marginTop: '10px', left: '29px' }}
                            >
                                <img
                                    src={searchsvg}
                                    alt=""
                                    style={{
                                        height: '13px',
                                        top: '12px',
                                        left: '30px',
                                    }}
                                />
                                <input
                                    type="text"
                                    className="search-inpt"
                                    placeholder="Search tickets"
                                    style={{ width: '141.5px' }}
                                />
                            </div>
                        </div>
                        <div style={{ width: '70px' }}></div>
                        <Link
                            to={{ pathname: '/tickets/add' }}
                            className="primary-text-color"
                        >
                            <button
                                className="primary-button row-view items-center"
                                style={{ padding: '16px' }}
                            >
                                <AiOutlinePlusCircle
                                    color="white"
                                    size={20}
                                    className="mr10"
                                />
                                <div>Add Ticket</div>
                            </button>
                        </Link>
                    </div>
                </div>
                {/* <div className="row-view justify-between">
                    <div>
                        <div className="size2 bold">Tickets</div>
                        <div className="size1 bold today">
                            <span>3 tickets</span>
                        </div>
                    </div>
                    <div className="float-right" style={{ float: 'right' }}>
                        <div className="search-cont">
                            <img src={searchsvg} alt="" />
                            <input
                                type="text"
                                className="search-inpt"
                                placeholder="Search tickets"
                            />
                        </div>
                    </div>
                    <Link
                        to={{ pathname: '/tickets/add' }}
                        className="primary-text-color"
                    >
                        <button className="primary-button row-view items-center">
                            <AiOutlinePlusCircle
                                color="white"
                                size={20}
                                className="mr10"
                            />
                            <div>Add Ticket</div>
                        </button>
                    </Link>
                </div> */}
            </div>

            <div className="row-view flex1 mt50">
                <div className="column-view">
                    {ticketsData.map((ticket, index) => {
                        return <TicketCard key={index} ticket={ticket} />
                    })}
                </div>
            </div>

            {/* <div className="row-view items-center justify-between mt50 ">
                <div className="row-view items-center">
                    <button className="secondary-button row-view p10 mr10">
                        <div className="mr5">25</div>
                        <PiCaretUpDownLight />
                    </button>
                    <div className="section-title">tickets per page</div>
                </div>
                <div className="pagination">
                    <div
                        className="pagination--item"
                        onClick={() =>
                            setCurrentPageData(
                                currentPageData === 0
                                    ? currentPageData
                                    : currentPageData - 1
                            )
                        }
                    >
                        <img src={arrowLeft} alt="dashboard" className="icon" />
                    </div>
                    <div
                        className="pagination--item"
                        onClick={() => setCurrentPageData(currentPageData + 1)}
                    >
                        <img
                            src={arrowRight}
                            alt="dashboard"
                            fill="red"
                            className="icon pagination--color"
                        />
                    </div>
                </div>
            </div> */}
            <TableFooter handlePaginationDecrement={handlePaginationDecrement} currentPageData={currentPageData}
                handlePaginationIncrement={handlePaginationIncrement} selectedOption={currentPageCountData} handlePerPageDropdownChange={handlePerPageDropdownChange} footerName={'Tickets'} />
        </div>
    )
}
