const getToken = async () => {
    return JSON.parse(localStorage.getItem('auth'));
};

export const getMyUser = async (callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/users/my/user`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getUser = async (sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/users/${sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const updateUser = async (sub, user, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/users/${sub}?source=bo`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "PATCH",
        body: JSON.stringify(user)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getUserList = async (callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/users/list`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getContactList = async (offset, limit, search, hotel_sub, callback) => {
    const authToken = await getToken();
    let url = `${process.env.REACT_APP_API_URL}/users/contacts`;
    if ((offset !== undefined && offset !== '') || (limit !== undefined && limit !== '')) {
        url += '?';
        if (offset !== undefined && offset !== '') {
            url += `offset=${offset}`;
        }
        if ((offset === undefined || offset === '') && (limit === undefined && limit === '')) {
            url += `?limit=${limit}`;
        } else {
            url += `&limit=${limit}`;
        }
    }
    url += `&search=${search}`;
    url += `&hotel_sub=${hotel_sub}`;
    fetch(url, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getUsersRequest = async (offset, limit, search, callback) => {
    const authToken = await getToken();
    let url = `${process.env.REACT_APP_API_URL}/users/requests/list`;
    if ((offset !== undefined && offset !== '') || (limit !== undefined && limit !== '')) {
        url += '?';
        if (offset !== undefined && offset !== '') {
            url += `offset=${offset}`;
        }
        if ((offset === undefined || offset === '') && (limit === undefined && limit === '')) {
            url += `?limit=${limit}`;
        } else {
            url += `&limit=${limit}`;
        }
    }
    url += `&search=${search}`;
    fetch(url, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getUserListByRole = async (offset, limit, search, roles, callback) => {
    const authToken = await getToken();
    let url = `${process.env.REACT_APP_API_URL}/users/role/${roles}`;
    if ((offset !== undefined && offset !== '') || (limit !== undefined && limit !== '')) {
        url += '?';
        if (offset !== undefined && offset !== '') {
            url += `offset=${offset}`;
        }
        if ((offset === undefined || offset === '') && (limit === undefined && limit === '')) {
            url += `?limit=${limit}`;
        } else {
            url += `&limit=${limit}`;
        }
    }
    url += `&search=${search}`;
    fetch(url, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const sendInvite = async (user, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/users/invite`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "POST",
        body: JSON.stringify(user),
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const replaceUserContent = async (content, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/users`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "PATCH",
        body: JSON.stringify(content)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const createUser = async (user, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/users`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "POST",
        body: JSON.stringify(user),
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};