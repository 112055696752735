import React, { useEffect, useState } from 'react'
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { MdCheck } from 'react-icons/md';
import colors from '../../../colors/colors';
import addImg from '../../../assets/icons/AddImgOrange.svg';
import Calendrier from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

import 'react-calendar/dist/Calendar.css'

import { MdChevronLeft, MdClose } from 'react-icons/md'
import { createHotelProfile } from '../../../api/hotel_profile'

const mood = [
    { icon: '🥂', label: 'Leisure' },
    { icon: '💼', label: 'Business' },
    { icon: '👔', label: 'Bleisure' },
    { icon: '👫', label: 'Friends' },
    { icon: '👨‍👩‍👧', label: 'Family' },
    { icon: '🌹', label: 'Romantic' },
    { icon: '🎉', label: 'Party' },
    { icon: '🏀', label: 'Sport' }
];

const nationalities = [
    { icon: '🇺🇸', label: 'American' },
    { icon: '🇫🇷', label: 'French' },
    { icon: '🇮🇹', label: 'Italian' },
    { icon: '🇪🇸', label: 'Spanish' },
    { icon: '🇩🇪', label: 'German' },
    { icon: '🇲🇽', label: 'Mexican' },
    { icon: '🇵🇪', label: 'Peruvian' },
    { icon: '🇬🇷', label: 'Greek' },
    { icon: '🇯🇵', label: 'Japanese' },
    { icon: '🇻🇳', label: 'Vietnamese' },
    { icon: '🇨🇳', label: 'Chinese' },
    { icon: '🇰🇷', label: 'Korean' },
    { icon: '🇲🇦', label: 'Moroccan' }
];

const travel_with = [
    { icon: '👨‍👩‍👧', label: 'Kids' },
    { icon: '👔', label: 'Colleagues' },
    { icon: '💃', label: 'Friends' },
    { icon: '👠', label: 'Secret' },
    { icon: '💞', label: 'Partner' },
    { icon: '🐕', label: 'Pets' },
    { icon: '👨‍👩‍👧', label: 'Family' }
];

const vibe = [
    { icon: '👞', label: 'Trendy' },
    { icon: '👠', label: 'Charm' },
    { icon: '👜', label: 'Evasion' },
    { icon: '🏰', label: 'Castle Life' },
    { icon: '❤️', label: 'Intimate' },
    { icon: '👟', label: 'Outgoing' }
];

const preferences = [
    { icon: '🪴', label: 'Sustainability' },
    { icon: '🐶', label: 'Pet lover' },
    { icon: '🔥', label: 'Cigar advisor' },
    { icon: '🍷', label: 'Wine lover' },
    { icon: '🗺️', label: 'Local guide' },
    { icon: '📚', label: 'History master\'s' },
    { icon: '🍸', label: 'Cocktail expert' }
];

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
]

export const AddProfile = ({ hotel_sub, setActiveTab }) => {
    const [infoModal, setInfoModal] = useState(false);

    const [windowSize, setWindowSize] = useState(window.innerWidth)
    // const [date, setDate] = React.useState(new Date())
    const hiddenFileInput = React.useRef(null);
    const [photoInput, setPhotoInput] = useState(null);
    const [periodTab, setPeriodTab] = useState('calendar')
    const [date, setDate] = React.useState(new Date())
    const [periodTags, setPeriodTags] = React.useState([])

    const [hotelProfile, setHotelProfile] = useState({
        active: false,
        name: '',
        picture: null,
        period: [],
        nationalities: [],
        targetMood: [],
        travelWith: [],
        vibe: [],
        preferences: []
    })

    const handlePhoto = (key) => {
        hiddenFileInput.current.click();
        setPhotoInput(key);
    };

    const handleInputPhoto = (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;

                img.onload = () => {
                    if (img.width >= 150 && img.height >= 150) {
                        setHotelProfile({ ...hotelProfile, picture: e.target.result });
                    } else {
                        alert('Image size must be at least 150x150 pixels ')
                    }
                }
            }

            reader.readAsDataURL(fileUploaded);
        }
    };

    const handleInputText = (e) => {
        setHotelProfile({ ...hotelProfile, [e.target.name]: e.target.value })
    }

    const handleTag = (key, value) => {
        if (hotelProfile[key] === undefined) {
            setHotelProfile({ ...hotelProfile, [key]: [value] })
            return
        }
        if (containsObject(value, hotelProfile[key])) {
            setHotelProfile({ ...hotelProfile, [key]: hotelProfile[key].filter((el) => el !== value) })
        } else {
            setHotelProfile({ ...hotelProfile, [key]: [...hotelProfile[key], value] })
        }
    }

    const handleSubmit = () => {
        let period = []
        if (periodTab === 'calendar') {
            period = date
            if (!period.length) {
                period = [date, date]
            }
        } else {
            period = periodTags
        }
        let profile = {
            ...hotelProfile,
            period
        }

        createHotelProfile(hotel_sub, profile, (response) => {
            if (response?.status === 'success') {
                setActiveTab(0)
            } else {
                alert(response?.message)
            }
        })
    }

    const periodContent = () => {
        switch (periodTab) {
            case 'calendar':
                return (
                    <div>
                        <div className="w55p">
                            <Calendrier
                                minDate={new Date()}
                                onChange={setDate}
                                value={date}
                                selectRange
                            />
                        </div>
                    </div>
                )

            case 'period':
                return (
                    <div className='row-view wrap'>
                        <div className='row-view'>
                            {months.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`row-view tag mr20 mt10 mb10 ${containsObject(item, periodTags) && 'selected'}`}
                                        onClick={() => {
                                            if (containsObject(item, periodTags)) {
                                                setPeriodTags(periodTags.filter((el) => el !== item))
                                            } else {
                                                setPeriodTags([...periodTags, item])
                                            }
                                        }}
                                    >
                                        <div>{item}</div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='row-view mt20'>
                            {days.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`row-view tag mr20 mt10 mb10 ${containsObject(item, periodTags) && 'selected'}`}
                                        onClick={() => {
                                            if (containsObject(item, periodTags)) {
                                                setPeriodTags(periodTags.filter((el) => el !== item))
                                            } else {
                                                setPeriodTags([...periodTags, item])
                                            }
                                        }}
                                    >
                                        <div>{item}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            default:
                break;
        }

    }

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const containsObject = (obj, list) => {
        return list?.some(item => item?.icon === obj?.icon && item?.label === obj?.label);
    }

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                handleSubmit()
            }}
        >
            <div className="row-view items-center">
                <MdChevronLeft
                    size={50}
                    className="cursor-pointer"
                    onClick={() => setActiveTab(0)}
                />
                <div className="title-secondary">Add Profile</div>
            </div>
            <div className="subtitle mt50 ml50">General information*</div>

            <div className="border-container r8 mt20">
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Profile name*</div>
                    <div className="row-view items-center ml20 flex1">
                        <div>
                            <input
                                className="input-text w300"
                                name="name"
                                placeholder="Profile name..."
                                value={hotelProfile?.name}
                                onChange={(e) => {
                                    handleInputText(e)
                                }}
                            />
                        </div>
                        <div
                            className={`checkbox cursor-pointer r4 ml20 mr10 ${hotelProfile?.active && 'selected'}`}
                            onClick={() => {
                                setHotelProfile({ ...hotelProfile, active: !hotelProfile.active })
                            }}
                        >
                            {hotelProfile?.active && <MdCheck color='white' />}
                        </div>
                        <div className='bold mr10'>Set active</div>
                        <div className='relative row-view items-center'>
                            <BsFillQuestionCircleFill color={colors.primary} size={20} onClick={() => setInfoModal(!infoModal)} />
                            <div className={`info-modal ${infoModal && 'active'}`}>
                                Set <span className='primary-text-color bold'>"The hotel profile"</span> active.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Photo</div>
                    <div className='row-view flex1 items-center ml20'>
                        <div className='row-view items-center'>
                            {!hotelProfile?.picture ?
                                <div
                                    className='border-container b-dashed b2 r8 p20 mr20'
                                    onClick={(e) => handlePhoto(e)}
                                >
                                    <img
                                        src={addImg}
                                        alt='addImg'
                                    />
                                </div>
                                :
                                <div
                                    className='p20 mr20 relative'
                                    onClick={(e) => handlePhoto(e)}
                                >
                                    <img
                                        className='r8'
                                        src={hotelProfile?.picture}
                                        alt="profilePicture"
                                        style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                                    />
                                    <div
                                        className='close-icon'
                                        onClick={() => setHotelProfile({ ...hotelProfile, picture: null })}
                                    >
                                        <MdClose size={20} color='white' />
                                    </div>
                                </div>
                            }
                            <div className='section-subtitle'>(JPGs or PNGs of at least 150x150px)</div>
                        </div>
                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            ref={hiddenFileInput}
                            onChange={(e) => handleInputPhoto(e)}
                            style={{ display: 'none' }}
                        />
                    </div>
                </div>

                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Mood*</div>
                    <div className="row-view wrap flex1 ml20">
                        {mood.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view tag mr20 mt10 mb10 ${containsObject(item, hotelProfile?.mood) && 'selected'}`}
                                    onClick={() => handleTag('mood', item)}
                                >
                                    <div>{item.icon} {item.label}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="row-view items-center p20">
                    <div className="section-title flex04">Period*</div>
                    <div className="row-view wrap flex1 ml20 relative">
                        <div className='row-view absolute' style={{ top: 0, left: '20%' }}>
                            {periodTab === 'calendar' ?
                                <div className='ph10 cursor-pointer primary-text-color bbOrange ' onClick={() => setPeriodTab('calendar')}>
                                    Calendar
                                </div>
                                :
                                <div className='ph10 row-view justify-center cursor-pointer' onClick={() => setPeriodTab('calendar')}>
                                    Calendar
                                </div>
                            }
                            <div className='ml20 mr20'> | </div>
                            {periodTab === 'period' ?
                                <div className='ph10 cursor-pointer primary-text-color bbOrange' onClick={() => setPeriodTab('period')}>
                                    Period
                                </div>
                                :
                                <div className='ph10 cursor-pointer' onClick={() => setPeriodTab('period')}>
                                    Period
                                </div>
                            }
                        </div>
                        <div className='row-view mt50'>
                            {periodContent()}

                        </div>
                    </div>
                </div>
            </div>

            <div className="subtitle mt50 ml50">Target audience*</div>
            <div className="border-container r8 mt20">
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Nationalities</div>
                    <div className="row-view wrap flex1 ml20">
                        {nationalities.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view tag mr20 mt10 mb10 ${containsObject(item, hotelProfile?.nationalities) && 'selected'}`}
                                    onClick={() => handleTag('nationalities', item)}
                                >
                                    <div>{item.icon} {item.label}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Target mood*</div>
                    <div className="row-view wrap flex1 ml20">
                        {mood.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view tag mr20 mt10 mb10 ${containsObject(item, hotelProfile?.targetMood) && 'selected'}`}
                                    onClick={() => handleTag('targetMood', item)}
                                >
                                    <div>{item.icon} {item.label}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Travel with*</div>
                    <div className="row-view wrap flex1 ml20">
                        {travel_with.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view tag mr20 mt10 mb10 ${containsObject(item, hotelProfile?.travelWith) && 'selected'}`}
                                    onClick={() => handleTag('travelWith', item)}
                                >
                                    <div>{item.icon} {item.label}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Vibe*</div>
                    <div className="row-view wrap flex1 ml20">
                        {vibe.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view tag mr20 mt10 mb10 ${containsObject(item, hotelProfile?.vibe) && 'selected'}`}
                                    onClick={() =>
                                        handleTag('vibe', item)
                                    }
                                >
                                    <div>{item.icon} {item.label}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="row-view items-center p20">
                    <div className="section-title flex04">Preferences</div>
                    <div className="row-view wrap flex1 ml20">
                        {preferences.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view tag mr20 mt10 mb10 ${containsObject(item, hotelProfile?.preferences) && 'selected'}`}
                                    onClick={() => handleTag('preferences', item)}
                                >
                                    <div>{item.icon} {item.label}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="row-view justify-end mt20">
                <button
                    className="secondary-button mr20"
                    type="button"
                    onClick={() => setActiveTab(0)}
                >
                    Cancel
                </button>
                <button className="primary-button" type="submit">
                    Create
                </button>
            </div>
        </form >
    )
}
