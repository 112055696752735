import React, { useState } from 'react';

import logoTextblack from '../../assets/logos/logo-text-black.svg';
import { Link } from 'react-router-dom';
import './../../iltm2023.css';
import { iltmRegister } from '../../api/iltm';

export const NewHotel = ({ setActiveSubTab }) => {
    const [data, setData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        position: '',
        hotel: '',
        amadeus: ''
    });
    const [isGDS, setGDS] = useState(true);

    const handleInputText = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = () => {
        if (data?.hotel === '') {
            alert('Please fill your hotel name!');
            return;
        }
        // if (data?.amadeus.length !== 8 && isGDS) {
        //     alert('Wrong format of Amadeus Property Code');
        //     return;
        // }
        if (data?.firstname === '' || data?.lastname === '' || data?.email === '') {
            alert(`Please fill the required fields in 'Your information' section!`);
            return;
        }
        iltmRegister(data, response => {
            if (response?.status === 'success') {
                if (data?.amadeus?.length !== 0) {
                    setActiveSubTab(1);
                } else {
                    setActiveSubTab(2);
                }
            }
        });
    };

    return (
        <div className='column-view justify-between' style={{ padding: '2vw', height: '90vh' }}>
            <div className='flex02'>
                <div className="column-view items-center flex03 mt20">
                    <img
                        src={logoTextblack}
                        alt="logoTextWhite"
                        style={{ width: '14rem' }}
                    />
                </div>
            </div>
            <div className='flex1 column-view'>
                <div className="column-view items-center justify-center mt50">
                    <div className="bold">
                        To begin, which hotel are your connected to ?
                    </div>
                    <div className="mt10">Select your hotel</div>
                </div>
                <div className="subtitle mt50 ml50">Hotel information*</div>
                <div className="border-container r8 mt20">
                    <div className="row-view items-center p20 bb">
                        <div className="section-title flex04">Hotel name*</div>
                        <div className="row-view flex1 items-center">
                            <input
                                className="input-text"
                                required
                                name="hotel"
                                placeholder="Hotel name..."
                                value={data?.hotel}
                                onChange={(e) => setData({ ...data, hotel: e.target.value.toUpperCase() })}
                            />
                        </div>
                    </div>
                    <div className={`row-view items-center p20 ${isGDS ? 'bb' : ''}`}>
                        <div className="section-title flex04">Are you in GDS*</div>
                        <div className='row-view flex1 items-center gap20'>
                            <div className='row-view w250 gap20'>
                                <div className={`flex-grow ${isGDS ? 'primary-button' : 'secondary-button'}`} onClick={() => setGDS(true)}>
                                    Yes
                                </div>
                                <div className={`flex-grow ${isGDS ? 'secondary-button' : 'primary-button'}`} onClick={() => setGDS(false)}>
                                    No
                                </div>
                            </div>
                        </div>
                    </div>
                    {isGDS &&
                        <div className="row-view items-center p20">
                            <div className="section-title flex04">Amadeus Property code*</div>
                            <div className="row-view flex1 items-center">
                                <input
                                    className="input-text"
                                    required
                                    name="amadeus"
                                    placeholder="Property code..."
                                    value={data?.amadeus}
                                    maxLength={8}
                                    onChange={(e) => {
                                        let trimmedValue = e.target.value.replace(/\s/g, '');
                                        setData({ ...data, amadeus: trimmedValue.toUpperCase() });
                                    }}
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className="subtitle ml50 mt50">Your information*</div>
                <div className="border-container r8 mt20">
                    <div className="row-view items-center p20 bb">
                        <div className="section-title flex04">First name*</div>
                        <div className="row-view flex1 items-center">
                            <input
                                className="input-text"
                                required
                                name="firstname"
                                placeholder="First name..."
                                value={data?.firstname}
                                onChange={(e) => handleInputText(e)}
                            />
                        </div>
                    </div>
                    <div className="row-view items-center p20 bb">
                        <div className="section-title flex04">Last name*</div>
                        <div className="row-view flex1 items-center">
                            <input
                                className="input-text"
                                required
                                name="lastname"
                                placeholder="Last name..."
                                value={data?.lastname}
                                onChange={(e) => handleInputText(e)}
                            />
                        </div>
                    </div>
                    <div className="row-view items-center p20 bb">
                        <div className="section-title flex04">Position</div>
                        <div className="row-view flex1 items-center">
                            <input
                                className="input-text"
                                name="position"
                                placeholder="Position..."
                                value={data?.position}
                                onChange={(e) => handleInputText(e)}
                            />
                        </div>
                    </div>
                    <div className="row-view items-center p20">
                        <div className="section-title flex04">Email address*</div>
                        <div className="row-view flex1 items-center">
                            <input
                                className="input-text"
                                required
                                type="email"
                                name="email"
                                placeholder="Email address..."
                                value={data?.email}
                                onChange={(e) => handleInputText(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row-view justify-center">
                <Link
                    className="primary-button none-decoration-link row-view items-center mt50 ph100 mb50"
                    style={{ borderRadius: '25px' }}
                    onClick={() => handleSubmit()}
                >
                    <div cla>Continue</div>
                </Link>
            </div>
        </div>
    );
};
