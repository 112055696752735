import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import moment from 'moment';
import { Table } from '../../../components/Table/Table';
import { getHotelServiceList } from '../../../api/hotel_service';
import { listServices } from '../../../utils/mock/HotelData';
import { Loader } from '../../../components/Generic/Loader';

const cells = [
    'Name',
    'Availability',
    'Dates & opening hours',
    'Informations'
]

export const HotelServices = ({ hotelSub, setActiveTab, setActiveService }) => {
    const [isLoading, setIsLoading] = useState(true);
    const currentDate = moment().format('16/11/2023');
    const toDate = moment().format('25/11/2023');
    const fromDate = moment().format('17/11/2023');

    const [services, setServices] = useState([])
    const [servicesCount, setServicesCount] = useState(0)

    const formatData = (data) => {
        let res = [];
        data.map((s) => {
            return (
                res.push({
                    id: s?.sub,
                    img: listServices?.filter(el => el?.name === s?.service)?.[0]?.icon ?? null,
                    name: `${s?.service ?? ''} - ${s?.name ?? ''}`,
                    availability: s?.active ?? false,
                    dates: `${s?.dates ?? []}`,
                    informations: `${moment(s?.created_at).format('ll') ?? ''}|${s?.numberOfBookings ?? 0}|${s?.numberOfCancellations ?? 0}|${s?.totalRevenues ?? 0}`
                })
            );
        })
        return res;
    }

    useEffect(() => {
        getHotelServiceList(hotelSub, response => {
            if (response?.status === 'success') {
                setServices(formatData(response?.content))
                setServicesCount(response?.content?.length)
            }
            setIsLoading(false)
        })
    }, [])

    return (
        <>
            {isLoading ?
                <Loader />
                :
                <>
                    <div className='row-view justify-end'>
                        <Link
                            className='primary-button none-decoration-link row-view items-center'
                            onClick={() => setActiveTab(1)}
                        >
                            <AiOutlinePlusCircle color='white' size={20} className='mr10' />
                            <div>Add Service</div>
                        </Link>
                    </div>
                    <div className='mt20'>
                        <Table cells={cells} data={services.length !== 0 ? services : 'No services yet...'} type='services' setActiveTab={setActiveTab} setActiveItem={setActiveService} />
                    </div>
                    {!services && <div className='p20'>No services registered..</div>}
                </>
            }
        </>
    )
}