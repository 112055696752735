import React from "react";
import img from '../../assets/logos/image.svg';
import { FaAngleRight } from "react-icons/fa6";
import { IoMoonSharp } from 'react-icons/io5'
import colors from "../../colors/colors";

export const BookedCalendar = ({ events }) => {

    return (
        <div className="border-container m20 r8 bgWhite">
            <div className="row-view items-center mt10 ml10 mb10">
                <div>
                    <img
                        src={img}
                        alt="img"
                        className="r100 mr10"
                        style={{ width: 30 }}
                    ></img>
                </div>
                <div className="row-view">
                    <div className="column-view">
                        <div className="mb10 bold">
                            Harry Cover
                        </div>
                        <div className="row-view items-center bold">
                            2 adults - <span className="primary-text-color" style={{ paddingLeft: 3 }}>View profiles</span> <FaAngleRight color={colors.primary} />
                        </div>

                    </div>
                </div>
                <div style={{ marginTop: '-3vh' }}>
                    💼 Business
                </div>
            </div>
            <div className='bg161616 w90p' style={{ height: '1px', margin: 'auto', backgroundColor: '#343330' }} />

            <div className="row-view justify-between mt10 ml10">
                <div className="column-view mb10">
                    <div className="dark-grey bold mb10">Starting at 3pm</div>
                    <div className="text-underline primary-text-color bold">View reservation</div>
                </div>
                <div className="row-view bold mr20">
                    <IoMoonSharp /> 2 nights
                </div>
            </div>


        </div >
    )

};
