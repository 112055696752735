import React, { useEffect, useState } from "react";

import { MdChevronLeft, MdStar } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import useHotelApi from "../../../api/hotel";
import { HotelProfileTab } from "./HotelProfileTab";
import { RoomsTab } from "../Rooms/RoomsTab";
import { ProfilesTab } from "./ProfilesTab";
import colors from "../../../colors/colors";
import { ServicesTab } from "../Services/ServicesTab";
import { RestaurantsTab } from "../Restaurants/RestaurantsTab";
import { Loader } from "../../../components/Generic/Loader";
import { CustomAlert } from "../../../components/Generic/CustomAlert";
import Advantages from "../Advantages/Advantages";

const tabs = [
  "General",
  "Profiles",
  "Rooms",
  "Services",
  "Restaurants",
  "Advantages",
];

export const HotelProfile = ({ handleLogout }) => {
  const { getHotelContent, updateHotelContent } = useHotelApi();
  const navigate = useNavigate();
  const location = useLocation();
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [activeTab, setActiveTab] = useState(0);
  const [hotelSub, setHotelSub] = useState(
    location.pathname.replace("/hotels/profile/", "")
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [managers, setManagers] = useState([]);

  const [lon, setLon] = useState(7.0687992);
  const [lat, setLat] = useState(43.6241267);

  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [hotel, setHotel] = useState({
    phone: [{ phone: "", description: "" }],
  });

  const handleSubmit = () => {
    console.log('passss')
    let managers = [];
    let owners = [];
    hotel?.manager?.map((m) => {
      return managers.push(m.sub);
    });
    hotel?.owner?.map((m) => {
      return owners.push(m.sub);
    });
    let newHotel = {
      ...hotel,
      manager: managers,
      owner: owners,
    };

    setIsLoading(true);
    updateHotelContent(newHotel, hotelSub, (response) => {
      if (response?.status === "success") {
        if (response?.message) {
          setErrorMessage(response?.message);
          setShowAlert(true);
          setTimeout(() => {
            setIsFadingOut(true);
            navigate(-1);
          }, 3000);
        } else {
          navigate(-1);
        }
      } else {
        alert(response?.message);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (isFadingOut) {
      setTimeout(() => {
        setShowAlert(false);
        setIsFadingOut(false);
      }, 500);
    }
  }, [isFadingOut]);

  useEffect(() => {
    let token = localStorage.getItem("auth");
    getHotelContent(hotelSub, (response) => {
      if (response.status === "success") {
        setHotel(response.content);
      } else {
        setError(true);
      }
      setLoading(false);
    });
    if (token) {
      const expiration = JSON.parse(localStorage.getItem("expiration"));
      if (expiration < Date.now() / 1000) {
        handleLogout();
      }
    }

    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    // getUserListByRole(0, 10, '', 'hotelManager', response => {
    //     if (response?.status === 'success') {
    //         setManagers(response?.content)
    //     }
    // })

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleLogout]);

  const RenderRating = () => {
    let stars = [];
    for (let i = 0; i < parseInt(hotel?.awards?.[0]?.rating); i++) {
      stars.push(colors.primary);
    }
    while (stars.length < 5) {
      stars.push("#DADADA");
    }
    return stars?.map((color, index) => {
      return <MdStar key={index} color={color} size={30} />;
    });
  };

  return (
    <div className="page-container">
      {isLoading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {showAlert && (
        <div
          className={`row-view alert-container justify-center ${isFadingOut ? "fade-out-from-bottom" : "fade-in-from-top"
            }`}
        >
          <CustomAlert
            message={errorMessage}
            handleClose={() => {
              setIsFadingOut(true);
            }}
          />
        </div>
      )}
      <div className="row-view items-center">
        <MdChevronLeft
          size={50}
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <div className="title-secondary mr20">{hotel?.name}</div>
        {hotel?.awards?.[0]?.rating && (
          <div className="mr20">
            <RenderRating />
          </div>
        )}
        {hotel?.segment_category?.label && (
          <div className="bgPrimary p10 r8 white-text">
            {hotel?.segment_category?.label}
          </div>
        )}
      </div>
      {error && (
        <div className="row-view flex1 justify-center items-center">
          <span style={{ fontSize: 60 }}>❌</span>
        </div>
      )}
      {loading && (
        <div className="row-view flex1 justify-center items-center">
          <div className="loader" />
        </div>
      )}
      {!loading && !error && (
        <div>
          <div className="row-view">
            {tabs.map((tab, index) => {
              return (
                <div key={index} className="row-view pb20 mt50">
                  {tab === "MyAgency" && (
                    <div className="v-separator bg161616 mr50" />
                  )}
                  <div
                    className={`mr50 cursor-pointer pv4 ${activeTab === index && "black-text bold bb161616"
                      }`}
                    onClick={() => setActiveTab(index)}
                  >
                    <div className={`mr5`}>{tab}</div>
                  </div>
                </div>
              );
            })}
          </div>

          {activeTab === 0 && (
            <HotelProfileTab
              windowSize={windowSize}
              managers={managers}
              setManagers={setManagers}
              lon={lon}
              setLon={setLon}
              lat={lat}
              setLat={setLat}
              hotel={hotel}
              setHotel={setHotel}
              handleSubmit={handleSubmit}
              navigate={navigate}
            />
          )}

          {activeTab === 1 && <ProfilesTab hotel_sub={hotel?.sub} />}

          {activeTab === 2 && <RoomsTab hotel_sub={hotel?.sub} />}

          {activeTab === 3 && <ServicesTab hotel_sub={hotel?.sub} />}

          {activeTab === 4 && <RestaurantsTab hotel_sub={hotel?.sub} />}

          {activeTab === 5 && <Advantages hotel_sub={hotel?.sub} />}
        </div>
      )}
    </div>
  );
};
