import React, { useEffect, useState } from 'react';

import { MdChevronLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import useHotelApi from '../../api/hotel';
import { AddHotelTab } from './AddHotelTab';
import { sendImage } from '../../api/auth';
import colors from '../../colors/colors';
import { CustomAlert } from '../../components/Generic/CustomAlert';
import { Loader } from '../../components/Generic/Loader';

export const AddHotel = ({ handleLogout }) => {
    const { createHotel, requestHotel } = useHotelApi();
    const navigate = useNavigate();
    const mySub = JSON.parse(localStorage.getItem('sub'));
    const [activeTab, setActiveTab] = useState(0);
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isFadingOut, setIsFadingOut] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [lon, setLon] = useState(7.0687992);
    const [lat, setLat] = useState(43.6241267);

    const [managers, setManagers] = useState([]);

    const [hotel, setHotel] = useState({
        active: false,
        phone: [{ phone: '', desctiption: '' }]
    });

    const handleSubmit = (type) => {
        let managers = [];
        let owners = [];
        hotel?.manager?.map(m => {
            return (managers.push(m.sub));
        });
        hotel?.owner?.map(m => {
            return (owners.push(m.sub));
        });
        let newHotel = {
            ...hotel,
            manager: managers,
            owner: owners
        };
        setIsLoading(true);
        if (type === 'create') {
            createHotel(newHotel, (response) => {
                if (response?.status === 'success') {
                    if (response?.message) {
                        setErrorMessage(response?.message);
                        setShowAlert(true);
                        setTimeout(() => {
                            setIsFadingOut(true);
                            navigate(-1);
                        }, 3000);
                    } else {
                        navigate(-1);
                    }
                } else {
                    alert(response?.message);
                }
            });
        } else if (type === 'request') {
            requestHotel(hotel?.sub, (response) => {
                if (response?.status === 'success') {
                    if (response?.message) {
                        setErrorMessage(response?.message);
                        setShowAlert(true);
                        setTimeout(() => {
                            setIsFadingOut(true);
                            navigate(-1);
                        }, 3000);
                    } else {
                        navigate(-1);
                    }
                } else {
                    alert(response?.message);
                }
            });
        }
    };

    useEffect(() => {
        if (isFadingOut) {
            setTimeout(() => {
                setShowAlert(false);
                setIsFadingOut(false);
            }, 500);
        }
    }, [isFadingOut]);

    useEffect(() => {
        let token = localStorage.getItem('auth');
        if (token) {
            const expiration = JSON.parse(localStorage.getItem('expiration'));
            if (expiration < Date.now() / 1000) {
                handleLogout();
            }
        }
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleLogout]);

    return (
        <div className='page-container column-view'>
            {isLoading &&
                <div className='loader-container'>
                    <Loader />
                </div>
            }
            {showAlert && (
                <div className={`row-view alert-container justify-center ${isFadingOut ? 'fade-out-from-bottom' : 'fade-in-from-top'}`}>
                    <CustomAlert
                        message={errorMessage}
                        handleClose={() => {
                            setIsFadingOut(true);
                        }}
                    />
                </div>
            )}
            <div>
                <div className="row-view items-center">
                    <MdChevronLeft size={50} className='cursor-pointer' onClick={() => navigate(-1)} />
                    <div className="title-secondary">Add Hotel</div>
                </div>

                <div className='column-view flex1'>
                    {activeTab === 0 &&
                        <AddHotelTab
                            windowSize={windowSize}
                            managers={managers}
                            setManagers={setManagers}
                            lon={lon}
                            setLon={setLon}
                            lat={lat}
                            setLat={setLat}
                            hotel={hotel}
                            setHotel={setHotel}
                            handleSubmit={handleSubmit}
                            navigate={navigate}
                        />
                    }
                </div>
            </div>
        </div >
    );
};
