import React, { useState } from 'react';

import logoTextblack from '../../assets/logos/logo-text-black.svg';
import { InviteForm } from '../../components/Generic/InviteForm';
import { resetPassword } from '../../api/auth';
import { useNavigate } from 'react-router-dom';


export const Invite = () => {
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const [email, setEmail] = useState(queryParams.get("email") ?? '');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleVerify = () => {
        let data = {
            email: email,
            newPassword: password,
            pinCode: pinCode
        };
        resetPassword(data, response => {
            if (response?.status === 'success') {
                navigate('/');
            } else {
                setErrorMessage(response?.message);
            }
        });
    };

    return (
        <div className='column-view h100vh items-center justify-between'>
            <div className='column-view items-center flex03 mt20'>
                <img
                    src={logoTextblack}
                    alt='logoTextWhite'
                    className='logo'
                />
            </div>
            <div className='row-view items-center flex03'>
                <div className='title column-view text-center'>
                    <span>
                        you have been invited as a user
                    </span>
                    <span>
                        to <span className='bold'>MyHotelMatch</span> Back Office
                    </span>
                </div>
            </div>
            <div className='flex1'>
                <InviteForm
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                    pinCode={pinCode}
                    setPinCode={setPinCode}
                    errorMessage={errorMessage}
                    handleVerify={handleVerify}
                />
            </div>
            <div className='column-view flex04 text-center justify-end pv20' >
                <div>
                    By continuing, you agree to the {' '}
                    <a
                        href='https://mymatchingcompany.com/mentions-legales/'
                        target='_blank'
                        rel="noreferrer"
                        className='link'
                    >
                        Terms of Service
                    </a>
                    {' '} and {' '}
                    <a
                        href='https://mymatchingcompany.com/mentions-legales/'
                        target='_blank'
                        rel="noreferrer"
                        className='link'
                    >
                        Privacy policy
                    </a>
                </div>
                <div className='mt20'>
                    All rights reserved.
                </div>
                <div>
                    ©Myhotelmatch {new Date().getFullYear()}
                </div>
            </div>
        </div>
    );
};