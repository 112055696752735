import React from 'react';

function TimePicker({ selectedTime, handleTimeChange }) {
  // const [selectedTime, setSelectedTime] = useState(initialTime || '');

  const timeOptions = [];
  for (let h = 0; h < 12; h++) {
    for (let m = 0; m < 60; m += 15) {
      let hour = h === 0 ? 12 : h;
      hour = hour.toString().padStart(2, '0');
      let minute = m === 0 ? '00' : m;
      minute = minute.toString().padStart(2, '0');
      const period = 'AM';
      timeOptions.push(`${hour}:${minute} ${period}`);
    }
  }
  for (let h = 0; h < 12; h++) {
    for (let m = 0; m < 60; m += 15) {
      let hour = h === 0 ? 12 : h;
      hour = hour.toString().padStart(2, '0');
      let minute = m === 0 ? '00' : m;
      minute = minute.toString().padStart(2, '0');
      const period = 'PM';
      timeOptions.push(`${hour}:${minute} ${period}`);
    }
  }

  // useEffect(() => {
  //   setSelectedTime(initialTime || '');
  // }, [initialTime]);

  // const handleTimeChange = (event) => {
  //   setSelectedTime(event.target.value);
  // };

  return (
    <select className="form-control"
      id="timePicker"
      value={selectedTime} onChange={e => handleTimeChange(e.target.value)}
    >
      {timeOptions.map((timeOption) => (
        <option key={timeOption} value={timeOption}>
          {timeOption}
        </option>
      ))}
    </select>


  );
}

export default TimePicker;
