import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ArrowLeftSvg from '../../assets/icons/left-arrow-add-offer.svg'
import TimePicker from '../../components/Generic/TimePicker'
import { formatDate, Current_Time, getCurrentTime } from '../../utils/utils';
import { useNavigate, useLocation } from 'react-router-dom'
import { getHotelOffer, updateHotelOffer } from '../../api/offer';

export const EditOffer = ({ handleLogout }) => {

    const navigate = useNavigate()
    const location = useLocation()
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const [offerSub, setOfferSub] = useState(
        location.pathname.replace('/offers/edit/', ''),
    )
    const [hotel, setHotel] = useState({})
    const [hotelOffer, setHotelOffer] = useState({
        offer_name: '',
        rooms: [],
        details: [],
        discount: '',
        target_audience: [],
        available: false,
        start_date: '',
        end_date: ''
    })

    const [errors, setErrors] = useState({
        service_name: '',
        rooms: '',
        discount: '',
        target_audience: '',
        dates: '',

    })

    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const [selectedFromTime, setSelectedFromTime] = useState(Current_Time);//'replace with actual time'
    const [selectedToTime, setSelectedToTime] = useState(Current_Time);//'replace with actual time'



    const handleInputText = (e) => {
        setHotelOffer({ ...hotelOffer, [e.target.name]: e.target.value })
    }

    const handleFromDtChange = date => {
        // Convert the date to the desired format

        setSelectedFromDate(date);
    };

    const handleToDtChange = date => {
        setSelectedToDate(date);
    };

    const handleFromTimeChange = (time) => {
        setSelectedFromTime(time);
    };

    const handleToTimeChange = (time) => {
        setSelectedToTime(time);
    };

    const handleRoomsDropdownChange = (event) => {
        setHotelOffer({ ...hotelOffer, rooms: event.target.value })
    };
    const handleAudienceDropdownChange = (event) => {
        setHotelOffer({ ...hotelOffer, target_audience: event.target.value })
    };
    //Available - toggle button
    const [isAvailableChecked, setIsAvailableChecked] = useState(false);

    const handleAvailableCheckboxChange = (e) => {
        setHotelOffer({ ...hotelOffer, available: e.target.checked })
        setIsAvailableChecked(e.target.checked);
    };

    const handleCheckboxChange = (e) => {
        const { checked } = e.target;
        const detail = e.target.value;

        if (checked) {
            // Add the detail to the array
            setHotelOffer((prevOffer) => ({
                ...prevOffer,
                details: [...prevOffer.details, detail],
            }));
        } else {
            // Remove the detail from the array
            setHotelOffer((prevOffer) => ({
                ...prevOffer,
                details: prevOffer.details.filter((d) => d !== detail),
            }));
        }
    };
    const handleSubmit = () => {
        const validationErrors = {}

        if (!selectedFromDate || !selectedToDate) {
            validationErrors.dates = 'Select dates'
        }

        if (hotelOffer.offer_name.trim() === '') {
            validationErrors.offer_name = 'offer name is required'
        }

        if (hotelOffer.rooms === 'Select Rooms') {
            validationErrors.rooms = 'Rooms is required'
        }

        if (hotelOffer.discount.trim() === '') {
            validationErrors.discount = 'Discount is required'
        }

        if (hotelOffer.target_audience === 'Select audience') {
            validationErrors.target_audience = 'Target audience is required'
        }


        if (hotelOffer.details.length === 0) {
            validationErrors.details = 'At least one detail must be checked'
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors)
            return
        }

        if (
            formatDate(selectedFromDate, selectedFromTime) >=
            formatDate(selectedToDate, selectedToTime)
        ) {
            alert('end date should be greater than start date')
            return 0
        }


        let offer = {
            ...hotelOffer,
            start_date: formatDate(selectedFromDate, selectedFromTime),
            end_date: formatDate(selectedToDate, selectedToTime),
        }

        updateHotelOffer(offerSub, offer, (response) => {
            console.log(response)
            if (response === true) {
                // navigate(-1)
                navigate("/offers");
            }
        })
    }

    useEffect(() => {
        let token = localStorage.getItem('auth')
        if (token) {
            const expiration = JSON.parse(localStorage.getItem('expiration'))
            if (expiration < Date.now() / 1000) {
                handleLogout()
            }
        }
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleLogout]);

    useEffect(() => {

        getHotelOffer(offerSub, (response) => {
            if (response?.status === 'success') {

                console.log(response?.content?.available)
                setHotelOffer({
                    offer_name: response?.content?.offer_name,
                    rooms: response?.content?.rooms,
                    details: response?.content?.details || [],
                    discount: response?.content?.discount,
                    target_audience: response?.content?.target_audience,
                    available: response?.content?.available,
                    start_date: response?.content?.start_date,
                    end_date: response?.content?.end_date
                })
                setHotel({ hotel_sub: response?.content?.hotel_sub, hotel_name: response?.content?.hotel_name })
                setIsAvailableChecked(response?.content?.available)

                // set start date and end date
                setSelectedFromDate(moment(response?.content?.start_date, "YYYY-MM-DD HH:mm:ss.SSS").toDate());
                setSelectedToDate(moment(response?.content?.end_date, "YYYY-MM-DD HH:mm:ss.SSS").toDate());

                // set start time and end time
                setSelectedFromTime(getCurrentTime(response?.content?.start_date));
                setSelectedToTime(getCurrentTime(response?.content?.end_date));

            }

        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <form
            className="page-container"
            onSubmit={(e) => {
                e.preventDefault()
                handleSubmit()
            }}
        >

            <div className="page-container body-content">
                <div className="body-content">
                    <div className="body-head-cont">
                        <div className="title-offer">
                            <h3 className="body-head"><Link to="/offers" ><img src={ArrowLeftSvg} alt="" /></Link>Edit offer</h3>
                        </div>
                    </div>
                    <h3 className="body-sub-head">General Information</h3>
                    <div className="form-container">
                        <ul className="forms">
                            <li>
                                <label className="form-label">Hotel*</label>
                                <div className="input-cont">
                                    <input type="text" name="hotel_name"
                                        value={hotel?.hotel_name}
                                        disabled
                                        placeholder="Hotel name"
                                        className="form-control" />
                                </div>
                            </li>

                            <li>
                                <label className="form-label">Offer Name*</label>
                                <div className="input-cont">
                                    <input
                                        type="text"
                                        name="offer_name"
                                        value={hotelOffer?.offer_name}
                                        onChange={(e) => {
                                            handleInputText(e)
                                        }}
                                        placeholder="offer name..."
                                        className="form-control"
                                    />
                                    {errors.offer_name && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ color: 'red' }}
                                        >
                                            {errors.offer_name}
                                        </div>
                                    )}
                                </div>
                            </li>
                            <li>
                                <label className="form-label">Rooms & Rates*</label>
                                <div className="input-cont">
                                    <select className="form-control"
                                        value={hotelOffer?.rooms}
                                        onChange={handleRoomsDropdownChange}>
                                        <option>Select Rooms</option>
                                        <option>Room deluxe</option>
                                        <option>Room river view</option>
                                    </select>
                                    {errors.rooms && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ color: 'red' }}
                                        >
                                            {errors.rooms}
                                        </div>
                                    )}
                                </div>
                            </li>
                            <li>
                                <label className="form-label">Details*</label>
                                <div className="input-cont">
                                    <div>
                                        <label className="check-container">Basic deal
                                            <input type="checkbox"
                                                checked={hotelOffer.details.includes('Basic deal')}
                                                onChange={handleCheckboxChange}
                                                value="Basic deal"
                                            />
                                            <span className="checkmark"></span>
                                            <img src="images/information.svg" className="float-right" alt="" />
                                        </label>
                                        <label className="check-container">Minimum Stay
                                            <input type="checkbox"
                                                checked={hotelOffer.details.includes('Minimum Stay')}
                                                onChange={handleCheckboxChange}
                                                value="Minimum Stay"
                                            />
                                            <span className="checkmark"></span>
                                            <img src="images/information.svg" className="float-right" alt="" />
                                        </label>
                                        <label className="check-container">Credit card required
                                            <input type="checkbox"
                                                checked={hotelOffer.details.includes('Credit card required')}
                                                onChange={handleCheckboxChange}
                                                value="Credit card required"
                                            />
                                            <span className="checkmark"></span>
                                            <img src="images/information.svg" className="float-right" alt="" />
                                        </label>

                                    </div>
                                    {errors.details && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ color: 'red' }}
                                        >
                                            {errors.details}
                                        </div>
                                    )}

                                </div>
                            </li>
                            <li>
                                <label className="form-label">Discount*</label>
                                <div className="input-cont">
                                    <input type="text" name="discount"
                                        value={hotelOffer?.discount}
                                        onChange={(e) => { handleInputText(e) }}
                                        placeholder="Ex. 12 %" className="form-control" />
                                    {errors.discount && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ color: 'red' }}
                                        >
                                            {errors.discount}
                                        </div>
                                    )}
                                </div>
                            </li>
                            <li>
                                <label className="form-label">Target audience*</label>
                                <div className="input-cont">
                                    <select className="form-control"
                                        value={hotelOffer?.target_audience}
                                        onChange={handleAudienceDropdownChange}>
                                        <option>Select audience</option>
                                        <option>Business</option>
                                        <option>Couples</option>
                                    </select>
                                    {errors.target_audience && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ color: 'red' }}
                                        >
                                            {errors.target_audience}
                                        </div>
                                    )}
                                </div>
                            </li>
                            <li>
                                <label className="form-label">
                                    Availability*
                                </label>
                                <div className="input-cont">
                                    <h3>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={isAvailableChecked}
                                                onChange={
                                                    handleAvailableCheckboxChange
                                                }
                                            />
                                            <span className="slider round"></span>
                                        </label>{' '}
                                        {isAvailableChecked
                                            ? 'active'
                                            : 'inactive'}{' '}
                                        <img
                                            src="images/help.svg"
                                            className="vertical-middle"
                                            alt=""
                                        />
                                    </h3>
                                </div>
                            </li>
                            <li>
                                <label className="form-label">Dates*</label>
                                <div className="input-cont">
                                    <div className="nexted-form dtpicker_offer">
                                        <label>From</label>

                                        <DatePicker className="form-control datepicker-float-offer"
                                            selected={selectedFromDate}
                                            onChange={handleFromDtChange}
                                            dateFormat="dd/MM/yyyy" // Customize date format as needed
                                            placeholderText="Select start date"
                                        />

                                        <TimePicker selectedTime={selectedFromTime} handleTimeChange={handleFromTimeChange} />
                                    </div>
                                    <div className="nexted-form dtpicker_offer">
                                        <label>To</label>

                                        <DatePicker className="form-control datepicker-to-float-offer"
                                            selected={selectedToDate}
                                            onChange={handleToDtChange}
                                            dateFormat="dd/MM/yyyy" // Customize date format as needed
                                            placeholderText="Select end date"
                                        />
                                        <TimePicker selectedTime={selectedToTime} handleTimeChange={handleToTimeChange} />
                                        {/* initialTime="09:30 AM" */}
                                    </div>
                                    {errors.dates && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ color: 'red' }}
                                        >
                                            {errors.dates}
                                        </div>
                                    )}
                                </div>

                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row-view justify-end mt20">
                    <button
                        className="secondary-button mr20"
                        type="button"
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </button>
                    <button className="primary-button" type="submit">
                        Update
                    </button>
                </div>
            </div>
        </form>
    );
};

