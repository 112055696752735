import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import { MdSearch } from 'react-icons/md'
import { TbFilterPlus } from 'react-icons/tb'
import { cells } from '../../utils/mock/ReservationData'
import { Table } from '../../components/Table/Table'
import { getReservationListLazy } from '../../api/reservations'
import { Pagination } from '../../components/Generic/Paginations';
import { useNavigate } from 'react-router-dom';
import { BiSolidChevronDown } from 'react-icons/bi';
import useHotelApi from '../../api/hotel';
import { Loader } from '../../components/Generic/Loader';

export const Reservations = ({ handleLogout }) => {
    const { getHotelListLazy, getHotelReservations } = useHotelApi();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [selectedType, setSelectedType] = useState('Arrivals');
    const [reservations, setReservations] = useState([]);
    const [reservationsCount, setReservationsCount] = useState(0)
    const [arrivals, setArrivals] = useState([]);
    const [cancelled, setCancelled] = useState([]);
    const [departures, setDepartures] = useState([]);
    const [newBookings, setNewBookings] = useState([]);
    const [stayOvers, setStayOvers] = useState([]);
    const [guestsRequests, setGuestsRequests] = useState([]);
    const queryParams = new URLSearchParams(window.location.search)
    const [currentPage, setCurrentPage] = useState(parseInt(queryParams.get("page")));
    const [reservationsPerPage, setReservationsPerPage] = useState(parseInt(queryParams.get("limit")));
    const [pagesNb, setPagesNb] = useState(0)
    const [hotelsSearch, setHotelsSearch] = useState('');
    const [hotel, setHotel] = useState([])
    const [hotels, setHotels] = useState([])
    const [hotelsOpen, setHotelssOpen] = useState(false)
    const hotelsRef = useRef(null);

    const handleTypeReservationClick = (type) => {
        setSelectedType(type)
    }


    const renderTable = () => {

        switch (selectedType) {
            case 'Arrivals':
                return (
                    < Table cells={cells} data={arrivals.length !== 0 ? arrivals : 'No reservations yet...'} type="reservations" />
                )
            case 'Cancelled':
                return (
                    < Table cells={cells} data={cancelled.length !== 0 ? cancelled : 'No reservations yet...'} type="reservations" />
                )
            // case 'Departures':
            //     return (
            //         <Table
            //             cells={cells}
            //             data={departures.length !== 0 ? departures : 'No reservations yet...'}
            //             type="reservations"
            //         />
            //     )
            // case 'New bookings':
            //     return (
            //         <Table
            //             cells={cells}
            //             data={newBookings.length !== 0 ? newBookings : 'No reservations yet...'}
            //             type="reservations"
            //         />
            //     )
            // case 'Stay-Overs':
            //     return (
            //         <Table cells={cells} data={stayOvers.length !== 0 ? stayOvers : 'No reservations yet...'} type="reservations" />
            //     )
            // case 'Guests requests':
            //     return (
            //         <Table
            //             cells={cells}
            //             data={guestsRequests.length !== 0 ? guestsRequests : 'No reservations yet...'}
            //             type="reservations"
            //         />
            //     )
            default:
                return null
        }
    }

    const formatData = (data) => {
        let res = [];
        data.map((r) => {
            return (
                res.push({
                    'id': r?.reservation_sub,
                    'img': r?.user?.photo || null,
                    'name': `${r?.user?.firstname} ${r?.user?.lastname}` || '',
                    'checkin': `${moment(r?.checkin).format('ll')} at ${moment(r?.checkin).format('LT')}`,
                    'checkout': `${moment(r?.checkout).format('ll')} at ${moment(r?.checkout).format('LT')}`,
                    'roomType': r?.room,
                    'guest': parseInt(r?.adults) + parseInt(r?.childs),
                    'price': r?.amount + (r.currency === 'EUR' ? '€' : r?.currency),
                    'commission': `${(parseInt(r?.amount) * 0.15)}€`,
                    'state': r?.state,
                    // 'hotel': r?.hotel?.name,
                    'reservationId': r?.reservation_sub,
                    'controlNumber': r?.controlNumber
                })
            );
        })
        return res;
    }

    const retrieveReservations = (offset, limit) => {
        getHotelReservations(hotel?.sub, response => {
            if (response?.status === "success") {
                setReservations(response.content);
                setArrivals(formatData(response.content?.filter(el => el.state === 'Confirmed')))
                setCancelled(formatData(response.content?.filter(el => el.state === 'Cancelled')))
                setReservationsCount(response.content.length)
                // setPagesNb(Math.ceil(response?.content?.count / limit))
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        let token = localStorage.getItem('auth')
        if (token) {
            const expiration = JSON.parse(localStorage.getItem('expiration'))
            if (expiration < Date.now() / 1000) {
                handleLogout()
            }
        }
    }, [handleLogout])

    useEffect(() => {
        let page = 1
        let limit = 10
        if (currentPage > 1) {
            page = currentPage
        }
        if (reservationsPerPage > 0 && reservationsPerPage < 26) {
            limit = reservationsPerPage
        }

        setCurrentPage(page)
        setReservationsPerPage(limit)
        navigate(`/reservations?page=${page}&limit=${limit}`)
        retrieveReservations((page - 1) * limit, limit)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hotel])

    useEffect(() => {
        function handleClickOutside(event) {
            if (hotelsOpen && hotelsRef.current && !hotelsRef.current.contains(event.target)) {
                setHotelssOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [
        hotelsOpen,
        hotelsRef
    ]);

    const retrieveHotels = (search) => {
        getHotelListLazy(0, 10, search, undefined, response => {
            if (response?.status === 'success') {
                let hotels = [{ name: 'All Hotels' }, ...response?.content?.hotels]
                setHotels(hotels)
                setHotel(response?.content?.hotels?.[0])
            }
        })
    }

    useEffect(() => {
        retrieveHotels(hotelsSearch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loading ?
                <div className='row-view flex1 justify-center items-center'>
                    <Loader />
                </div>
                :
                <div className="page-container">
                    <div className="row-view justify-between">
                        <div>
                            <div className="size2 bold">Reservations</div>
                            <div className="size1 bold today">
                                <span>{reservationsCount} reservations</span>
                            </div>
                        </div>
                        <div className="row-view">
                            <button className="secondary-button borderless row-view items-center">
                                <div className="mr5">
                                    <TbFilterPlus />
                                </div>
                                <div>Filter</div>
                            </button>
                            <div className="secondary-search-bar-container row-view items-center justify-center">
                                <div className="mr10">
                                    <MdSearch size={20} />
                                </div>
                                <input
                                    type="text"
                                    placeholder="Search reservations"
                                    className="secondary-search-bar"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='relative' style={{ paddingTop: "2%", paddingBottom: "2%", display: "inline-block" }}>
                        {hotelsOpen ?
                            <input
                                type='text'
                                value={hotelsSearch}
                                className='input-text w300'
                                autoFocus
                                onChange={(e) => {
                                    setHotelsSearch(e.target.value)
                                    retrieveHotels(e.target.value)
                                }}
                            />
                            :
                            <div
                                className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer'
                                onClick={() => setHotelssOpen(!hotelsOpen)}
                            >
                                <div>
                                    {hotel?.name
                                        ? hotel?.name
                                        : 'Select hotel'}
                                </div>
                                <BiSolidChevronDown />
                            </div>
                        }
                        <div className={`select-menu ${hotelsOpen && 'open'}`} style={{ maxHeight: 300, overflow: "scroll" }} ref={hotelsRef}>
                            {hotels?.length !== 0 ? (
                                <>
                                    {hotels?.map((h, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className='select-item'
                                                onClick={() => {
                                                    setHotel(h)
                                                    setHotelssOpen(false)
                                                }}
                                            >
                                                <span className='section-title'>
                                                    {h?.name}<span className='grey-text'> - {h?.address?.city}</span>
                                                </span>
                                            </div>
                                        )
                                    })}
                                </>
                            ) : (
                                <>
                                    <div className='select-item'>
                                        No hotels found
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row-view pb20 mt50">
                        <div
                            className={`row-view items-center mr50 cursor-pointer pv4 ${selectedType === 'Arrivals' && 'black-text bold bb161616'}`}
                            onClick={() => handleTypeReservationClick('Arrivals')}
                        >
                            <div className={`mr5`}>
                                Arrivals
                            </div>
                            {arrivals.length !== 0 && <div className="notif">{arrivals.length}</div>}
                        </div>
                        <div
                            className={`row-view items-center mr50 cursor-pointer pv4 ${selectedType === 'Cancelled' && 'black-text bold bb161616'}`}
                            onClick={() => handleTypeReservationClick('Cancelled')}
                        >
                            <div className={`mr5`}>
                                Cancelled
                            </div>
                            {cancelled.length !== 0 && <div className="notif">{cancelled.length}</div>}
                        </div>
                        {/* <div className={`row-view items-cente mr50 cursor-pointer pv4 ${selectedType === 'Departures' && 'black-text bold bb161616'}`}
                            onClick={() => handleTypeReservationClick('Departures')}
                        >
                            <div className={`mr5`}>
                                Departures
                            </div>
                            {departures.length !== 0 && <div className="notif">{departures.length}</div>}
                        </div>
                        <div
                            className={`row-view items-center mr50 cursor-pointer pv4 ${selectedType === 'New bookings' && 'black-text bold bb161616'}`}
                            onClick={() => handleTypeReservationClick('New bookings')}
                        >
                            <div className={`mr5`}>
                                New bookings
                            </div>
                            {newBookings.length !== 0 && <div className="notif">{newBookings.length}</div>}
                        </div>
                        <div
                            className={`row-view items-center mr50 cursor-pointer pv4 ${selectedType === 'Stay-Overs' && 'black-text bold bb161616'}`}
                            onClick={() => handleTypeReservationClick('Stay-Overs')}
                        >
                            <div className={`mr5`}>
                                Stay-Overs
                            </div>
                            {stayOvers.length !== 0 && <div className="notif">{stayOvers.length}</div>}
                        </div>
                        <div
                            className={`row-view items-center mr50 cursor-pointer pv4 ${selectedType === 'Guests requests' && 'black-text bold bb161616'}`}
                            onClick={() => handleTypeReservationClick('Guests requests')}
                        >
                            <div className={`mr5`}>
                                Guests requests
                            </div>
                            {guestsRequests.length !== 0 && <div className="notif">{guestsRequests.length}</div>}
                        </div> */}
                    </div>

                    <div className='of-scroll'>
                        {renderTable()}
                    </div>

                    <Pagination
                        type='Reservation'
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        itemsPerPage={reservationsPerPage}
                        pagesNb={pagesNb}
                        setItemsPerPage={setReservationsPerPage}
                        path='reservations'
                        retrieveData={retrieveReservations}
                    />
                </div>
            }
        </>
    )
}
export default Reservations
