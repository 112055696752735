import React, { useEffect, useState } from 'react';
import colors from '../../colors/colors';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdOutlineCheckCircle } from 'react-icons/md';
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

export const InviteForm = ({ email, setEmail, password, setPassword, confirmPassword, setConfirmPassword, pinCode, setPinCode, errorMessage, handleVerify }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isPassword8, setPassword8] = useState(false);
    const [isPasswordNumber, setPasswordNumber] = useState(false);
    const [isPasswordUppercase, setPasswordUppercase] = useState(false);
    const [isPasswordLowercase, setPasswordLowercase] = useState(false);
    const [isPasswordSpecialChar, setPasswordSpecialChar] = useState(false);
    const [isPasswordMatch, setPasswordMatch] = useState(false);

    useEffect(() => {
        setPassword8(password.length >= 8);
        setPasswordNumber(/\d/.test(password));
        setPasswordUppercase(/[A-Z]/.test(password));
        setPasswordLowercase(/[a-z]/.test(password));
        setPasswordSpecialChar(/[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password));
        setPasswordMatch(confirmPassword === password);
    }, [password, confirmPassword]);
    return (
        <form
            className='login-container'
            onSubmit={(e) => {
                e.preventDefault();
                if (isPasswordMatch) {
                    handleVerify();
                }
            }}
        >
            <div>Email address</div>
            <input
                required
                className='input-text mt10'
                type='email'
                placeholder='Email address'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <div className='mt10'>Pin Code</div>
            <input
                required
                className='input-text mt10'
                type='text'
                placeholder='Pin Code'
                value={pinCode}
                maxLength={6}
                onChange={(e) => {
                    let trimmedValue = e.target.value.replace(/\s/g, '');
                    setPinCode(trimmedValue);
                }}
            />
            <div className='mt10'>Password</div>
            <div className='column-view relative'>
                <input
                    required
                    className={`input-text mt10 ${!isPassword8 || !isPasswordNumber || !isPasswordLowercase || !isPasswordUppercase ? 'bRed oRed' : ''}`}
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div className='absolute cursor-pointer' style={{ right: 10, top: '50%', transform: 'translateY(-25%)' }} onClick={() => setShowPassword(prev => !prev)}>
                    {showPassword ?
                        <IoMdEyeOff size={20} />
                        :
                        <IoMdEye size={20} />
                    }
                </div>
            </div>
            <div className='column-view mt10'>
                <div className='row-view items-center'>
                    {isPassword8 ?
                        <MdOutlineCheckCircle color={colors.green} />
                        :
                        <AiOutlineCloseCircle color={colors.red} />
                    }
                    <span className={isPassword8 ? 'green-text' : 'red-text'}>Has at least 8 characters</span>
                </div>
                <div className='row-view items-center'>
                    {isPasswordNumber ?
                        <MdOutlineCheckCircle color={colors.green} />
                        :
                        <AiOutlineCloseCircle color={colors.red} />
                    }
                    <span className={isPasswordNumber ? 'green-text' : 'red-text'}>Contains at least one number</span>
                </div>
                <div className='row-view items-center'>
                    {isPasswordUppercase ?
                        <MdOutlineCheckCircle color={colors.green} />
                        :
                        <AiOutlineCloseCircle color={colors.red} />
                    }
                    <span className={isPasswordUppercase ? 'green-text' : 'red-text'}>Contains at least one uppercase letter</span>
                </div>
                <div className='row-view items-center'>
                    {isPasswordLowercase ?
                        <MdOutlineCheckCircle color={colors.green} />
                        :
                        <AiOutlineCloseCircle color={colors.red} />
                    }
                    <span className={isPasswordLowercase ? 'green-text' : 'red-text'}>Contains at least one lowercase letter</span>
                </div>
                <div className='row-view items-center'>
                    {isPasswordSpecialChar ?
                        <MdOutlineCheckCircle color={colors.green} />
                        :
                        <AiOutlineCloseCircle color={colors.red} />
                    }
                    <span className={isPasswordSpecialChar ? 'green-text' : 'red-text'}>Contains at least one special chararter</span>
                </div>
            </div>
            <div className='mt10'>Confirm Password</div>
            <div className='column-view relative'>
                <input
                    required
                    className={`input-text mt10 ${!isPasswordMatch && 'bRed oRed'}`}
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder='Confirm Password'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <div className='absolute cursor-pointer' style={{ right: 10, top: '50%', transform: 'translateY(-25%)' }} onClick={() => setShowConfirmPassword(prev => !prev)}>
                    {showConfirmPassword ?
                        <IoMdEyeOff size={20} />
                        :
                        <IoMdEye size={20} />
                    }
                </div>
            </div>
            <div className='row-view items-center mt10'>
                {isPasswordMatch ?
                    <MdOutlineCheckCircle color={colors.green} />
                    :
                    <AiOutlineCloseCircle color={colors.red} />
                }
                {isPasswordMatch ?
                    <span className='green-text'>Password matching</span>
                    :
                    <span className='red-text'>Password are not matching</span>
                }
            </div>

            <button
                type='submit'
                className={`primary-button mt20 ${!isPassword8 || !isPasswordNumber || !isPasswordLowercase || !isPasswordUppercase || !isPasswordSpecialChar || !isPasswordMatch ? 'opacity60' : ''}`}
                disabled={!isPassword8 || !isPasswordNumber || !isPasswordLowercase || !isPasswordUppercase || !isPasswordSpecialChar || !isPasswordMatch}
            >
                Send
            </button>
            {errorMessage !== '' && <div className='error-message mt10'>{errorMessage}</div>}
        </form>
    );
};