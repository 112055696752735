import React, { useState, useRef } from 'react';
import moment from 'moment';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { MdCheck } from 'react-icons/md';
import colors from '../../../colors/colors';
import addImg from '../../../assets/icons/AddImgOrange.svg';
import 'react-calendar/dist/Calendar.css';
import 'react-calendar-datetime-picker/dist/style.css';
import { hotel_profile_tags } from '../../../utils/mock/HotelData';

import { MdChevronLeft, MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { createRestaurant } from '../../../api/hotel_restaurant';

const openingEvents = [
    { icon: '🥞', label: 'Breakfast' },
    { icon: '🥗', label: 'Lunch' },
    { icon: '🥐', label: 'Brunch' },
    { icon: '🥘', label: 'Diner' }
];

const weekDays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
];

export const AddRestaurants = ({ hotelSub, setActiveTab }) => {
    const [infoModal, setInfoModal] = useState(false);

    const navigate = useNavigate();
    // const [date, setDate] = React.useState(new Date())
    const hiddenFileInput = React.useRef(null);
    const [photoInput, setPhotoInput] = useState(null);
    const [newDate, setNewDate] = useState(null);
    const currentDate = moment();
    const [addFoodTypeTag, setAddFoodTypeTag] = useState('');
    const [addFoodTypeTagFocused, setAddFoodTypeTagFocused] = useState(false);
    const addFoodTypeTagRef = useRef(null);

    // Utilise la date formatée comme bon te semble dans ton composant
    const minDate = {
        year: parseInt(currentDate.format('YYYY')),
        month: parseInt(currentDate.format('MM')),
        day: parseInt(currentDate.format('DD'))
    };

    const [restaurant, setRestaurant] = useState({
        foodType: [],
        active: true,
        images: [],
        opening: {
            events: [],
            days: []
        }
    });

    const containsObject = (obj, list) => {
        return list.some(item => item.icon === obj.icon && item.label === obj.label);
    };

    const handleTag = (key, value) => {
        if (restaurant[key] === undefined) {
            setRestaurant({ ...restaurant, [key]: [value] });
            return;
        }
        if (containsObject(value, restaurant[key])) {
            setRestaurant({ ...restaurant, [key]: restaurant[key].filter((el) => el !== value) });
        } else {
            setRestaurant({ ...restaurant, [key]: [...restaurant[key], value] });
        }
    };

    const handleInputPhoto = async (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = async () => {
                const img = new Image();
                img.src = reader.result;

                img.onload = async () => {
                    const width = img.width;
                    const height = img.height;
                    const isPortrait = width < height;
                    const rotatedImageDataURL = isPortrait ? await rotateImage(reader.result) : reader.result;
                    const compressedImageDataURL = await compressImage(rotatedImageDataURL, 0.4);
                    setRestaurant({
                        ...restaurant,
                        images: [...restaurant?.images ?? [], compressedImageDataURL]
                    });
                    setPhotoInput(null);
                };
            };

            reader.readAsDataURL(file);
        }
    };

    const rotateImage = async (imageUrl) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.height;
                canvas.height = img.width;

                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate((90 * Math.PI) / 180);
                ctx.drawImage(img, -img.width / 2, -img.height / 2);

                resolve(canvas.toDataURL('image/jpeg', 0.8));
            };
        });
    };

    const compressImage = async (imageUrl, quality) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                canvas.toBlob((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(blob);
                }, 'image/jpeg', quality);
            };
        });
    };

    const handlePhoto = (key) => {
        hiddenFileInput.current.click();
        setPhotoInput(key);
    };

    const handleInputText = (e) => {
        setRestaurant({ ...restaurant, [e.target.name]: e.target.value });
    };

    const handleSubmit = () => {
        let dates = [
            moment(newDate?.from).format(),
            moment(newDate?.to).format()
        ];
        let newRestaurant = {
            ...restaurant,
            dates: dates
        };
        createRestaurant(hotelSub, newRestaurant, response => {
            if (response?.status === 'success') {
                setActiveTab(0);
            } else {
                alert(response?.message);
            }
        });
    };

    return (
        <div className="column-view">
            <div className="row-view items-center">
                <MdChevronLeft
                    size={50}
                    className="cursor-pointer"
                    onClick={() => setActiveTab(0)}
                />
                <div className="title-secondary">Add Restaurant</div>
            </div>

            <div className="column-view flex1">
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <div className="subtitle mt50 ml50">General information</div>
                    <div className="border-container r8 mt20">
                        <div className="row-view items-center p20 bb">
                            <div className="section-title flex04">Name<span className='primary-text-color'>*</span></div>
                            <div className="row-view items-center ml20 flex1">
                                <div>
                                    <input
                                        className="input-text w300"
                                        name="name"
                                        placeholder="Restaurant name..."
                                        value={restaurant?.name}
                                        onChange={(e) => {
                                            handleInputText(e);
                                        }}
                                    />
                                </div>
                                <div
                                    className={`checkbox cursor-pointer r4 ml20 mr10 ${restaurant?.active && 'selected'}`}
                                    onClick={() => {
                                        setRestaurant({ ...restaurant, active: !restaurant.active });
                                    }}
                                >
                                    {restaurant?.active && <MdCheck color='white' />}
                                </div>
                                <div className='bold mr10'>Set active</div>
                                <div className='relative row-view items-center'>
                                    <BsFillQuestionCircleFill color={colors.primary} size={20} onClick={() => setInfoModal(!infoModal)} />
                                    <div className={`info-modal ${infoModal && 'active'}`}>
                                        Set <span className='green-text bold'>"The hotel restaurant"</span> active.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row-view items-center p20 bb">
                            <div className="section-title flex04">
                                Presentation
                            </div>
                            <div className="row-view flex1 ml20">
                                <textarea
                                    className="input-text flex1"
                                    rows={10}
                                    value={restaurant?.description}
                                    name="description"
                                    placeholder="Describe your restaurant..."
                                    onChange={(e) => handleInputText(e)}
                                />
                            </div>
                        </div>
                        <div className="row-view items-center p20 bb">
                            <div className="section-title flex04">Pictures</div>
                            <div className='flex1 ofx-hidden'>
                                <div className='row-view flex1 items-center ofx-scroll'>
                                    {restaurant?.images?.map((image, index) => (
                                        <div className='relative p10'>
                                            <img
                                                width={250}
                                                height={150}
                                                style={{ objectFit: 'cover' }}
                                                alt={index}
                                                key={index}
                                                src={image}
                                                className='r8'
                                            />
                                            <div
                                                className="close-icon"
                                                onClick={() => {
                                                    let imgs = restaurant?.images;
                                                    imgs.splice(index, 1);
                                                    setRestaurant({
                                                        ...restaurant,
                                                        imgaes: imgs,
                                                    });
                                                }}
                                            >
                                                <MdClose size={20} color="white" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className={`row-view items-center gap20 ${restaurant?.images?.length > 0 && 'mt20'}`}>
                                    <div
                                        className="row-view border-container b-dashed b2 r8 p20 cursor-pointer items-center justify-center"
                                        onClick={() => handlePhoto('images')}
                                    >
                                        <img src={addImg} alt="addImg" />
                                    </div>
                                    <div className="section-subtitle">
                                        (JPGs or PNGs of at least 1080x566 px)
                                    </div>
                                </div>
                            </div>
                        </div>

                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            ref={hiddenFileInput}
                            onChange={(e) => handleInputPhoto(e)}
                            style={{ display: 'none' }}
                        />
                        <div className="row-view items-center p20 bb">
                            <div className="section-title flex04">
                                Food type<span className='primary-text-color'>*</span>
                            </div>
                            <div className="flex1">
                                <div>
                                    <div className="row-view wrap flex1">
                                        {hotel_profile_tags.profiling_user.favorite_food_type.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={`row-view tag mr20 mt10 mb10 ${restaurant?.foodType && containsObject(item, restaurant?.foodType) &&
                                                        'selected'
                                                        }`}
                                                    onClick={() =>
                                                        handleTag('foodType', item)
                                                    }
                                                >
                                                    <div>{item.icon} {item.label}</div>
                                                </div>
                                            );
                                        })}
                                        {restaurant?.foodType?.filter(el => !containsObject(el, hotel_profile_tags.profiling_user.favorite_food_type))?.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={`row-view tag mr20 mt10 mb10 ${restaurant?.foodType && containsObject(item, restaurant?.foodType) &&
                                                        'selected'
                                                        }`}
                                                    onClick={() =>
                                                        handleTag('foodType', item)
                                                    }
                                                >
                                                    <div>{item.icon} {item.label}</div>
                                                </div>
                                            );
                                        })}
                                        <input
                                            type='text'
                                            ref={addFoodTypeTagRef}
                                            placeholder={!addFoodTypeTagFocused && '+Add tag'}
                                            className={`borderless none-outline mr20 mt10 mb10 ${addFoodTypeTagFocused && 'tag'}`}
                                            size={addFoodTypeTag.length || 4}
                                            value={addFoodTypeTag}
                                            onChange={(e) => setAddFoodTypeTag(e.target.value)}
                                            onFocus={() => setAddFoodTypeTagFocused(true)}
                                            onBlur={() => {
                                                let tag = { icon: '🏷️', label: addFoodTypeTag };
                                                if (addFoodTypeTag?.length < 1 || containsObject(tag, restaurant?.foodType)) {
                                                    setAddFoodTypeTag('');
                                                    setAddFoodTypeTagFocused(false);
                                                    addFoodTypeTagRef.current.blur();
                                                    return;
                                                }
                                                let tags = restaurant?.foodType ?? [];
                                                tags.push(tag);
                                                setRestaurant({ ...restaurant, foodType: tags });
                                                setAddFoodTypeTag('');
                                                setAddFoodTypeTagFocused(false);
                                                addFoodTypeTagRef.current.blur();
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    addFoodTypeTagRef.current.blur();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row-view items-center p20 bb">
                            <div className="section-title flex04">
                                Opening<span className='primary-text-color'>*</span>
                            </div>
                            <div className="column-view flex1 ml20 w100">
                                <h3 className='bold'>By events</h3>
                                <div className='row-view items-center'>
                                    {openingEvents.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`row-view tag mr20 mt10 mb10 ${restaurant?.opening?.events && containsObject(item, restaurant?.opening?.events) &&
                                                    'selected'
                                                    }`}
                                                onClick={() => {
                                                    if (restaurant?.opening?.events === undefined) {
                                                        setRestaurant({ ...restaurant, opening: { ...restaurant?.opening, events: [item] } });
                                                        return;
                                                    }
                                                    if (containsObject(item, restaurant?.opening?.events)) {
                                                        setRestaurant({ ...restaurant, opening: { ...restaurant?.opening, events: restaurant?.opening?.events?.filter((el) => el?.label !== item?.label) } });
                                                    } else {
                                                        setRestaurant({ ...restaurant, opening: { ...restaurant?.opening, events: [...restaurant?.opening?.events, item] } });
                                                    }
                                                }}
                                            >
                                                <div>{item.icon} {item.label}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <h3 className='bold mt20'>By days</h3>
                                <div className='row-view items-center'>
                                    {weekDays.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`row-view tag mr20 mt10 mb10 ${restaurant?.opening?.days && restaurant?.opening?.days?.includes(item) &&
                                                    'selected'
                                                    }`}
                                                onClick={() => {
                                                    if (restaurant?.opening?.days === undefined) {
                                                        setRestaurant({ ...restaurant, opening: { ...restaurant?.opening, days: [item] } });
                                                        return;
                                                    }
                                                    if (restaurant?.opening?.days?.includes(item)) {
                                                        setRestaurant({ ...restaurant, opening: { ...restaurant?.opening, days: restaurant?.opening?.days?.filter((el) => el !== item) } });
                                                    } else {
                                                        setRestaurant({ ...restaurant, opening: { ...restaurant?.opening, days: [...restaurant?.opening?.days, item] } });
                                                    }
                                                }}
                                            >
                                                <div>{item}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <input
                        type="file"
                        accept="image/png, image/jpeg"
                        ref={hiddenFileInput}
                        onChange={(e) => handleInputPhoto(e)}
                        style={{ display: 'none' }}
                    />
                    <div className="row-view justify-end mt20">
                        <button
                            className="secondary-button mr20"
                            type="button"
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </button>
                        <button className="primary-button" type="submit">
                            Create
                        </button>
                    </div>
                </form >
            </div>
        </div>
    );
};
