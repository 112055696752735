/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { MdAccountCircle, MdChevronLeft } from 'react-icons/md'
import { IoMoonSharp } from 'react-icons/io5'
import { BiSolidDownload } from 'react-icons/bi'
import { BiMessageDetail } from 'react-icons/bi'
import { getReservationContent } from '../../api/reservations'

const advantages = [
    { icon: '🌄', name: 'View' },
    { icon: '🛁', name: '1 bathtub' },
    { icon: '🛏️', name: '1 king size bed' },
    { icon: '🥐', name: 'Breakfast included' }
]

export const ViewReservation = ({ handleLogout }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [reservationSub, setReservationSub] = useState(location.pathname.replace('/reservations/', ''));
    const [reservation, setReservation] = useState({});

    useEffect(() => {
        let token = (localStorage.getItem('auth'));
        if (token) {
            const expiration = JSON.parse(localStorage.getItem('expiration'));
            if (expiration < Date.now() / 1000) {
                handleLogout();
            }
        }
        getReservationContent(reservationSub, response => {
            if (response.status === 'success') {
                setReservation(response.content)
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleLogout]);

    return (
        <div className="page-container">
            <div className="row-view justify-between">
                <div className="row-view items-center">
                    <MdChevronLeft size={50} onClick={() => navigate(-1)} />
                    <div className="title-secondary">Reservation</div>
                </div>

                <div className="row-view">
                    <div className="row-view items-center">
                        <div className='column-view'>
                            <div className='grey-text'>
                                <span className='bold'>ORDER ID :</span> {reservation?.reservation_sub}
                            </div>
                            <div className='grey-text'>
                                <span className='bold'>CONTROL NUMBER :</span> {reservation?.controlNumber}
                            </div>
                        </div>
                        <div
                            className={`row-view justify-center items-center ml20 p10 r4 bold ${reservation?.state === 'Confirmed' ? 'greenbg01 primary-text-color2' : reservation?.state === 'Cancelled' ? 'regbg01 red-text' : 'orangebg01 orange-text'}`}
                        >
                            {reservation?.state}
                        </div>
                    </div>
                </div>
            </div>

            <div className='border-container r8 p20 mt50'>
                <div className='row-view'>
                    <div className='mr20'>
                        {reservation?.user?.photo ?
                            <img src={reservation?.user?.photo} alt='img' style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 100 }} />
                            :
                            <MdAccountCircle color='#999999' size={80} />
                        }
                    </div>
                    <div className='column-view justify-between flex1'>
                        <div className='row-view items-center justify-between'>
                            <div className='title-secondary mr10'>{reservation?.user?.firstname} {reservation?.user?.lastname}</div>
                            <div className='subtitle'>{reservation?.hotel?.name}</div>
                        </div>
                        <div className='row-view items-center'>
                            <div className='grey-text mr10'>{reservation?.adults} adults {reservation?.childs && reservation?.childs !== '' && ` - ${reservation.childs} Childrens`} - </div>
                            <Link
                                className='primary-text-color'
                                to={{
                                    pathname: `/travelers/profile/${reservation?.user?.sub}`,
                                }}
                            >
                                View profiles
                            </Link>
                        </div>
                        <div className='grey-text'>{moment(reservation?.checkin).format('ddd, D MMM YY')} - {moment(reservation?.checkout).format('ddd, D MMM YY')}</div>
                    </div>
                </div>

                <div className='h-separator bgDADADA mt50' />

                <div className='row-view justify-between mt50'>
                    <div className=''>
                        <div className='subtitle'>Check-In</div>
                        <div className='grey-text mt10'>{moment(reservation?.checkin).format('ddd, D MMM YY')}</div>
                        <div className='bold mt5'>Starting at {moment(reservation?.checkin).format('LT')}</div>
                    </div>
                    <div className="row-view items-center justify-center bold primary-text-color greenbg01 w80 h50 r100">
                        <IoMoonSharp size={20} className='mr5' /> {moment(reservation?.checkout).diff(moment(reservation?.checkin), 'days')}
                    </div>
                    <div className=''>
                        <div className='flex1'>
                            <div className='subtitle'>Check-Out</div>
                            <div className='grey-text mt10'>{moment(reservation?.checkout).format('ddd, D MMM YY')}</div>
                            <div className='bold mt5'>Until at {moment(reservation?.checkout).format('LT')}</div>
                        </div>
                    </div>
                </div>


                <div className='h-separator bgDADADA mt50' />

                <div className='row-view mt50'>
                    <div className='subtitle black-text flex02'>Advantages</div>
                    <div className='row-view justify-center flex06'>
                        {reservation?.advantages && advantages.map((item, index) => {
                            if (reservation.advantages.includes(item.name)) {
                                return (
                                    <div key={index} className='row-view items-center'>
                                        <div className='mr5'>{item.icon}</div>
                                        <div className='bold mr10'>{item.name}</div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                    <div className='flex02' />
                </div>

                <div className='h-separator bgDADADA mt50' />

                <div className='mt50'>
                    <div className='subtitle text-underline'>{reservation?.room}</div>
                    <div className='row-view justify-between mt20'>
                        <div className='flex02 p20 bgFAFAFA'>
                            <div className='bold'>Overral information</div>
                            {reservation?.overral_information && reservation?.overral_information.map((item, index) => {
                                return (
                                    <div key={index} className='grey-text mt5'>{item}</div>
                                )
                            })}
                        </div>
                        <div className='flex02 p20 bgFAFAFA'>
                            <div className='bold'>Requests</div>
                            {reservation?.requests && reservation?.requests.map((item, index) => {
                                return (
                                    <div key={index} className='grey-text mt5'>{item}</div>
                                )
                            })}
                        </div>
                        <div className='flex02 p20 bgFAFAFA'>
                            <div className='bold'>Minibar</div>
                            {reservation?.minibar && reservation?.minibar.map((item, index) => {
                                return (
                                    <div key={index} className='grey-text mt5'><span className='bold'>{item.article}</span> x{item.quantity}</div>
                                )
                            })}
                        </div>
                        <div className='flex02 p20 bgFAFAFA'>
                            <div className='bold'>Minibar</div>
                            {reservation?.music_list && reservation?.music_list.map((item, index) => {
                                return (
                                    <div key={index} className='grey-text mt5'><span className='bold'>{item.title}</span> - {item.artist}</div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='text-end title-secondary mt50'>Total: {reservation?.amount} {reservation?.currency === 'EUR' ? '€' : reservation?.currency}</div>
                </div>

                {/* <div className='h-separator bgDADADA mt50' /> */}

                {/* <div className=''>
                    <div className='subtitle black-text mt50'>{reservation?.room}</div>
                    <div className='row-view flex1 mt10'>
                        <textarea
                            rows={8}
                            className="input-text flex1"
                            placeholder="Write your comment..."
                        />
                    </div>
                </div> */}

                <div className='h-separator bgDADADA mt50' />

                {/* <div className='row-view justify-between mt20'>
                    <button
                        className="secondary-button row-view justify-center items-center flex04"
                    >
                        <BiMessageDetail
                            size={20}
                            className='mr5'
                        />
                        Message
                    </button>
                    <button
                        className="primary-button row-view justify-center items-center flex04"
                    >
                        <BiSolidDownload
                            size={20}
                            className='mr5'
                        />
                        Download reservation
                    </button>
                </div> */}
            </div>
        </div>
    )
}
