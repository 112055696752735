import React from 'react';

export const GoalProgressLine = ({ rate, max }) => {
    const getPercent = (value) => {
        return (value / max) * 100;
    };

    return (
        <div>
            <div className='mt10'>
                <div className='w100 r8 mt10' style={{ height: 8, backgroundColor: '#F6C0B4' }}>
                    <div className='bgPrimary r8' style={{ height: 8, width: `${getPercent(rate)}%` }} />
                </div>
            </div>
        </div>
    );
};
