import React, { useEffect, useState } from 'react';

import addImg from '../../assets/icons/AddImgOrange.svg';

import { MdChevronLeft, MdClose, MdCheck } from 'react-icons/md';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { BiSolidChevronDown } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import useHotelApi from '../../api/hotel';
import { getMyUser, getUser, updateUser } from '../../api/user';
import { AiOutlineCloudUpload } from "react-icons/ai";
import { HiDocument } from 'react-icons/hi';
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi';
import colors from '../../colors/colors';

const locations = [
    { city: 'Cannes', country: 'France' },
    { city: 'Paris', country: 'France' },
    { city: 'Prague', country: 'Czech' }
];

export const Settings = ({ handleLogout }) => {
    const { getHotelListLazy, getHotelManagerList } = useHotelApi();
    const navigate = useNavigate();
    const [infoModal, setInfoModal] = useState(false);
    const [hotels, setHotels] = useState([]);
    const [locationOpen, setLocationsOpen] = useState(false);
    const [managers, setManagers] = useState([]);
    const [selectedPage, setSelectedPage] = useState("Personal");
    const [personal, setPersonal] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [password, setPassword] = useState({
        newpassword: '',
        confnewpassword: ''
    });
    const [user, setUser] = useState({
        firstname: '',
        lastname: '',
        position: '',
        nationality: [],
        roles: [],
        available: false,
        entity: [],
        locations: [],
        manager: [],
        email: '',
        phone: '',
        access: false,
        photo: '',
        notifications: [
            {
                messages: false,
                mentions: false,
                hotelCreation: false,
                newUser: false,
                hotelStatus: false,
                ticketCreation: false,
                hotelierMessage: false
            }
        ]
    });

    const [document, setDocument] = useState({
        name: '',
        date: '',
        size: '',
        type: '',
        file: ''
    });

    const handlePage = (page) => {
        setSelectedPage(page);
    };

    const hiddenFileInput = React.useRef(null);
    const [documentInput, setDocumentInput] = useState(null);

    const handlePhoto = () => {
        hiddenFileInput.current.click();
    };

    const handleInputPhoto = (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;

                img.onload = () => {
                    if (img.width >= 150 && img.height >= 150) {
                        setUser({ ...user, photo: e.target.result });
                    } else {
                        alert('Image size must be at least 150x150 pixels ');
                    }
                };
            };

            reader.readAsDataURL(fileUploaded);
        }
    };

    const handleDocument = (key) => {
        hiddenFileInput.current.click();
        setDocumentInput(key);
    };

    const handleInputDocuments = (event) => {

        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const reader = new FileReader();

            reader.onload = (e) => {
                switch (documentInput) {
                    case 'document':
                        setDocument({ ...document, name: event.target.files[0].name, date: event.target.files[0].lastModifiedDate.toDateString(), size: parseFloat(event.target.files[0].size / 1000000).toFixed(2), type: event.target.files[0].type, file: e.target.result });
                        break;
                    default:
                        return;
                }
            };

            reader.readAsDataURL(fileUploaded);
        }
    };

    const handleCheckbox = (type, value) => {
        console.log()
        setUser({
            ...user,
            notifications: [
                { ...user?.notifications?.[0], [type]: value }
            ]
        });
    };

    console.log(user)

    const handleInputText = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSelect = (key, value) => {
        setUser({ ...user, [key]: [value] });
    };

    const handleInputPassword = (e) => {
        setPassword({ ...password, [e.target.name]: e.target.value });
    };


    const handleSubmitUser = () => {
        updateUser(user.sub, user, (response) => {
            if (response?.status === "success") {
                navigate(-1);
            } else {
                alert(response?.message);
            }
        });
    };

    const handleSubmitPassword = () => {
        console.log(password);
    };

    useEffect(() => {
        let token = (localStorage.getItem('auth'));
        if (token) {
            const expiration = JSON.parse(localStorage.getItem('expiration'));
            if (expiration < Date.now() / 1000) {
                handleLogout();
            }
        }
    }, [handleLogout]);

    useEffect(() => {
        getMyUser(response => {
            if (response?.status === 'success') {
                setUser(response?.content);
            }
        });
    }, []);

    const renderPage = () => {
        switch (selectedPage) {
            case "Personal":
                return (
                    <div className="page-container">
                        {/* Premier formulaire */}
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmitUser();
                            }}
                        >
                            {infoModal &&
                                <div
                                    className='filter-darker'
                                    onClick={() => {
                                        if (infoModal) {
                                            setInfoModal(false);
                                        }
                                    }}
                                />
                            }

                            <div className='subtitle mt10'>General information*</div>
                            <div className='border-container r8 mt20'>
                                <div className='row-view items-center p20 bb'>
                                    <div className='section-title flex04'>First name*</div>
                                    <div className='row-view flex1 items-center'>
                                        <input className='input-text w300 ph20' name='firstname' placeholder='First name...' value={user?.firstname} onChange={(e) => handleInputText(e)} />
                                    </div>
                                </div>
                                <div className='row-view items-center p20 bb'>
                                    <div className='section-title flex04'>Last name*</div>
                                    <div className='row-view flex1 items-center'>
                                        <input className='input-text w300 ph20' name='lastname' placeholder='Last name...' value={user?.lastname} onChange={(e) => handleInputText(e)} />
                                    </div>
                                </div>
                                <div className='row-view items-center p20 bb'>
                                    <div className='section-title flex04'>Position*</div>
                                    <div className='row-view flex1 items-center'>
                                        <input className='input-text w300 ph20' disabled name='position' placeholder='Position...' value={user?.roles?.[0]} onChange={(e) => handleInputText(e)} />
                                    </div>
                                </div>
                                <div className='row-view items-center p20 bb'>
                                    <div className='section-title flex04'>Photo*</div>
                                    <div className='row-view flex1 items-center'>
                                        <div className='row-view items-center'>
                                            {!user?.photo ?
                                                <div
                                                    className="row-view border-container b-dashed b2 r8 p20 cursor-pointer items-center justify-center"
                                                    onClick={(e) => handlePhoto(e)}
                                                >
                                                    <img
                                                        src={addImg}
                                                        alt='addImg'
                                                    />
                                                </div>
                                                :
                                                <div
                                                    className='p20 mr20 relative'
                                                    onClick={(e) => handlePhoto(e)}
                                                >
                                                    <img
                                                        className='r8'
                                                        src={user?.photo}
                                                        alt="staffPhoto"
                                                        style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                                                    />
                                                    <div
                                                        className='close-icon'
                                                        onClick={() => setUser({ ...user, photo: null })}
                                                    >
                                                        <MdClose size={20} color='white' />
                                                    </div>
                                                </div>
                                            }
                                            <div className='section-subtitle'>(JPGs or PNGs of at least 150x150px)</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row-view items-center p20 bb'>
                                    <div className='section-title flex04'>Availability*</div>
                                    <div className='row-view flex1 items-center'>
                                        <div className='row-view items-center w300'>
                                            <div className={`toggle mr10 ${user?.available && 'checked'}`} onClick={() => setUser({ ...user, available: !user?.available })}>
                                                <div className={`toggle-span ${user?.available && 'checked'}`} />
                                            </div>
                                            <div className='section-title mr10'>Available</div>
                                            <div className='relative row-view items-center'>
                                                <BsFillQuestionCircleFill className='cursor-pointer' color={colors.primary} size={20} onClick={() => setInfoModal(!infoModal)} />
                                                <div className={`info-modal ${infoModal && 'active'} `}>
                                                    Is this hotelier unavailable or on vacation? Update its status to <span className='primary-text-color bold'>"not available"</span> to notify every user. The hotelier will be <span className='primary-text-color bold'>hidden in the application</span> during this period.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='row-view items-center p20 bb'>
                                <div className='section-title flex04'>Entity linked*</div>
                                <div className='row-view flex1 items-center'>
                                    <div className='relative'>
                                        <div
                                            className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20'
                                            onClick={() => setEntityOpen(!entityOpen)}
                                        >
                                            <div>{user?.entity?.length ? `${user?.entity[0].name} - ${user?.entity[0].city}` : 'Select hotel'}</div>
                                            <BiSolidChevronDown />
                                        </div>
                                        <div className={`select-menu ${entityOpen && 'open'}`}>
                                            {hotels?.length !== 0 ?
                                                <>
                                                    {hotels.map((hotel, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className='select-item'
                                                                onClick={() => {
                                                                    handleSelect('entity', hotel);
                                                                    setEntityOpen(false);
                                                                }}
                                                            >
                                                                <span className='section-title'>{hotel.name}</span> - {hotel.city}
                                                            </div>
                                                        )
                                                    })}</>
                                                :
                                                <>
                                                    <div
                                                        className='select-item'
                                                    >
                                                        No hotels found
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                                {/* <div className='row-view items-center p20 bb'>
                                    <div className='section-title flex04'>Favourite locations</div>
                                    <div className='row-view flex1 items-center'>
                                        <div className='relative'>
                                            <div
                                                className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20'
                                                onClick={() => setLocationsOpen(!locationOpen)}
                                            >
                                                <div>
                                                    {user?.locations && user.locations.length > 0
                                                        ? user.locations[0].city + ' - ' + user.locations[0].country
                                                        : 'Select destinations'}
                                                </div>                                            <BiSolidChevronDown />
                                            </div>
                                            <div className={`select-menu ${locationOpen && 'open'}`}>
                                                {locations?.length !== 0 ?
                                                    <>
                                                        {locations.map((location, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className='select-item'
                                                                    onClick={() => {
                                                                        handleSelect('locations', location);
                                                                        setLocationsOpen(false);
                                                                    }}
                                                                >
                                                                    <span className='section-title'>{location.city}</span> - {location.country}
                                                                </div>
                                                            );
                                                        })}</>
                                                    :
                                                    <>
                                                        <div
                                                            className='select-item'
                                                        >
                                                            No locations found
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className='row-view items-center p20 bb'>
                                    <div className='section-title flex04'>Email address*</div>
                                    <div className='row-view flex1 items-center'>
                                        <input disabled className='input-text w300 ph20' name='email' placeholder='Email address...' value={user?.email} onChange={(e) => handleInputText(e)} />
                                    </div>
                                </div>
                                <div className='row-view items-center p20'>
                                    <div className='section-title flex04'>Phone number*</div>
                                    <div className='row-view flex1 items-center'>
                                        <input className='input-text w300 ph20' name='phone' placeholder='Phone number...' value={user?.phone} onChange={(e) => handleInputText(e)} />
                                    </div>
                                </div>

                                {/* <div className='row-view items-center p20'>
                                    <div className='section-title flex04'>Contract*</div>

                                    <div className='column-view flex1'>
                                        <div className={`bgFAFAFA row-view items-center w80p p20 b r8 cursor-pointer`}>
                                            <div className="row-column items-center justify-center w80p">
                                                <div className="row-view justify-center section-subtitle mb10">
                                                    PDF, JPG, PNG are allowed
                                                </div>

                                                <div
                                                    className="border-container b2 r8 p20 cursor-pointer mr10 bgLightOrange column-view justify-center items-center"
                                                    onClick={() => handleDocument('document')}
                                                >
                                                    <AiOutlineCloudUpload size={60} color='#EF7056' />
                                                    <div className='primary-text-color bold'>Browse to choose a file</div>
                                                </div>
                                                {document?.file ?
                                                    (
                                                        <div className='row-view justify-between border-container w100 pv12 r8 mb20 mt20'>
                                                            <div className='row-view'>
                                                                <HiDocument className='ml10' size={50} color='#EF7056' />
                                                                <div className='column-view justify-center '>
                                                                    <div className='bold'>{document.name}</div>
                                                                    <div className='color-grey mt05'>{document.date + ' - ' + document.size} Mo</div>
                                                                </div>
                                                            </div>
                                                            <div className='cursor-pointer'>
                                                                <PiDotsThreeOutlineVerticalFill size={50} color='#EF7056' />
                                                            </div>
                                                        </div>
                                                    ) : ''
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>

                            <div className='row-view justify-center mt20'>
                                <button className='secondary-button mr20' type='reset' onClick={() => navigate(-1)}>Cancel</button>
                                <button className='primary-button' type='submit'>Save datas</button>
                            </div>
                            <input
                                type="file"
                                accept="image/png, image/jpeg"
                                ref={hiddenFileInput}
                                onChange={(e) => handleInputPhoto(e)}
                                style={{ display: 'none' }}
                            />
                            <input
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                ref={hiddenFileInput}
                                onChange={(e) => handleInputDocuments(e)}
                                style={{ display: 'none' }}
                            />

                        </form>

                        {/* Deuxième formulaire */}

                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmitPassword();
                        }}>
                            <div className='subtitle'>Password*</div>
                            <div className='border-container r8 mt20'>
                                <div className='row-view items-center p20 bb'>
                                    <div className='section-title flex04'>Current password*</div>
                                    <div className='row-view flex1 items-center'>
                                        <input className='input-text w300 ph20' name='firstname' placeholder='First name...' value='●●●●●●●●●●' disabled />
                                    </div>
                                </div>
                                <div className='row-view items-center p20 bb'>
                                    <div className='section-title flex04'>New password*</div>
                                    <div className='row-view flex1 items-center'>
                                        <input className='input-text w300 ph20' type='password' name='newpassword' placeholder='New password...' onChange={(e) => handleInputPassword(e)} />
                                    </div>
                                </div>
                                <div className='row-view items-center p20'>
                                    <div className='section-title flex04'>Confirm new password*</div>
                                    <div className='row-view flex1 items-center'>
                                        <input className='input-text w300 ph20' type='password' name='confnewpassword' placeholder='Confirm new password...' onChange={(e) => handleInputPassword(e)} />
                                    </div>
                                </div>

                            </div>
                            <div className='row-view justify-center mt20'>
                                <button className='secondary-button mr20' type='reset' onClick={() => navigate(-1)}>Cancel</button>
                                <button className='primary-button' type='submit'>Save password</button>
                            </div>

                        </form>
                    </div>


                );

            case "Notifications":
                return (
                    <div>
                        <div>
                            <div className='title-secondary primary-text-color ml50 mt20'>Notifications</div>
                            <div className='column-view justify-center ml50'>
                                <div className='row-view items-center mt20 cursor-pointer' onClick={() => handleCheckbox('messages', !user.notifications?.[0]?.messages)}>
                                    <div className={`checkbox r4 mr10 ${user?.notifications && user?.notifications?.[0]?.messages === true && 'selected'}`} >
                                        {user?.notifications?.[0]?.messages === true && <MdCheck color='white' />}
                                    </div>
                                    <div className='column-view'>
                                        <div className='bold mb10'>Messages notifications</div>
                                        <div>Receive a notification when you receive a message.</div>
                                    </div>
                                </div>
                                <div className='row-view items-center mt20 cursor-pointer' onClick={() => handleCheckbox('mentions', !user.notifications?.[0]?.mentions)}>
                                    <div className={`checkbox r4 mr10 ${user?.notifications && user?.notifications?.[0]?.mentions === true && 'selected'}`}  >
                                        {user?.notifications?.[0]?.mentions === true && <MdCheck color='white' />}
                                    </div>
                                    <div className='column-view'>
                                        <div className='bold mb10'>Mentions</div>
                                        <div>Be informed as soon as you are mentioned.</div>
                                    </div>
                                </div>
                                <div className='row-view items-center mt20 cursor-pointer' onClick={() => handleCheckbox('hotelCreation', !user.notifications?.[0]?.hotelCreation)}>
                                    <div className={`checkbox r4 mr10 ${user?.notifications && user?.notifications?.[0]?.hotelCreation === true && 'selected'}`}  >
                                        {user?.notifications?.[0]?.hotelCreation === true && <MdCheck color='white' />}
                                    </div>
                                    <div className='column-view'>
                                        <div className='bold mb10'>Hotels creation</div>
                                        <div>Receive a notification when a hotel is created. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='title-secondary primary-text-color ml50 mt50'>Email</div>
                            <div className='column-view justify-center ml50'>
                                <div className='row-view items-center mt20 cursor-pointer' onClick={() => handleCheckbox('newUser', !user.notifications?.[0]?.newUser)}>
                                    <div className={`checkbox r4 mr10 ${user?.notifications && user?.notifications?.[0]?.newUser === true && 'selected'}`} >
                                        {user?.notifications?.[0]?.newUser === true && <MdCheck color='white' />}
                                    </div>
                                    <div className='column-view'>
                                        <div className='bold mb10'>New user</div>
                                        <div>Receive an email when a user is created.</div>
                                    </div>
                                </div>
                                <div className='row-view items-center mt20 cursor-pointer' onClick={() => handleCheckbox('hotelStatus', !user.notifications?.[0]?.hotelStatus)}>
                                    <div className={`checkbox r4 mr10 ${user?.notifications && user?.notifications?.[0]?.hotelStatus === true && 'selected'}`} >
                                        {user?.notifications?.[0]?.hotelStatus === true && <MdCheck color='white' />}
                                    </div>
                                    <div className='column-view'>
                                        <div className='bold mb10'>Hotels status</div>
                                        <div>Receive an email when a hotel changes status.</div>
                                    </div>
                                </div>
                                <div className='row-view items-center mt20 cursor-pointer' onClick={() => handleCheckbox('ticketCreation', !user.notifications?.[0]?.ticketCreation)}>
                                    <div className={`checkbox r4 mr10 ${user?.notifications && user?.notifications?.[0]?.ticketCreation === true && 'selected'}`} >
                                        {user?.notifications?.[0]?.ticketCreation === true && <MdCheck color='white' />}
                                    </div>
                                    <div className='column-view'>
                                        <div className='bold mb10'>Ticket creation</div>
                                        <div>Receive an email when a ticket is created.</div>
                                    </div>
                                </div>
                                <div className='row-view items-center mt20 cursor-pointer' onClick={() => handleCheckbox('hotelierMessages', !user.notifications?.[0]?.hotelierMessages)}>
                                    <div className={`checkbox r4 mr10 ${user?.notifications && user?.notifications?.[0]?.hotelierMessages === true && 'selected'}`} >
                                        {user?.notifications?.[0]?.hotelierMessages === true && <MdCheck color='white' />}
                                    </div>
                                    <div className='column-view'>
                                        <div className='bold mb10'>Hoteliers messages</div>
                                        <div>Receive an email when you receive a message by hoteliers.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="page-container">
            <div className="row-view justify-between">
                <div className='row-view items-center'>
                    <MdChevronLeft
                        size={50}
                        onClick={() => navigate(-1)}
                    />
                    <div className='title-secondary'>Settings</div>
                </div>
            </div>

            <div className="row-view pb20 mt20 ml20">
                <div
                    className={`row-view items-center mr50 cursor-pointer pv4 ${selectedPage === 'Personal' && 'black-text bold bb161616'}`}
                    onClick={() => handlePage('Personal')}
                >
                    <div className={`mr5`}>
                        Personal
                    </div>
                    {personal.length !== 0 && <div className="notif">{personal.length}</div>}
                </div>
                <div className={`row-view items-center mr50 cursor-pointer pv4 ${selectedPage === 'Notifications' && 'black-text bold bb161616'}`}
                    onClick={() => handlePage('Notifications')}
                >
                    <div className={`mr5`}>
                        Notifications
                    </div>
                    {notifications.length !== 0 && <div className="notif">{notifications.length}</div>}
                </div>

            </div>


            <div className='of-scroll'>
                {renderPage()}
            </div>

        </div>);
};