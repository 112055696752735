import React, { useEffect, useState, useRef } from 'react'

import { Link } from 'react-router-dom'
import moment from 'moment'

import useHotelApi from '../../api/hotel'

import { getHotelOfferList } from '../../api/offer';
import { Table } from '../../components/Table/Table'
import { MdSearch } from 'react-icons/md'
import { AiOutlinePlusCircle } from 'react-icons/ai'

const cells = ['Name', 'Rooms & Dates', 'Discount', 'Target Audience', 'Dates', 'Informations']

export const Offers = ({ handleLogout }) => {
    const { getHotelListLazy } = useHotelApi();
    const [dtOffer, setDtOffer] = useState([])

    // const [setSelectedType] = useState('Active-Offers')

    const [hotelsOpen, setHotelssOpen] = useState(false)
    const queryParams = new URLSearchParams(window.location.search)

    const [hotel, setHotel] = useState({})
    const [hotels, setHotels] = useState([])
    const hotelsRef = useRef(null)
    const [isSearching, setIsSearching] = useState(false);
    const [searchText, setSearchText] = useState(queryParams.get("search"));

    // const handleDeactivate = (idx, offer_sub) => {

    //     deleteHotelOffer(offer_sub, response => {

    //         if (response === true) {
    //             console.log(response?.content)
    //             const updatedItems = [...dtOffer];  // Create a copy of the array
    //             updatedItems.splice(idx, 1);   // Remove the item
    //             setDtOffer(updatedItems);
    //         }
    //     })

    //     // const updatedItems = [...dtOffer] // Create a copy of the array
    //     // updatedItems.splice(idx, 1) // Remove the item
    //     // setDtOffer(updatedItems)
    // }

    // const handleEditOffer = (idx, offer_sub) => {
    //     navigate('/offers/edit/' + offer_sub)
    // }

    useEffect(() => {
        let token = localStorage.getItem('auth')

        if (token) {
            const expiration = JSON.parse(localStorage.getItem('expiration'))
            if (expiration < Date.now() / 1000) {
                handleLogout()
            }
        }
    }, [handleLogout])

    useEffect(() => {
        getHotelListLazy(0, 20, '', undefined, (response) => {
            if (response?.status === 'success') {
                setHotels(response?.content?.hotels)
                setHotel(response?.content?.hotels[0])
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formatData = (data) => {
        let res = [];

        data.map((p) => {
            const rates = p?.details.map(item => item);
            return (
                res.push({
                    'offer_sub': p?.offer_sub,
                    'offer_name': p?.offer_name,
                    'rooms_type': p?.rooms,
                    'rates': rates?.join(', '),
                    'discount': p?.discount + ' %',
                    'target_audience': 'Mobile device users',
                    'audience_Type': p?.target_audience,
                    'from_date': moment(p?.start_date).format('DD/MM/YYYY'),
                    'to_date': moment(p?.end_date).format('DD/MM/YYYY'),
                    'from_time': moment(p?.start_date).format('HH:mm'),
                    'to_time': moment(p?.end_date).format('HH:mm'),
                    'information':
                    {
                        'created_on': moment(p?.created_at).format('DD/MM/YYYY'),
                        'number_of_bookings': '',
                        'number_of_cancellations': '',
                        'total_revenue': ''
                    }
                })
            );
        })

        return res;
    }

    const ListAllOffers = (hotel_sub) => {
        getHotelOfferList(hotel_sub, response => {
            if (response?.status === 'success') {
                console.log(response?.content);
                setDtOffer(formatData(response?.content));

            }
        })
    }

    useEffect(() => {
        ListAllOffers(hotel?.hotel_sub)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hotel])

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                hotelsOpen &&
                hotelsRef.current &&
                !hotelsRef.current.contains(event.target)
            ) {

                setHotelssOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [hotelsOpen, hotelsRef])

    return (
        // <div className="container">
        <div className="page-container">
            <div className="row-view justify-between">
                <div>
                    <div className="size2 bold">Offers</div>
                    <div className="size1 bold today">
                        <span>{dtOffer.length} offers</span>
                    </div>
                </div>
                <div className="row-view">
                    <div className="row-view mr10">
                        {/* <button className="secondary-button borderless row-view items-center">
                            <div className="mr5">
                                <TbFilterPlus />
                            </div>
                            <div>Filter</div>
                        </button> */}
                        <div className="secondary-search-bar-container row-view items-center justify-center">
                            <div className="mr10">
                                <MdSearch size={20} />
                            </div>
                            <input
                                type="text"
                                placeholder="Search"
                                className="secondary-search-bar"
                                value={searchText}
                                onChange={(e) => {
                                    setIsSearching(true)
                                    setSearchText(e.target.value)
                                    setTimeout(() => {
                                        setIsSearching(false)
                                    }, 300)
                                }}
                            />
                        </div>
                    </div>
                    <div className="row-view justify">
                        {/* <button
                            className="secondary-button w160 borderless row-view items-center justify-center"
                            onClick={() => handleEdit()}
                        >
                            <div className="mr5">
                                <BsPersonSquare />
                            </div>
                            <div>{edit ? 'Save' : 'Edit roles'}</div>
                        </button> */}
                        <Link
                            className="primary-button none-decoration-link row-view items-center"
                            to={{ pathname: '/offers/add' }}
                        >
                            <AiOutlinePlusCircle
                                color="white"
                                size={20}
                                className="mr10"
                            />
                            <div>Add Offer</div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="dropdown hotel-dpdwn clear-fix row-view flex1 items-center mt50">
                {/* <div className='relative'>
                        <div
                            className='border-container bgFAFAFA row-view items-center justify-between r4 w300 pv12 ph20 cursor-pointer'
                            onClick={() => setHotelssOpen(!hotelsOpen)}
                        >
                            <div>
                                {hotel?.name || hotel?.content?.myagency?.Name
                                    ? hotel?.name || hotel?.content?.myagency?.Name
                                    : 'Select hotel'}
                            </div>
                            <BiSolidChevronDown />
                        </div>
                        <div className={`select-menu ${hotelsOpen && 'open'}`} style={{ maxHeight: 300, overflow: "scroll" }} ref={hotelsRef}>
                            {hotels?.length !== 0 ? (
                                <>
                                    {hotels?.map((h, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className='select-item'
                                                onClick={() => {
                                                    setHotel(h)
                                                    setHotelssOpen(false)
                                                }}
                                            >
                                                <span className='section-title'>
                                                    {h?.name || h?.content?.myagency?.Name} - {h?.city || h?.content?.myagency?.CityName}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </>
                            ) : (
                                <>
                                    <div className='select-item'>
                                        No hotels found
                                    </div>
                                </>
                            )}
                        </div>
                    </div> */}
            </div>

            <Table cells={cells} data={dtOffer.length !== 0 ? dtOffer : 'No offers yet...'} type='offers' />

        </div >
    )
}
export default Offers
