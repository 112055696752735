import React from 'react';

export const CustomAlertConfirm = ({ title, message, onConfirm, onCancel }) => {
    return (
        <div>
            <div className='bgWhite p20 r8'>
                <div className='subtitle'>{title}</div>
                <div className='mt10'>{message}</div>
                <div className='mt20'>
                    <button className='primary-button mr10' onClick={onConfirm}>Yes</button>
                    <button className='secondary-button' onClick={onCancel}>No</button>
                </div>
            </div>
        </div>
    );
}