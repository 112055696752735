import React, { useState } from 'react';
import { HotelRestaurants } from './HotelRestaurants';
import { AddRestaurants } from './AddRestaurants';
import { HotelRestaurant } from './HotelRestaurant';

export const RestaurantsTab = ({ hotel_sub }) => {
    const [activeTab, setActiveTab] = useState(0)
    const [activeRestaurant, setActiveRestaurant] = useState('')

    return (
        <div>
            {activeTab === 0 && <HotelRestaurants hotelSub={hotel_sub} setActiveTab={setActiveTab} setActiveRestaurant={setActiveRestaurant} />}

            {activeTab === 1 && <AddRestaurants hotelSub={hotel_sub} setActiveTab={setActiveTab} />}

            {activeTab === 2 && <HotelRestaurant restaurantSub={activeRestaurant} setActiveTab={setActiveTab} setActiveRestaurant={setActiveRestaurant} />}
        </div>
    )
}