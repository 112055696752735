import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import moment from 'moment';
import { Table } from '../../../components/Table/Table';
import { getHotelRestaurantList } from '../../../api/hotel_restaurant';
import { Loader } from '../../../components/Generic/Loader';

const cells = [
    'Name',
    'Availability',
    'Food Type',
    'Opening',
    'Informations'
];

export const HotelRestaurants = ({ hotelSub, setActiveTab, setActiveRestaurant }) => {
    const [isLoading, setIsLoading] = useState(true);

    const [restaurants, setRestaurants] = useState([]);
    const [restaurantsCount, setRestaurantsCount] = useState(0);

    const formatData = (data) => {
        let res = [];
        data.map((r) => {
            return (
                res.push({
                    id: r?.sub,
                    img: r?.images?.[0] ?? null,
                    name: `${r?.restaurant ?? ''} - ${r?.name ?? ''}`,
                    availability: r?.active ?? false,
                    foodType: `${r?.foodType?.[0]?.icon ?? ''} ${r?.foodType?.[0]?.label ?? ''}`,
                    // opening: r?.opening?.events?.[0] ? `${r?.opening?.events?.[0]?.icon} ${r?.opening?.events?.[0]?.label},${r?.opening?.events?.length}|${r?.opening?.days?.[0]},${r?.opening?.days?.length}` : '',
                    opening: r?.opening ? JSON.stringify(r?.opening) : '',
                    informations: `${moment(r?.created_at).format('ll') ?? ''}|${r?.numberOfBookings ?? 0}|${r?.numberOfCancellations ?? 0}|${r?.totalRevenues ?? 0}`
                })
            );
        });
        return res;
    };

    useEffect(() => {
        getHotelRestaurantList(hotelSub, response => {
            if (response?.status === 'success') {
                setRestaurants(formatData(response?.content));
                setRestaurantsCount(response?.content?.length);
            }
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isLoading ?
                <Loader />
                :
                <>
                    <div className='row-view justify-end'>
                        <Link
                            className='primary-button none-decoration-link row-view items-center'
                            onClick={() => setActiveTab(1)}
                        >
                            <AiOutlinePlusCircle color='white' size={20} className='mr10' />
                            <div>Add Restaurant</div>
                        </Link>
                    </div>
                    <div className='mt20'>
                        <Table cells={cells} data={restaurants.length !== 0 ? restaurants : 'No restaurants yet...'} type='restaurants' setActiveTab={setActiveTab} setActiveItem={setActiveRestaurant} />
                    </div>
                    {!restaurants && <div className='p20'>No restaurants registered..</div>}
                </>
            }
        </>
    );
};