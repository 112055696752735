import React from 'react';
import LogoBlackText from '../../assets/logos/logo-text-black.svg';

const WelcomeTab = ({ setActiveTab }) => {
    return (
        <>
            <div className='blur-circle1' />
            <div className='blur-circle2' />
            <div className='page-wrapper-tab'>
                <div>
                    <img src={LogoBlackText} alt='LogoBlackText' className='welcome-logo' />
                </div>
                <div className='welcome-body'>
                    <div>
                        <h1>WELCOME TO</h1>
                        <h1 className='mhm'>MYHOTELMATCH</h1>
                    </div>
                    <div className='bo-welcome'>
                        {/* <img src='https://storage.googleapis.com/mhm-medias/bo-hotels/bo-welcome.png' alt='bo-welcome' /> */}
                        <img src='https://storage.googleapis.com/mhm-medias/bo-hotels/circle-bg.png' alt='bo-welcome-bg' />
                        <img src='https://storage.googleapis.com/mhm-medias/bo-hotels/Mac.png' alt='bo-welcome-mac' />
                    </div>
                    <div>
                        <div className='text-center'>
                            <img src='https://storage.googleapis.com/mhm-medias/bo-hotels/yves.png' alt='yves' />
                            <h2 className='bold'>Yves Abitbol</h2>
                            <h3>CEO</h3>
                        </div>
                        <h2>MyHotelMatch <span className='primary-text-color'>gonna change your life!</span> Join the motion to reconnect with your guest.</h2>
                    </div>
                </div>
                <div>
                    <div
                        className='primary-button r100'
                        onClick={() => setActiveTab(prev => prev + 1)}
                    >
                        Get access to my Back-Office!
                    </div>
                </div>
            </div>
        </>
    );
};

export default WelcomeTab;