import React, { useState, useEffect, useRef } from 'react'
import { TbFilterPlus } from 'react-icons/tb'
import { MdSearch } from 'react-icons/md'
import { ProgressLine } from '../../components/ProgressLine/ProgressLine'
import { ReviewCard } from '../../components/ReviewCard/ReviewCard'
import { reviewsData } from '../../utils/mock/ReviewsData'

import TableFooter from '../../components/Generic/TableFooter'
export const Reviews = ({ handleLogout }) => {
    const [currentPageData, setCurrentPageData] = useState(1)
    const [currentPageCountData, setCurrentPageCountData] = useState(5)

    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const [reviewScore, setReviewScore] = useState(0);
    const [staffReviewScore, setStaffReviewScore] = useState(0);
    const [equipmentsReviewScore, setEquipmentsReviewScore] = useState(0);

    const [hotelsOpen, setHotelssOpen] = useState(false)
    const hotelsRef = useRef(null)

    const handlePaginationIncrement = () => {
        setCurrentPageData(currentPageData + 1)
    };

    const handlePaginationDecrement = () => {

        setCurrentPageData(
            currentPageData === 1
                ? currentPageData
                : currentPageData - 1
        )
    };

    const handlePerPageDropdownChange = (e) => {
        setCurrentPageCountData(e.target.value);

    };

    useEffect(() => {
        let token = localStorage.getItem('auth')
        if (token) {
            const expiration = JSON.parse(localStorage.getItem('expiration'))
            if (expiration < Date.now() / 1000) {
                handleLogout()
            }
        }
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        }

        let sum = 0;
        let sumStaff = 0;
        let sumEquipments = 0;
        if (reviewsData.length !== 0) {
            // eslint-disable-next-line array-callback-return
            reviewsData.map(r => {
                sum += (r.score.staff + r.score.equipments) / 2;
                sumStaff += r?.score.staff;
                sumEquipments += r?.score.equipments;
            })
            setReviewScore((sum / reviewsData.length).toFixed(1))
            setStaffReviewScore((sumStaff / reviewsData.length).toFixed(1))
            setEquipmentsReviewScore((sumEquipments / reviewsData.length).toFixed(1))
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleLogout])

    useEffect(() => {
        function handleClickOutside(event) {
            if (hotelsOpen && hotelsRef.current && !hotelsRef.current.contains(event.target)) {
                setHotelssOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [
        hotelsOpen,
        hotelsRef
    ]);

    return (
        <div className="page-container">
            <div className="row-view justify-between">
                <div>
                    <div className="size2 bold">Reviews</div>
                    <div className="size1 bold today">
                        <span>{reviewsData.length} reviews</span>
                    </div>

                </div>
                <div className="row-view">
                    <button className="secondary-button borderless row-view items-center">
                        <div className="mr5">
                            <TbFilterPlus />
                        </div>
                        <div>Filter</div>
                    </button>
                    <div className="secondary-search-bar-container row-view items-center justify-center">
                        <div className="mr10">
                            <MdSearch size={20} />
                        </div>
                        <input
                            type="text"
                            placeholder="Search reviews"
                            className="secondary-search-bar"
                        />
                    </div>
                </div>
            </div>
            <div className='dropdown hotel-dpdwn clear-fix row-view flex1 items-center mt50' style={{ marginTop: '-6PX' }}>
                {/* <div className='relative'>
                    <div
                        className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer'
                        onClick={() => setHotelssOpen(!hotelsOpen)}
                    >
                        <div>
                            {hotel?.length > 0 ? hotel : 'Select hotel'}
                        </div>
                        <BiSolidChevronDown />
                    </div>
                    <div className={`select-menu ${hotelsOpen && 'open'}`} style={{ maxHeight: 300, overflow: "scroll" }} ref={hotelsRef}>
                        {Hotel_List?.length !== 0 ? (
                            <>
                                {Hotel_List?.map((h, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='select-item'
                                            onClick={() => {
                                                setHotel(h)
                                                setHotelssOpen(false)
                                            }}
                                        >
                                            <span className='section-title'>
                                                {h}
                                            </span>
                                        </div>
                                    )
                                })}
                            </>
                        ) : (
                            <>
                                <div className='select-item'>
                                    No hotels found
                                </div>
                            </>
                        )}
                    </div>
                </div> */}
            </div>
            <div className="body-head-cont">
                <div className="title-offer">
                    <h6 className="body-head" style={{ marginTop: '30px' }}>All review <span style={{ backgroundColor: '#DD172C', color: 'white', borderRadius: '10px' }}>0</span></h6>
                </div>
            </div>
            {/* <button className="secondary-button row-view items-center p10 mt20">
                <div
                    style={{
                        backgroundColor: 'gray',
                        width: 25,
                        height: 25,
                        borderRadius: 50,
                        marginRight: 5,
                    }}
                />
                <div className="mr5">The Mozart Hotel</div>
                <MdArrowDropDown size={20} />
            </button> */}

            <div className={`mt50`}>
                <div className='row-view justify-between border-container bgFAFAFA r8 p20 mb20'>
                    <div className='flex03'>
                        <div className='column-view'>
                            <div className={`row-view`}>
                                <div className='p20 bgPrimary r8 white-text bolder mr20 h100'>{reviewScore}</div>
                                <div className='column-view justify-evenly'>
                                    <div className='bold'>Your review score</div>
                                    <div>based on 255 verified reviews</div>
                                </div>
                            </div>
                            <div className='mt20'>
                                <ProgressLine
                                    rate={staffReviewScore}
                                    text="Staff of the hotel"
                                />
                                <ProgressLine
                                    rate={equipmentsReviewScore}
                                    text="Equipments and services"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex07' />
                </div>

                <div className={`column-view flex1`}>
                    {reviewsData.map((review, index) => {
                        return (
                            <ReviewCard
                                key={index}
                                review={review}
                            />
                        )
                    })}
                </div>
            </div>

            {/* <div className="row-view items-center justify-between mt50 ">
                <div className="row-view items-center">
                    <button className="secondary-button row-view p10 mr10">
                        <div className="mr5">25</div>
                        <PiCaretUpDownLight />
                    </button>
                    <div className="section-title">tickets per page</div>
                </div>
                <div className="pagination">
                    <div
                        className="pagination--item"
                        onClick={() =>
                            setCurrentPageData(
                                currentPageData === 0
                                    ? currentPageData
                                    : currentPageData - 1
                            )
                        }
                    >
                        <img src={arrowLeft} alt="dashboard" className="icon" />
                    </div>
                    <div
                        className="pagination--item"
                        onClick={() => setCurrentPageData(currentPageData + 1)}
                    >
                        <img
                            src={arrowRight}
                            alt="dashboard"
                            fill="red"
                            className="icon pagination--color"
                        />
                    </div>
                </div>
            </div> */}

            <TableFooter handlePaginationDecrement={handlePaginationDecrement} currentPageData={currentPageData}
                handlePaginationIncrement={handlePaginationIncrement} selectedOption={currentPageCountData} handlePerPageDropdownChange={handlePerPageDropdownChange} footerName={'Reviews'} />
        </div>
    )
}
