import React, { useState } from 'react';
import { WelcomeIltm } from './WelcomeIltm';
import { HotelChoice } from './HotelChoice';
import { RegisteredHotel } from './RegisteredHotel';
import { UnknownHotel } from './UnknownHotel';

export const ILTM = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [userContent, setUserContent] = useState({});
    const [hotelContent, setHotelContent] = useState({});

    return (
        <div>
            {activeTab === 0 && <WelcomeIltm setActiveTab={setActiveTab} />}
            {activeTab === 1 && <HotelChoice setActiveTab={setActiveTab} setUserContent={setUserContent} setHotelContent={setHotelContent} />}
            {activeTab === 2 && <RegisteredHotel userContent={userContent} setUserContent={setUserContent} hotelContent={hotelContent} />}
            {activeTab === 3 && <UnknownHotel />}
        </div>
    )
}