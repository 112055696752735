/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom'
import { MdChevronLeft, MdAccountCircle } from 'react-icons/md'
import img from '../../assets/images/profile-photo.png'
import prague from '../../assets/images/prague.png'
import cannes from '../../assets/images/cannes.png'
import newYork from '../../assets/images/new-york.png'
import { Link } from 'react-router-dom'
import { Table } from '../../components/Table/Table'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { BiMessageDots, BiSolidFlagAlt } from 'react-icons/bi'
import { getUser } from '../../api/user'
import { getAllReservations } from '../../api/reservations'
import colors from '../../colors/colors';

const communities = [
    { name: 'LGBTQ+', icon: '🏳️‍🌈' },
    { name: 'Biker', icon: '🛵' },
    { name: 'Ecologist', icon: '🍀' },
    { name: 'Pet lover', icon: '🐶' },
    { name: 'Recent Feminism', icon: '🌸' },
]

const foodHealth = [
    { name: 'Diabetic', icon: '💉' },
    { name: 'Vegan', icon: '🌱' },
    { name: 'ICD', icon: '🧰' },
    { name: 'Gluten free', icon: '🌿' },
    { name: 'Bug prevention', icon: '🪷' },
    { name: 'Pork free', icon: '🐷' },
    { name: 'Recent mattress', icon: '🛏️' },
    { name: 'High-end mattress', icon: '🛌' },
    { name: 'Seafood allergies', icon: '🦐' },
]

const cells = [
    'Check-In',
    'Check-Out',
    'Length',
    'Room',
    'Travelers',
    'Price',
    'Invoice',
]

export const TravelerProfile = ({ handleLogout }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [sub, setSub] = useState(location.pathname.replace('/travelers/profile/', ''))
    const [reservations, setReservations] = useState([]);
    const [traveler, setTraveler] = useState({});

    const formatData = (data) => {
        let res = [];
        data.map((r) => {
            return (
                res.push({
                    'checkin': moment(r?.checkin).format('ll'),
                    'checkout': moment(r?.checkout).format('ll'),
                    'length': moment(r?.checkout).diff(r?.checkin, 'days'),
                    'roomType': r?.room,
                    'guest': parseInt(r?.adults) + parseInt(r?.adults),
                    'price': r?.amount + (r.currency === 'EUR' ? '€' : r?.currency),
                    'invoice': 'The Mozart Hotel - Bill.pdf'
                })
            );
        })
        return res;
    }

    useEffect(() => {
        let token = (localStorage.getItem('auth'));
        if (token) {
            const expiration = JSON.parse(localStorage.getItem('expiration'));
            if (expiration < Date.now() / 1000) {
                handleLogout();
            }
        }
        getUser(sub, response => {
            setTraveler(response.content)
        })
        getAllReservations(response => {
            setReservations(formatData(response.content.filter(el => el.profile_sub === sub)))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleLogout]);

    return (
        <div className="page-container">
            <div className="row-view items-center">
                <MdChevronLeft size={50} onClick={() => navigate(-1)} />
                <div className="title-secondary">Traveler information</div>
            </div>

            <div className='row-view justify-between mt20 p10'>
                <div className='row-view'>
                    <div className='mr20'>
                        {traveler?.photo ?
                            <img src={traveler?.photo} alt='img' style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 100 }} />
                            :
                            <MdAccountCircle color='#999999' size={80} />
                        }
                    </div>
                    <div>
                        <div className='title-secondary'>{traveler?.firstname} {traveler?.lastname}</div>
                        <div className=''>@{traveler?.login}</div>
                    </div>
                </div>
                <div>
                    <Link
                        className="primary-button none-decoration-link row-view items-center"
                        to={{ pathname: '/travelers/profile' }}
                    >
                        <BiMessageDots
                            color="white"
                            size={20}
                            className="mr10"
                        />
                        <div>Message</div>
                    </Link>
                    <Link
                        className="secondary-button b-green none-decoration-link row-view items-center mt20"
                        to={{ pathname: '/travelers/profile' }}
                    >
                        <BiSolidFlagAlt
                            size={20}
                            className="mr10"
                        />
                        <div>Report</div>
                    </Link>
                </div>
            </div>

            <div className='row-view'>
                <div className='flex07 bgFAFAFA p20 mr50 r8'>
                    <div className='subtitle'>His profile information</div>
                    <div className='grid-view2 gap20 mt20'>
                        <div>
                            <div className='bold'>Address email</div>
                            <div className='mt10'>{traveler?.email}</div>
                        </div>
                        <div>
                            <div className='bold'>Date of birth</div>
                            <div className='mt10'>{traveler?.dateOfBirth}</div>
                        </div>
                        <div>
                            <div className='bold'>Phone number</div>
                            <div className='mt10'>{traveler?.phone}</div>
                        </div>
                        <div>
                            <div className='bold'>Address</div>
                            <div className='mt10'>{traveler?.address}</div>
                        </div>
                    </div>
                </div>
                <div className='flex03 bgFAFAFA p20 r8'>
                    <div className='subtitle black-text'>His favorite destinations</div>
                    <div className="row-view justify-between mt20">
                        {traveler?.destinations && traveler?.destinations.map((destination, index) => {
                            return (
                                <div className="column-view text-center bold">
                                    <img
                                        src={index === 0 ? prague : index === 1 ? cannes : newYork}
                                        alt={prague}
                                    />
                                    <div>{destination.city}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className='flex1 bgFAFAFA p20 r8 mt50'>
                <div className='subtitle black-text'>His spoken languages</div>
                <div className="row-view mt20">
                    {traveler?.tagLanguages?.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={`row-view tag mr20 mt10 mb10 selected`}
                            >
                                <div>{item.icon} {item.label}</div>
                            </div>
                        )
                    })}
                </div>
                {/* <div className='row-view items-center'>
                    <button className='secondary-button borderless p10'>+ Add suggestions</button>
                    <AiFillQuestionCircle color={colors.primary} size={30} />
                </div> */}
            </div>

            <div className='flex1 bgFAFAFA p20 r8 mt50'>
                <div className='subtitle black-text'>His travel habits</div>
                <div className="row-view mt20">
                    {traveler?.tagTravelHabits?.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={`row-view tag mr20 mt10 mb10 selected`}
                            >
                                <div>{item.icon} {item.label}</div>
                            </div>
                        )
                    })}
                </div>
                {/* <div className='row-view items-center'>
                    <button className='secondary-button borderless p10'>+ Add suggestions</button>
                    <AiFillQuestionCircle color={colors.primary} size={30} />
                </div> */}
            </div>

            <div className='flex1 bgFAFAFA p20 r8 mt50'>
                <div className='subtitle black-text'>His food and health imperatives</div>
                <div className="row-view mt20">
                    {traveler?.tagFoodRestrictions?.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={`row-view tag mr20 mt10 mb10 selected`}
                            >
                                <div>{item.icon} {item.label}</div>
                            </div>
                        )
                    })}
                </div>
                {/* <div className='row-view items-center'>
                    <button className='secondary-button borderless p10'>+ Add suggestions</button>
                    <AiFillQuestionCircle color={colors.primary} size={30} />
                </div> */}
            </div>

            <div className='flex1 bgFAFAFA p20 r8 mt50'>
                <div className='subtitle black-text'>His allergies</div>
                <div className="row-view mt20">
                    {traveler?.tagAllergies?.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={`row-view tag mr20 mt10 mb10 selected`}
                            >
                                <div>{item.icon} {item.label}</div>
                            </div>
                        )
                    })}
                </div>
                {/* <div className='row-view items-center'>
                    <button className='secondary-button borderless p10'>+ Add suggestions</button>
                    <AiFillQuestionCircle color={colors.primary} size={30} />
                </div> */}
            </div>

            {/* <div className='bgFAFAFA p20 r8 mt50'>
                <div className='subtitle black-text'>His communities</div>
                <div className='row-view'>
                    <div className='flex1 p20'>
                        <div className='bold'>Environment</div>
                        <div className="row-view mt20">
                            {traveler?.communities && communities.map((item, index) => {
                                if (traveler.communities.includes(item.name)) {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view items-center tag mr20 mt10 mb10 selected`}
                                        >
                                            <div className="mr10">{item.icon}</div>
                                            <div>{item.name}</div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div className='row-view items-center'>
                            <button className='secondary-button borderless p10'>+ Add suggestions</button>
                            <AiFillQuestionCircle color={colors.primary} size={30} />
                        </div>
                    </div>
                    <div className='v-separator bg161616' />
                    <div className='flex1 p20'>
                        <div className='bold'>Activities</div>
                        <div className="row-view mt20">
                            {traveler?.communities && communities.map((item, index) => {
                                if (traveler.communities.includes(item.name)) {
                                    return (
                                        <div
                                            key={index}
                                            className={`row-view items-center tag mr20 mt10 mb10 selected`}
                                        >
                                            <div className="mr10">{item.icon}</div>
                                            <div>{item.name}</div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div className='row-view items-center'>
                            <button className='secondary-button borderless p10'>+ Add suggestions</button>
                            <AiFillQuestionCircle color={colors.primary} size={30} />
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className='bgFAFAFA p20 r8 mt50'>
                <div className='subtitle black-text'>His last reviews</div>
                <div className="row-view mt20">
                    <div className='mr10'>
                        <img src={mozart} alt='mozart'></img>
                    </div>
                    <div className='column-view'>
                        <div className='bold black-text'>A great experience</div>
                        <div className='mt10'>18/02/2023</div>
                        <div className=''>The Mozart Hotel, Prague</div>
                    </div>
                </div>
                <div className='mt20'>
                    An ideal location 2 steps from the Charles Bridge, large family room with comfortable bedding, excellent breakfast. The staff is attentive and provides very...
                </div>
                <div className='h-separator bg161616 mt50' />
                <div className="row-view mt50">
                    <div className='mr10'>
                        <img src={hollywood} alt='hollywood'></img>
                    </div>
                    <div className='column-view'>
                        <div className='bold black-text'>A beautiful discovery</div>
                        <div className='mt10'>24/01/2023</div>
                        <div className=''>Hollywood Hotel, Prague</div>
                    </div>
                </div>
                <div className='mt20'>
                    We treated ourselves to an extended weekend in Cannes and chose The Hollywood Hotel to stay at Cannes. A pleasant atmosphere and quality bedding...
                </div>
            </div> */}

            {/* <div className='bgFAFAFA p20 r8 mt50'>
                <div className='subtitle black-text'>His last reservations</div>
                <div className='of-scroll p20'>
                    <Table cells={cells} data={reservations.length !== 0 ? reservations : 'No reservations yet...'} />
                </div>
                <div className="bgFAFAFA r8 p20">
                    <div className="section-title mb10">Add a comment : </div>
                    <div className="row-view">
                        <textarea
                            rows={8}
                            className="input-text flex1 bgWhite"
                            placeholder="Write a comment"
                        ></textarea>
                    </div>
                </div>
            </div> */}
        </div>
    )
}
