import React, { useState } from 'react';
import { HotelServices } from './HotelServices';
import { AddServices } from './AddServices';
import { ServiceTab } from './ServiceTab';

export const ServicesTab = ({ hotel_sub }) => {
    const [activeTab, setActiveTab] = useState(0)
    const [activeService, setActiveService] = useState('')

    return (
        <div>
            {activeTab === 0 && <HotelServices hotelSub={hotel_sub} activeService={activeService} setActiveTab={setActiveTab} setActiveService={setActiveService} />}

            {activeTab === 1 && <AddServices hotelSub={hotel_sub} setActiveTab={setActiveTab} />}

            {activeTab === 2 && <ServiceTab serviceSub={activeService} setActiveTab={setActiveTab} setActiveService={setActiveService} />}
        </div>
    )
}