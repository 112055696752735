import React from 'react'
import ArroLeftSvg from '../../assets/icons/arrow-left-sm.svg'
import ArroRightSvg from '../../assets/icons/arrow-right-sm.svg'

const TableFooter = ({ handlePaginationDecrement, currentPageData, handlePaginationIncrement,
    footerName, selectedOption, handlePerPageDropdownChange }) => {
    return (
        <div className="table-footer">
            <ul className="pages-list">
                <li><img src={ArroLeftSvg} alt="" onClick={handlePaginationDecrement} /></li>
                <li className="active">{currentPageData}</li>
                {/* <li>2</li><li>3</li><li>...</li><li>5</li> */}
                <li><img src={ArroRightSvg} alt="" onClick={handlePaginationIncrement} /></li>
            </ul>

            <div className="float-right">
                <label><select className="numb-dp" value={selectedOption} onChange={handlePerPageDropdownChange}>
                    <option>5</option>
                    <option>10</option>
                    <option>15</option>
                    <option>20</option>
                    <option>25</option>
                </select>{footerName} per page</label>
            </div>
        </div>
    )
}

export default TableFooter
