import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import addImg from '../../../assets/icons/AddImgOrange.svg';
import { BiSolidChevronDown } from 'react-icons/bi';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { MdClose, MdCheck, MdChevronLeft } from 'react-icons/md';
import { hotel_profile_tags } from '../../../utils/mock/HotelData';
import colors from '../../../colors/colors';
import { useNavigate } from 'react-router-dom';
import { createHotelRoom, deleteHotelRoom, getHotelRoom, updateHotelRoom } from '../../../api/hotel_room';
import { CustomAlertConfirm } from '../../../components/Generic/CustomAlertConfirm';

export const RoomTab = ({ roomSub, setActiveTab, setActiveRoom }) => {
    const [showAlert, setShowAlert] = useState(false);

    const [addAccessibilityTag, setAddAccessibilityTag] = useState('');
    const [addAccessibilityTagFocused, setAddAccessibilityTagFocused] =
        useState(false);
    const addAccessibilityTagRef = useRef(null);

    const [addLocationTag, setAddLocationTag] = useState('');
    const [addLocationTagFocused, setAddLocationTagFocused] = useState(false);
    const addLocationTagRef = useRef(null);

    const [addViewTag, setAddViewTag] = useState('');
    const [addViewTagFocused, setAddViewTagFocused] = useState(false);
    const addViewTagRef = useRef(null);

    const [addBedTypeTag, setAddBedTypeTag] = useState('');
    const [addBedTypeTagFocused, setAddBedTypeTagFocused] = useState(false);
    const addBedTypeTagRef = useRef(null);

    const [addRoomTypeTag, setAddRoomTypeTag] = useState('');
    const [addRoomTypeTagFocused, setAddRoomTypeTagFocused] = useState(false);
    const addRoomTypeTagRef = useRef(null);

    const [addMattressTypeTag, setAddMattressTypeTag] = useState('');
    const [addMattressTypeTagFocused, setAddMattressTypeTagFocused] =
        useState(false);
    const addMattressTypeTagRef = useRef(null);

    const [addPillowTypeTag, setAddPillowTypeTag] = useState('');
    const [addPillowTypeTagFocused, setAddPillowTypeTagFocused] =
        useState(false);
    const addPillowTypeTagRef = useRef(null);

    const [addBathroomTag, setAddBathroomTag] = useState('');
    const [addBathroomTagFocused, setAddBathroomTagFocused] = useState(false);
    const addBathroomTagRef = useRef(null);

    const [addRestroomTag, setAddRestroomTag] = useState('');
    const [addRestroomTagFocused, setAddRestroomTagFocused] = useState(false);
    const addRestroomTagRef = useRef(null);

    const [addFacilitiesTag, setAddFacilitiesTag] = useState('');
    const [addFacilitiesTagFocused, setAddFacilitiesTagFocused] = useState(false);
    const addFacilitiesTagRef = useRef(null);

    const [addExtraTag, setAddExtraTag] = useState('');
    const [addExtraTagFocused, setAddExtraTagFocused] = useState(false);
    const addExtraTagRef = useRef(null);

    const [addTVPreferencesTag, setAddTVPreferencesTag] = useState('');
    const [addTVPreferencesTagFocused, setAddTVPreferencesTagFocused] =
        useState(false);
    const addTVPreferencesTagRef = useRef(null);

    const [infoModal, setInfoModal] = useState(false);
    const hiddenFileInput = React.useRef(null);
    const [photoInput, setPhotoInput] = useState(null);
    const [categoryOpen, setCategoryOpen] = useState(false);
    const categoryRef = useRef(null);
    const [categories] = useState(['Apartment', 'Bangalow', 'Chalet', 'Junior suite', 'Room', 'Suite', 'Villa']);

    const [minSurface, setMinSurface] = useState('');
    const [maxSurface, setMaxSurface] = useState('');
    const [room, setRoom] = useState({
        name: '',
        categoryType: '',
        images: [],
        active: true,
        size: '',
        description: '',
        numberOfRooms: '',
        mattressAge: '',
        accessibilityTags: [],
        roomLocationTags: [],
        roomViewTags: [],
        bedTypeTags: [],
        roomTypeTags: [],
        mattressTypeTags: [],
        pillowTypeTags: [],
        extraTags: [],
        tvPreferencesTags: []
    });

    const handleInputPhoto = async (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = async () => {
                const img = new Image();
                img.src = reader.result;

                img.onload = async () => {
                    const width = img.width;
                    const height = img.height;
                    const isPortrait = width < height;
                    const rotatedImageDataURL = isPortrait ? await rotateImage(reader.result) : reader.result;
                    const compressedImageDataURL = await compressImage(rotatedImageDataURL, 0.4);
                    setRoom({
                        ...room,
                        images: [...room?.images ?? [], compressedImageDataURL]
                    });
                    setPhotoInput(null);
                };
            };

            reader.readAsDataURL(file);
        }
    };

    const rotateImage = async (imageUrl) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.height;
                canvas.height = img.width;

                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate((90 * Math.PI) / 180);
                ctx.drawImage(img, -img.width / 2, -img.height / 2);

                resolve(canvas.toDataURL('image/jpeg', 0.8));
            };
        });
    };

    const compressImage = async (imageUrl, quality) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                canvas.toBlob((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(blob);
                }, 'image/jpeg', quality);
            };
        });
    };

    const handlePhoto = (key) => {
        hiddenFileInput.current.click();
        setPhotoInput(key);
    };

    const handleInputText = (e) => {
        setRoom({ ...room, [e.target.name]: e.target.value });
    };

    const handleSelect = (key, value) => {
        if (room[key].includes(value)) {
            setRoom({ ...room, [key]: room[key].filter((el) => el !== value) });
        } else {
            setRoom({ ...room, [key]: [...room[key], value] });
        }
    };

    const handleRemoveSelect = (key, value) => {
        if (room[key].includes(value)) {
            setRoom({ ...room, [key]: room[key].filter((el) => el !== value) });
        }
    };

    const handleTag = (key, value) => {
        if (room[key] === undefined) {
            setRoom({ ...room, [key]: [value] });
            return;
        }
        if (containsObject(value, room[key])) {
            setRoom({ ...room, [key]: room[key].filter((el) => el.icon !== value.icon && el.label !== value.label) });
        } else {
            setRoom({ ...room, [key]: [...room[key], value] });
        }
    };

    const handleSubmit = () => {
        if (!room?.categoryType || room?.categoryType === '') {
            alert('Select a category!');
            return;
        }
        let roomDict = {
            ...room,
            size: `${minSurface}_${maxSurface}`
        };
        updateHotelRoom(roomSub, roomDict, response => {
            if (response?.status === 'success') {
                setActiveTab(0);
            } else {
                alert(response?.message);
            }
        });
    };

    const handleDelete = () => {
        deleteHotelRoom(roomSub, response => {
            if (response?.status === 'success') {
                setActiveTab(0);
                setActiveRoom('');
            } else {
                alert(response?.message);
            }
        });
    };

    const containsObject = (obj, list) => {
        return list.some(item => item.icon === obj.icon && item.label === obj.label);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (categoryOpen && categoryRef.current && !categoryRef.current.contains(event.target)) {
                setCategoryOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [
        categoryOpen,
        categoryRef
    ]);

    useEffect(() => {
        getHotelRoom(roomSub, response => {
            if (response?.status === 'success') {
                setRoom(response?.content);
                let size = response?.content?.size?.split('_');
                if (size?.length > 0) {
                    if (size?.length < 2) {
                        setMaxSurface(size?.[0]);
                    } else {
                        setMinSurface(size?.[0]);
                        setMaxSurface(size?.[1]);
                    }
                }
            } else {
                alert(response?.message);
            }
        });
    }, []);

    return (
        <div className="column-view">
            {showAlert && (
                <div
                    className='row-view filter-darker items-center justify-center'
                    onClick={() => {
                        if (showAlert) {
                            setShowAlert(false);
                        }
                    }}
                >
                    <CustomAlertConfirm
                        title="Do you want to proceed?"
                        message="By continuing the room will be permanently deleted"
                        onConfirm={() => handleDelete()}
                        onCancel={() => setShowAlert(false)}
                    />
                </div>
            )}
            <div>
                <div className="row-view items-center">
                    <MdChevronLeft
                        size={50}
                        className="cursor-pointer"
                        onClick={() => setActiveTab(0)}
                    />
                    <div className="title-secondary">{room?.name}</div>
                </div>

                <div className="column-view flex1">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >
                        {infoModal && (
                            <div
                                className="filter-darker"
                                onClick={() => {
                                    if (infoModal) {
                                        setInfoModal(false);
                                    }
                                }}
                            />
                        )}
                        <div className="subtitle mt50 ml50">
                            Category information
                        </div>
                        <div className="border-container r8 mt20">
                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Category name<span className='primary-text-color'>*</span>
                                </div>
                                <div className="row-view flex1 items-center">
                                    <div className="row-view items-center mr20">
                                        <input
                                            required
                                            className="input-text"
                                            name="name"
                                            placeholder="Category name..."
                                            value={room?.name}
                                            onChange={(e) => {
                                                handleInputText(e);
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={`checkbox cursor-pointer r4 mr10 ${room?.active && 'selected'}`}
                                        onClick={() => {
                                            setRoom({
                                                ...room,
                                                active: !room.active,
                                            });
                                        }}
                                    >
                                        {room?.active && (
                                            <MdCheck color="white" />
                                        )}
                                    </div>
                                    <div className="bold mr10">Set active</div>
                                    <div className="relative row-view items-center">
                                        <BsFillQuestionCircleFill
                                            color={colors.primary}
                                            size={20}
                                            onClick={() =>
                                                setInfoModal(!infoModal)
                                            }
                                        />
                                        <div
                                            className={`info-modal ${infoModal && 'active'}`}
                                        >
                                            Set{' '}
                                            <span className="primary-text-color bold">
                                                "the room"
                                            </span>{' '}
                                            active.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Type<span className='primary-text-color'>*</span>
                                </div>
                                <div className='row-view flex1 items-center'>
                                    <div className='relative' style={{ paddingTop: "2%", paddingBottom: "2%", display: "inline-block" }}>
                                        <div
                                            className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer'
                                            onClick={() => setCategoryOpen(!categoryOpen)}
                                        >
                                            <div>
                                                {room?.categoryType !== '' ? room?.categoryType : 'Select a type'}
                                            </div>
                                            <BiSolidChevronDown />
                                        </div>
                                        <div className={`select-menu ${categoryOpen && 'open'}`} style={{ maxHeight: 350, overflow: "scroll" }} ref={categoryRef}>
                                            {categories?.length !== 0 ? (
                                                <>
                                                    {categories?.map((category, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className='select-item'
                                                                onClick={() => {
                                                                    setRoom({ ...room, categoryType: category });
                                                                    setCategoryOpen(false);
                                                                }}
                                                            >
                                                                <span className='section-title'>
                                                                    {category}
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    <div className='select-item'>
                                                        No types found
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Pictures
                                </div>
                                <div className='flex1 ofx-hidden'>
                                    <div className='row-view flex1 items-center ofx-scroll'>
                                        {room?.images?.map((image, index) => (
                                            <div className='relative p10'>
                                                <img
                                                    width={250}
                                                    height={150}
                                                    style={{ objectFit: 'cover' }}
                                                    alt={index}
                                                    key={index}
                                                    src={image}
                                                    className='r8'
                                                />
                                                <div
                                                    className="close-icon"
                                                    onClick={() => {
                                                        let imgs = room?.images;
                                                        imgs.splice(index, 1);
                                                        setRoom({
                                                            ...room,
                                                            imgaes: imgs,
                                                        });
                                                    }}
                                                >
                                                    <MdClose size={20} color="white" />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={`row-view items-center gap20 ${room?.images?.length > 0 && 'mt20'}`}>
                                        <div
                                            className="row-view border-container b-dashed b2 r8 p20 cursor-pointer items-center justify-center"
                                            onClick={() => handlePhoto('images')}
                                        >
                                            <img src={addImg} alt="addImg" />
                                        </div>
                                        <div className="section-subtitle">
                                            (JPGs or PNGs of at least 1080x566 px)
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Surface area
                                </div>
                                <div className="row-view flex1 items-center">
                                    <span className='mr10'>Min</span>
                                    <input
                                        className="input-text w60 ph10 mr10"
                                        name="size"
                                        placeholder="ex: 30"
                                        value={minSurface}
                                        onChange={(e) => setMinSurface(e.target.value)}
                                    />
                                    <span className='mr10'>Max</span>
                                    <input
                                        className="input-text w60 ph10 mr10"
                                        name="size"
                                        placeholder="ex: 35"
                                        value={maxSurface}
                                        onChange={(e) => setMaxSurface(e.target.value)}
                                    />
                                    <h2 className='primary-text-color'>m²</h2>
                                </div>
                            </div>
                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Description
                                </div>
                                <div className="row-view flex1">
                                    <textarea
                                        className="input-text flex1"
                                        rows={10}
                                        value={room?.description}
                                        name="description"
                                        placeholder="Description..."
                                        onChange={(e) => handleInputText(e)}
                                    />
                                </div>
                            </div>
                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Number of rooms<span className='primary-text-color'>*</span>
                                </div>
                                <div className="row-view flex1 items-center">
                                    <input
                                        required
                                        className="input-text"
                                        name="numberOfRooms"
                                        placeholder="Number of rooms..."
                                        value={room?.numberOfRooms}
                                        onChange={(e) => handleInputText(e)}
                                    />
                                </div>
                            </div>
                            <div className="row-view items-center p20">
                                <div className="section-title flex04">
                                    Mattress age
                                </div>
                                <div className="row-view flex1 items-center">
                                    <input
                                        className="input-text"
                                        name="mattressAge"
                                        placeholder="Mattress age..."
                                        value={room?.mattressAge}
                                        onChange={(e) => handleInputText(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="subtitle mt50 ml50">
                            Category description
                        </div>
                        <div className="border-container r8 mt20">
                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Accessibility
                                </div>
                                <div className="flex1">
                                    <div>
                                        <div className="row-view items-center wrap flex1">
                                            {hotel_profile_tags.room.accessibility.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.accessibilityTags && containsObject(item, room?.accessibilityTags) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('accessibilityTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            {room?.accessibilityTags?.filter(el => !containsObject(el, hotel_profile_tags.room.accessibility))?.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.accessibilityTags && room?.accessibilityTags?.includes(item) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('accessibilityTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            <input
                                                type='text'
                                                ref={addAccessibilityTagRef}
                                                placeholder={!addAccessibilityTagFocused && '+Add tag'}
                                                className={`borderless none-outline mr20 mt10 mb10 ${addAccessibilityTagFocused && 'tag'}`}
                                                size={addAccessibilityTag.length || 4}
                                                value={addAccessibilityTag}
                                                onChange={(e) => setAddAccessibilityTag(e.target.value)}
                                                onFocus={() => setAddAccessibilityTagFocused(true)}
                                                onBlur={() => {
                                                    let tag = { icon: '🏷️', label: addAccessibilityTag };
                                                    if (addAccessibilityTag.length < 1 || containsObject(tag, room?.accessibilityTags)) {
                                                        setAddAccessibilityTag('');
                                                        setAddAccessibilityTagFocused(false);
                                                        addAccessibilityTagRef.current.blur();
                                                        return;
                                                    }
                                                    let tags = room?.accessibilityTags ?? [];
                                                    tags.push(tag);
                                                    setRoom({ ...room, accessibilityTags: tags });
                                                    setAddAccessibilityTag('');
                                                    setAddAccessibilityTagFocused(false);
                                                    addAccessibilityTagRef.current.blur();
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        addAccessibilityTagRef.current.blur();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Room location
                                </div>
                                <div className="flex1">
                                    <div>
                                        <div className="row-view wrap flex1">
                                            {hotel_profile_tags.room.room_location.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.roomLocationTags && containsObject(item, room?.roomLocationTags) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('roomLocationTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            {room?.roomLocationTags?.filter(el => !containsObject(el, hotel_profile_tags.room.room_location))?.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.roomLocationTags && room?.roomLocationTags?.includes(item) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('roomLocationTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            <input
                                                type='text'
                                                ref={addLocationTagRef}
                                                placeholder={!addLocationTagFocused && '+Add tag'}
                                                className={`borderless none-outline mr20 mt10 mb10 ${addLocationTagFocused && 'tag'}`}
                                                size={addLocationTag.length || 4}
                                                value={addLocationTag}
                                                onChange={(e) => setAddLocationTag(e.target.value)}
                                                onFocus={() => setAddLocationTagFocused(true)}
                                                onBlur={() => {
                                                    let tag = { icon: '🏷️', label: addLocationTag };
                                                    if (addLocationTag.length < 1 || containsObject(tag, room?.roomLocationTags)) {
                                                        setAddLocationTag('');
                                                        setAddLocationTagFocused(false);
                                                        addLocationTagRef.current.blur();
                                                        return;
                                                    }
                                                    let tags = room?.roomLocationTags ?? [];
                                                    tags.push(tag);
                                                    setRoom({ ...room, roomLocationTags: tags });
                                                    setAddLocationTag('');
                                                    setAddLocationTagFocused(false);
                                                    addLocationTagRef.current.blur();
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        addLocationTagRef.current.blur();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Room view
                                </div>
                                <div className="flex1">
                                    <div>
                                        <div className="row-view wrap flex1">
                                            {hotel_profile_tags.room.room_view.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.roomViewTags && containsObject(item, room?.roomViewTags) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('roomViewTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            {room?.roomViewTags?.filter(el => !containsObject(el, hotel_profile_tags.room.room_view))?.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.roomViewTags && room?.roomViewTags?.includes(item) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('roomViewTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            <input
                                                type='text'
                                                ref={addViewTagRef}
                                                placeholder={!addViewTagFocused && '+Add tag'}
                                                className={`borderless none-outline mr20 mt10 mb10 ${addViewTagFocused && 'tag'}`}
                                                size={addViewTag.length || 4}
                                                value={addViewTag}
                                                onChange={(e) => setAddViewTag(e.target.value)}
                                                onFocus={() => setAddViewTagFocused(true)}
                                                onBlur={() => {
                                                    let tag = { icon: '🏷️', label: addViewTag };
                                                    if (addViewTag.length < 1 || containsObject(tag, room?.roomViewTags)) {
                                                        setAddViewTag('');
                                                        setAddViewTagFocused(false);
                                                        addViewTagRef.current.blur();
                                                        return;
                                                    }
                                                    let tags = room?.roomViewTags ?? [];
                                                    tags.push(tag);
                                                    setRoom({ ...room, roomViewTags: tags });
                                                    setAddViewTag('');
                                                    setAddViewTagFocused(false);
                                                    addViewTagRef.current.blur();
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        addViewTagRef.current.blur();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Bed type
                                </div>
                                <div className="flex1">
                                    <div>
                                        <div className="row-view wrap flex1">
                                            {hotel_profile_tags.room.bed_type.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.bedTypeTags && containsObject(item, room?.bedTypeTags) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('bedTypeTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            {room?.bedTypeTags?.filter(el => !containsObject(el, hotel_profile_tags.room.bed_type))?.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.bedTypeTags && room?.bedTypeTags?.includes(item) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('bedTypeTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            <input
                                                type='text'
                                                ref={addBedTypeTagRef}
                                                placeholder={!addBedTypeTagFocused && '+Add tag'}
                                                className={`borderless none-outline mr20 mt10 mb10 ${addBedTypeTagFocused && 'tag'}`}
                                                size={addBedTypeTag.length || 4}
                                                value={addBedTypeTag}
                                                onChange={(e) => setAddBedTypeTag(e.target.value)}
                                                onFocus={() => setAddBedTypeTagFocused(true)}
                                                onBlur={() => {
                                                    let tag = { icon: '🏷️', label: addBedTypeTag };
                                                    if (addBedTypeTag.length < 1 || containsObject(tag, room?.bedTypeTags)) {
                                                        setAddBedTypeTag('');
                                                        setAddBedTypeTagFocused(false);
                                                        addBedTypeTagRef.current.blur();
                                                        return;
                                                    }
                                                    let tags = room?.bedTypeTags ?? [];
                                                    tags.push(tag);
                                                    setRoom({ ...room, bedTypeTags: tags });
                                                    setAddBedTypeTag('');
                                                    setAddBedTypeTagFocused(false);
                                                    addBedTypeTagRef.current.blur();
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        addBedTypeTagRef.current.blur();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Room type
                                </div>
                                <div className="flex1">
                                    <div>
                                        <div className="row-view wrap flex1">
                                            {hotel_profile_tags.room.room_type.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.roomTypeTags && containsObject(item, room?.roomTypeTags) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('roomTypeTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            {room?.roomTypeTags?.filter(el => !containsObject(el, hotel_profile_tags.room.room_type))?.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.roomTypeTags && room?.roomTypeTags?.includes(item) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('roomTypeTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            <input
                                                type='text'
                                                ref={addRoomTypeTagRef}
                                                placeholder={!addRoomTypeTagFocused && '+Add tag'}
                                                className={`borderless none-outline mr20 mt10 mb10 ${addRoomTypeTagFocused && 'tag'}`}
                                                size={addRoomTypeTag.length || 4}
                                                value={addRoomTypeTag}
                                                onChange={(e) => setAddRoomTypeTag(e.target.value)}
                                                onFocus={() => setAddRoomTypeTagFocused(true)}
                                                onBlur={() => {
                                                    let tag = { icon: '🏷️', label: addRoomTypeTag };
                                                    if (addRoomTypeTag.length < 1 || containsObject(tag, room?.roomTypeTags)) {
                                                        setAddRoomTypeTag('');
                                                        setAddRoomTypeTagFocused(false);
                                                        addRoomTypeTagRef.current.blur();
                                                        return;
                                                    }
                                                    let tags = room?.roomTypeTags ?? [];
                                                    tags.push(tag);
                                                    setRoom({ ...room, roomTypeTags: tags });
                                                    setAddRoomTypeTag('');
                                                    setAddRoomTypeTagFocused(false);
                                                    addRoomTypeTagRef.current.blur();
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        addRoomTypeTagRef.current.blur();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Mattress Type
                                </div>
                                <div className="flex1">
                                    <div>
                                        <div className="row-view wrap flex1">
                                            {hotel_profile_tags.room.mattress_type.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.mattressTypeTags && containsObject(item, room?.mattressTypeTags) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('mattressTypeTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            {room?.mattressTypeTags?.filter(el => !containsObject(el, hotel_profile_tags.room.mattress_type))?.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.mattressTypeTags && room?.mattressTypeTags?.includes(item) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('mattressTypeTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            <input
                                                type='text'
                                                ref={addMattressTypeTagRef}
                                                placeholder={!addMattressTypeTagFocused && '+Add tag'}
                                                className={`borderless none-outline mr20 mt10 mb10 ${addMattressTypeTagFocused && 'tag'}`}
                                                size={addMattressTypeTag.length || 4}
                                                value={addMattressTypeTag}
                                                onChange={(e) => setAddMattressTypeTag(e.target.value)}
                                                onFocus={() => setAddMattressTypeTagFocused(true)}
                                                onBlur={() => {
                                                    let tag = { icon: '🏷️', label: addMattressTypeTag };
                                                    if (addMattressTypeTag.length < 1 || containsObject(tag, room?.mattressTypeTags)) {
                                                        setAddMattressTypeTag('');
                                                        setAddMattressTypeTagFocused(false);
                                                        addMattressTypeTagRef.current.blur();
                                                        return;
                                                    }
                                                    let tags = room?.mattressTypeTags ?? [];
                                                    tags.push(tag);
                                                    setRoom({ ...room, mattressTypeTags: tags });
                                                    setAddMattressTypeTag('');
                                                    setAddMattressTypeTagFocused(false);
                                                    addMattressTypeTagRef.current.blur();
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        addMattressTypeTagRef.current.blur();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Pillow type
                                </div>
                                <div className="flex1">
                                    <div>
                                        <div className="row-view wrap flex1">
                                            {hotel_profile_tags.room.pillow_type.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.pillowTypeTags && containsObject(item, room?.pillowTypeTags) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('pillowTypeTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            {room?.pillowTypeTags?.filter(el => !containsObject(el, hotel_profile_tags.room.pillow_type))?.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.pillowTypeTags && room?.pillowTypeTags?.includes(item) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('pillowTypeTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            <input
                                                type='text'
                                                ref={addPillowTypeTagRef}
                                                placeholder={!addPillowTypeTagFocused && '+Add tag'}
                                                className={`borderless none-outline mr20 mt10 mb10 ${addPillowTypeTagFocused && 'tag'}`}
                                                size={addPillowTypeTag.length || 4}
                                                value={addPillowTypeTag}
                                                onChange={(e) => setAddPillowTypeTag(e.target.value)}
                                                onFocus={() => setAddPillowTypeTagFocused(true)}
                                                onBlur={() => {
                                                    let tag = { icon: '🏷️', label: addPillowTypeTag };
                                                    if (addPillowTypeTag.length < 1 || containsObject(tag, room?.pillowTypeTags)) {
                                                        setAddPillowTypeTag('');
                                                        setAddPillowTypeTagFocused(false);
                                                        addPillowTypeTagRef.current.blur();
                                                        return;
                                                    }
                                                    let tags = room?.pillowTypeTags ?? [];
                                                    tags.push(tag);
                                                    setRoom({ ...room, pillowTypeTags: tags });
                                                    setAddPillowTypeTag('');
                                                    setAddPillowTypeTagFocused(false);
                                                    addPillowTypeTagRef.current.blur();
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        addPillowTypeTagRef.current.blur();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Bathroom
                                </div>
                                <div className="flex1">
                                    <div>
                                        <div className="row-view wrap flex1">
                                            {hotel_profile_tags.room.bathroom.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.bathroomTags && containsObject(item, room?.bathroomTags) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('bathroomTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            {room?.bathroomTags?.filter(el => !containsObject(el, hotel_profile_tags.room.bathroom))?.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.bathroomTags && room?.bathroomTags?.includes(item) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('bathroomTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            <input
                                                type='text'
                                                ref={addBathroomTagRef}
                                                placeholder={!addBathroomTagFocused && '+Add tag'}
                                                className={`borderless none-outline mr20 mt10 mb10 ${addBathroomTagFocused && 'tag'}`}
                                                size={addBathroomTag.length || 4}
                                                value={addBathroomTag}
                                                onChange={(e) => setAddBathroomTag(e.target.value)}
                                                onFocus={() => setAddBathroomTagFocused(true)}
                                                onBlur={() => {
                                                    let tag = { icon: '🏷️', label: addBathroomTag };
                                                    if (addBathroomTag.length < 1 || containsObject(tag, room?.bathroomTags)) {
                                                        setAddBathroomTag('');
                                                        setAddBathroomTagFocused(false);
                                                        addBathroomTagRef.current.blur();
                                                        return;
                                                    }
                                                    let tags = room?.bathroomTags ?? [];
                                                    tags.push(tag);
                                                    setRoom({ ...room, bathroomTags: tags });
                                                    setAddBathroomTag('');
                                                    setAddBathroomTagFocused(false);
                                                    addBathroomTagRef.current.blur();
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        addBathroomTagRef.current.blur();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Restroom
                                </div>
                                <div className="flex1">
                                    <div>
                                        <div className="row-view wrap flex1">
                                            {hotel_profile_tags.room.restroom.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.restroomTags && containsObject(item, room?.restroomTags) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('restroomTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            {room?.restroomTags?.filter(el => !containsObject(el, hotel_profile_tags.room.restroom))?.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.restroomTags && room?.restroomTags?.includes(item) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('restroomTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            <input
                                                type='text'
                                                ref={addRestroomTagRef}
                                                placeholder={!addRestroomTagFocused && '+Add tag'}
                                                className={`borderless none-outline mr20 mt10 mb10 ${addRestroomTagFocused && 'tag'}`}
                                                size={addRestroomTag.length || 4}
                                                value={addRestroomTag}
                                                onChange={(e) => setAddRestroomTag(e.target.value)}
                                                onFocus={() => setAddRestroomTagFocused(true)}
                                                onBlur={() => {
                                                    let tag = { icon: '🏷️', label: addRestroomTag };
                                                    if (addRestroomTag.length < 1 || containsObject(tag, room?.restroomTags)) {
                                                        setAddRestroomTag('');
                                                        setAddRestroomTagFocused(false);
                                                        addRestroomTagRef.current.blur();
                                                        return;
                                                    }
                                                    let tags = room?.restroomTags ?? [];
                                                    tags.push(tag);
                                                    setRoom({ ...room, restroomTags: tags });
                                                    setAddRestroomTag('');
                                                    setAddRestroomTagFocused(false);
                                                    addRestroomTagRef.current.blur();
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        addRestroomTagRef.current.blur();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Facilities
                                </div>
                                <div className="flex1">
                                    <div>
                                        <div className="row-view wrap flex1">
                                            {hotel_profile_tags.room.facilities.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.facilitiesTags && containsObject(item, room?.facilitiesTags) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('facilitiesTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            {room?.facilitiesTags?.filter(el => !containsObject(el, hotel_profile_tags.room.facilities))?.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.facilitiesTags && room?.facilitiesTags?.includes(item) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('facilitiesTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            <input
                                                type='text'
                                                ref={addFacilitiesTagRef}
                                                placeholder={!addFacilitiesTagFocused && '+Add tag'}
                                                className={`borderless none-outline mr20 mt10 mb10 ${addFacilitiesTagFocused && 'tag'}`}
                                                size={addFacilitiesTag.length || 4}
                                                value={addFacilitiesTag}
                                                onChange={(e) => setAddFacilitiesTag(e.target.value)}
                                                onFocus={() => setAddFacilitiesTagFocused(true)}
                                                onBlur={() => {
                                                    let tag = { icon: '🏷️', label: addFacilitiesTag };
                                                    if (addFacilitiesTag.length < 1 || containsObject(tag, room?.facilitiesTags)) {
                                                        setAddFacilitiesTag('');
                                                        setAddFacilitiesTagFocused(false);
                                                        addFacilitiesTagRef.current.blur();
                                                        return;
                                                    }
                                                    let tags = room?.facilitiesTags ?? [];
                                                    tags.push(tag);
                                                    setRoom({ ...room, facilitiesTags: tags });
                                                    setAddFacilitiesTag('');
                                                    setAddFacilitiesTagFocused(false);
                                                    addFacilitiesTagRef.current.blur();
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        addFacilitiesTagRef.current.blur();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-view items-center p20 bb">
                                <div className="section-title flex04">
                                    Extra
                                </div>
                                <div className="flex1">
                                    <div>
                                        <div className="row-view wrap flex1">
                                            {hotel_profile_tags.room.extra.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.extraTags && containsObject(item, room?.extraTags) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('extraTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            {room?.extraTags?.filter(el => !containsObject(el, hotel_profile_tags.room.extra))?.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.extraTags && room?.extraTags?.includes(item) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('extraTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            <input
                                                type='text'
                                                ref={addExtraTagRef}
                                                placeholder={!addExtraTagFocused && '+Add tag'}
                                                className={`borderless none-outline mr20 mt10 mb10 ${addExtraTagFocused && 'tag'}`}
                                                size={addExtraTag.length || 4}
                                                value={addExtraTag}
                                                onChange={(e) => setAddExtraTag(e.target.value)}
                                                onFocus={() => setAddExtraTagFocused(true)}
                                                onBlur={() => {
                                                    let tag = { icon: '🏷️', label: addExtraTag };
                                                    if (addExtraTag.length < 1 || containsObject(tag, room?.extraTags)) {
                                                        setAddExtraTag('');
                                                        setAddExtraTagFocused(false);
                                                        addExtraTagRef.current.blur();
                                                        return;
                                                    }
                                                    let tags = room?.extraTags ?? [];
                                                    tags.push(tag);
                                                    setRoom({ ...room, extraTags: tags });
                                                    setAddExtraTag('');
                                                    setAddExtraTagFocused(false);
                                                    addExtraTagRef.current.blur();
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        addExtraTagRef.current.blur();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-view items-center p20">
                                <div className="section-title flex04">
                                    TV preferences
                                </div>
                                <div className="flex1">
                                    <div>
                                        <div className="row-view wrap flex1">
                                            {hotel_profile_tags.room.tv_preferences.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.tvPreferencesTags && containsObject(item, room?.tvPreferencesTags) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('tvPreferencesTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            {room?.tvPreferencesTags?.filter(el => !containsObject(el, hotel_profile_tags.room.tv_preferences))?.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`row-view tag mr20 mt10 mb10 ${room?.tvPreferencesTags && room?.tvPreferencesTags?.includes(item) &&
                                                            'selected'
                                                            }`}
                                                        onClick={() =>
                                                            handleTag('tvPreferencesTags', item)
                                                        }
                                                    >
                                                        <div>{item.icon} {item.label}</div>
                                                    </div>
                                                );
                                            })}
                                            <input
                                                type='text'
                                                ref={addTVPreferencesTagRef}
                                                placeholder={!addTVPreferencesTagFocused && '+Add tag'}
                                                className={`borderless none-outline mr20 mt10 mb10 ${addTVPreferencesTagFocused && 'tag'}`}
                                                size={addTVPreferencesTag.length || 4}
                                                value={addTVPreferencesTag}
                                                onChange={(e) => setAddTVPreferencesTag(e.target.value)}
                                                onFocus={() => setAddTVPreferencesTagFocused(true)}
                                                onBlur={() => {
                                                    let tag = { icon: '🏷️', label: addTVPreferencesTag };
                                                    if (addTVPreferencesTag.length < 1 || containsObject(tag, room?.tvPreferencesTags)) {
                                                        setAddTVPreferencesTag('');
                                                        setAddTVPreferencesTagFocused(false);
                                                        addTVPreferencesTagRef.current.blur();
                                                        return;
                                                    }
                                                    let tags = room?.tvPreferencesTags ?? [];
                                                    tags.push(tag);
                                                    setRoom({ ...room, tvPreferencesTags: tags });
                                                    setAddTVPreferencesTag('');
                                                    setAddTVPreferencesTagFocused(false);
                                                    addTVPreferencesTagRef.current.blur();
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        addTVPreferencesTagRef.current.blur();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            ref={hiddenFileInput}
                            onChange={(e) => handleInputPhoto(e)}
                            style={{ display: 'none' }}
                        />
                        <div className="row-view justify-between mt20">
                            <button className="primary-button bgRed" type="button" onClick={() => setShowAlert(true)}>
                                Delete
                            </button>
                            <div>
                                <button
                                    className="secondary-button mr20"
                                    type="button"
                                    onClick={() => {
                                        setActiveTab(0);
                                        setActiveRoom('');
                                    }}
                                >
                                    Cancel
                                </button>
                                <button className="primary-button" type="submit">
                                    Save
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
};
