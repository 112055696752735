import React, { useEffect, useRef, useState } from 'react';
import graphIcon from '../../assets/icons/Graph.svg';
import { trafficSourceOptions, trafficSourceData, } from '../../utils/mock/TrafficSourceData';
import { newReservationOptions, newReservationData } from '../../utils/mock/NewReservationData';
import logoOpacity from '../../assets/logos/logo-opacity.svg';
import goalIcon from '../../assets/icons/Goal.svg';
import lockIcon from '../../assets/icons/Lock.svg';
import hotelIcon from '../../assets/icons/Hotel.svg'
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowLeft, MdArrowForward } from 'react-icons/md';
import useHotelApi from '../../api/hotel';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from "chart.js"
import { Line } from "react-chartjs-2"
import { GoalProgressLine } from '../../components/ProgressLine/GoalProgressLine';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const GraphIcon = () => {
    return (
        <img
            src={graphIcon}
            alt='todaysOrder'
            className='icon'
        />
    )
}

const myHotels = [
    'The Mozart Hotel'
]

const colors = [
    '#7856FF',
    '#FF7557',
    '#80E1D9',
    '#F8BC3B',
    '#3B87F8'
]

export const Dashboard = ({ handleLogout }) => {
    const { getHotelListLazy } = useHotelApi();
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const [hotels, setHotels] = useState([])
    const [hotelsOpen, setHotelssOpen] = useState(false)
    const hotelsRef = useRef(null);
    const [hotelsSearch, setHotelsSearch] = useState('');

    const [data, setData] = useState({
        todays_order: '0',
        net_revenue: '0',
        income_earned: '0',
        new_travelers: '0',
        top_hotels: [
            // { name: 'The Mozart Hotel', revenue: '23.37' }
        ],
        commission: "15",
        goal_commission: "14",
        nb_reservations: "0",
        goal: "100",
        goal_date: '', //moment('12-31-2023').format('gg MMM gggg'),
        occupancy_rate: "0"
    })

    const [matches, setMatches] = useState({
        hotels: [
            // { img: null, name: 'The Mozaart' }
        ],
        lost: [
            // { img: null, firstname: 'Harry', lastname: 'Cover' }
        ],
        won: [
            // { img: null, firstname: 'Harry', lastname: 'Cover' }
        ]
    })

    useEffect(() => {
        let token = (localStorage.getItem('auth'));
        if (token) {
            const expiration = JSON.parse(localStorage.getItem('expiration'));
            if (expiration < Date.now() / 1000) {
                handleLogout();
            }
        }

        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [handleLogout]);

    const retrieveHotels = (search) => {
        getHotelListLazy(0, 10, search, undefined, response => {
            if (response?.status === 'success') {
                let hotels = [{ name: 'All Hotels' }, ...response?.content?.hotels]
                setHotels(hotels)
            }
        })
    }

    useEffect(() => {
        retrieveHotels(hotelsSearch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        function handleClickOutside(event) {
            if (hotelsOpen && hotelsRef.current && !hotelsRef.current.contains(event.target)) {
                setHotelssOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [
        hotelsOpen,
        hotelsRef
    ]);

    return (

        <div className='page-container'>
            {/* <div className='relative' style={{ paddingTop: "2%", paddingBottom: "2%", display: "inline-block" }}>
                {hotelsOpen ?
                    <input
                        type='text'
                        value={hotelsSearch}
                        className='input-text w300'
                        autoFocus
                        onChange={(e) => {
                            setHotelsSearch(e.target.value)
                            retrieveHotels(e.target.value)
                        }}
                    />
                    :
                    <div
                        className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer'
                        onClick={() => setHotelssOpen(!hotelsOpen)}
                    >
                        <div>
                            {hotel?.name
                                ? hotel?.name
                                : 'Select hotel'}
                        </div>
                        <BiSolidChevronDown />
                    </div>
                }
                <div className={`select-menu ${hotelsOpen && 'open'}`} style={{ maxHeight: 300, overflow: "scroll" }} ref={hotelsRef}>
                    {hotels?.length !== 0 ? (
                        <>
                            {hotels?.map((h, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='select-item'
                                        onClick={() => {
                                            setHotel(h)
                                            setHotelssOpen(false)
                                        }}
                                    >
                                        <span className='section-title'>
                                            {h?.name}<span className='grey-text'> - {h?.address?.city}</span>
                                        </span>
                                    </div>
                                )
                            })}
                        </>
                    ) : (
                        <>
                            <div className='select-item'>
                                No hotels found
                            </div>
                        </>
                    )}
                </div>
            </div> */}
            <div className={`${windowSize < 965 ? 'grid-view2' : 'row-view'}`}>
                <div
                    className={`dashboard-section bgPrimary flex1 mr20 column-view ${windowSize < 965 ? 'pv20' : 'pv50'}`}
                    style={{
                        backgroundImage: `url("${logoOpacity}")`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "bottom right"
                    }}
                >
                    <div className='column-view ph20'>
                        <img
                            src={goalIcon}
                            alt='goalIcon'
                            className='icon50'
                        />
                        <div className='row-view items-baseline mt20'>
                            <div className='subtitle white-text mr10'>Current commission:</div>
                            <div className='column-view relative'>
                                <div className=''
                                    style={{
                                        position: "absolute",
                                        bottom: 0,
                                        backgroundColor: "#FFFFFF33",
                                        width: "100%",
                                        height: "50%"
                                    }}
                                />
                                <div className='title bold white-text'>{data?.commission}%</div>
                            </div>
                        </div>
                        <div className='mt20 white-text'>
                            Complete <span className='bold'>{data?.goal} reservations</span> before {data?.goal_date} to unlock <span className='bold'>“{data?.goal_commission}% commission”</span>
                        </div>
                        <div className='relative mt50'>
                            <GoalProgressLine rate={parseInt(data?.nb_reservations)} max={data?.goal} />
                            <div className='column-view items-center justify-center absolute b b2 r100 bgPrimary' style={{ width: 40, height: 40, right: 0, top: -8 }}>
                                <img
                                    src={lockIcon}
                                    alt='lockIcon'
                                    className='icon'
                                />
                            </div>
                        </div>
                        <div className='white-text text-center mt10'>{data?.nb_reservations} / {data?.goal} reservations</div>
                    </div>
                </div>

                <div className='dashboard-section flex1 mr20'>
                    <div className='row-view items-center p20 pb5'>
                        <GraphIcon />
                        <div className='section-title ml10'>Total orders</div>
                    </div>
                    <div className='h-separator bgE9E9E9 mt20' />
                    {data?.todays_order !== '0' || data?.occupancy_rate !== '0' ? (
                        <div className='column-view items-center justify-center pv20'>
                            <div className='row-view items-center p10'>
                                <div className='purple-square16 mr10' />
                                <div className='section-secondary-title'>Volume</div>
                            </div>
                            <div className='xl-text'>{data?.todays_order}</div>
                            <div className='section-subtitle'>Reservations</div>
                            <div className='row-view items-center mt20'>
                                <div className='bold mr10'>Occupancy rate: </div>
                                <div className='primary-text-color bold greenbg01 pv4 ph10'>{data?.occupancy_rate}%</div>
                            </div>
                        </div>
                    ) : (
                        <div className='column-view items-center justify-center p30'>
                            <img
                                src='https://storage.googleapis.com/mhm-medias/bo-hotels/Length_of_stay.svg'
                                alt='Length_of_stay'
                                className='icon w200 h200'
                            />
                            <div className='mt20'>
                                <div className='bold text-center'>No order yet</div>
                                <div className='text-center'>See the progress of your orders in real time</div>
                            </div>
                        </div>
                    )}
                </div>

                <div className={`dashboard-section flex1 ${windowSize < 965 && 'mr20 mt20'}`}>
                    <div className='row-view items-center p20 pb5'>
                        <GraphIcon />
                        <div className='section-title ml10'>Revenue (€)</div>
                    </div>
                    <div className='h-separator bgE9E9E9 mt20' />
                    {data?.net_revenue !== '0' ? (
                        <div className='column-view items-center justify-center pv20'>
                            <div className='row-view items-center p10'>
                                <div className='purple-square16 mr10' />
                                <div className='section-secondary-title'>Net Revenue - By all reservations</div>
                            </div>
                            <div className='xl-text'>{data?.net_revenue}</div>
                            <div className='section-subtitle'>euros</div>
                        </div>
                    ) : (
                        <div className='column-view items-center justify-center p30'>
                            <img
                                src='https://storage.googleapis.com/mhm-medias/bo-hotels/Revenue.svg'
                                alt='Revenue'
                                className='icon w200'
                            />
                            <div className='mt20'>
                                <div className='bold text-center'>No revenue yet</div>
                                <div className='text-center'>See the progress of your revenue in real time</div>
                            </div>
                        </div>
                    )}
                </div>

                {windowSize < 965 &&
                    <div className='dashboard-section flex1 mt20'>
                        <div className='row-view items-center p20 pb5'>
                            <GraphIcon />
                            <div className='section-title ml10'>Reservations</div>
                        </div>
                        <div className='h-separator bgE9E9E9 mt20' />
                        <div className='pv20 w100'>
                            <Line options={newReservationOptions} data={newReservationData} />
                        </div>
                    </div>
                }
            </div>

            <div className='dashboard-section flex1 mt20'>
                <div className='row-view items-center p20 pb5'>
                    <GraphIcon />
                    <div className='section-title ml10'>Traffic Sources</div>
                </div>
                <div className='ml20 section-subtitle'>Last 30 days</div>
                <div className='h-separator bgE9E9E9 mt20' />
                {trafficSourceData?.datasets?.length !== 0 ? (
                    <div className='pv20 w100'>
                        <Line options={trafficSourceOptions} data={trafficSourceData} />
                    </div>
                ) : (
                    <div className='column-view items-center justify-center p30'>
                        <img
                            src='https://storage.googleapis.com/mhm-medias/bo-hotels/Traffic_sources.svg'
                            alt='Traffic_sources'
                            className='icon w200'
                        />
                        <div className='mt20'>
                            <div className='bold text-center'> Traffic source</div>
                            <div className='text-center'>Get to know my target audience</div>
                        </div>
                    </div>
                )}
            </div>

            <div className={`row-view mt20 ${windowSize < 965 && 'grid-view2'}`}>
                {windowSize > 965 &&
                    <div className='dashboard-section flex1 mr20'>
                        <div className='row-view items-center p20 pb5'>
                            <GraphIcon />
                            <div className='section-title ml10'>Reservations</div>
                        </div>
                        <div className='h-separator bgE9E9E9 mt20' />
                        {newReservationData?.datasets?.length !== 0 ? (
                            <div className='pv20 w100'>
                                <Line options={newReservationOptions} data={newReservationData} />
                            </div>
                        ) : (
                            <div className='column-view items-center justify-center p30'>
                                <img
                                    src='https://storage.googleapis.com/mhm-medias/bo-hotels/Reservations.svg'
                                    alt='Reservations'
                                    className='icon w200'
                                />
                                <div className='mt20'>
                                    <div className='bold text-center'>Reservations trend</div>
                                    <div className='text-center'>See how my reservations are progressing</div>
                                </div>
                            </div>
                        )}
                    </div>
                }
                <div className='dashboard-section flex1 mr20'>
                    <div className='row-view items-center p20 pb5'>
                        <GraphIcon />
                        <div className='section-title ml10'>My Rank <span className='normal'>(Similar hotels in my city)</span></div>
                    </div>
                    <div className='h-separator bgE9E9E9 mt20' />
                    <div className='column-view items-center justify-center p20'>
                        {data?.top_hotels?.length !== 0 ? (
                            <div>
                                {data && data?.top_hotels?.sort((a, b) => parseInt(b.revenue) - parseInt(a.revenue)).map((hotel, index) => {
                                    return (
                                        <div key={index} className={`w100 mb10 row-view items-center`}>
                                            <div className={`section-secondary-title flex04 mr10 ${windowSize > 1200 && 'text-end'}`}>{hotel.name}</div>
                                            <div className='section-secondary-title row-view items-center flex06 w100'>
                                                <div
                                                    style={{
                                                        backgroundColor: colors[index],
                                                        width: `${(hotel.revenue * 100) / 100}%`,
                                                        height: 20,
                                                        borderRadius: 6,
                                                        marginRight: 10,
                                                    }}
                                                />
                                                <div className={`${!myHotels.includes(hotel.name) && 'blur50 unselectable'}`}>{hotel.revenue}K€</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : (
                            <div className='column-view items-center justify-center p30'>
                                <img
                                    src='https://storage.googleapis.com/mhm-medias/bo-hotels/My_rank.svg'
                                    alt='My_rank'
                                    className='icon w200'
                                />
                                <div className='mt20'>
                                    <div className='bold text-center'>No rank yet</div>
                                    <div className='text-center'>See how my rank is progressing</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='dashboard-section flex1'>
                    <div className='row-view items-center p20 pb5'>
                        <GraphIcon />
                        <div className='section-title ml10'>Match tracking</div>
                    </div>
                    <div className='h-separator bgE9E9E9 mt20' />
                    {matches?.hotels?.length !== 0 ? (
                        <div className='column-view justify-center p20 mt20'>
                            <div className='row-view flex1 justify-between items-center'>
                                <MdOutlineKeyboardArrowLeft size={50} />
                                <div className='row-view justify-center items-center flex09'>
                                    {matches?.hotels?.map((h, i) => {
                                        return (
                                            <div key={i} className='column-view items-center mr10 ml10'>
                                                {h.img ?
                                                    <img src={h.img} alt='logoMozart' className='shadowbox20 r8' />
                                                    :
                                                    <img src={hotelIcon} alt='hotelIcon' style={{ width: 64 }} />
                                                }
                                                <div className='text-center bold mt10'>{h?.name}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <MdOutlineKeyboardArrowRight size={50} />
                            </div>
                            <div className='h-separator bg161616 mt20' />
                            <div className='row-view items-start justify-evenly mt20'>
                                <div className='p20'>
                                    <div className='row-view items-cetner'>
                                        <MdArrowForward size={20} color='#5E9A37' />
                                        <div className='primary-text-color2 bold'>{matches?.won?.length} Matches won</div>
                                    </div>
                                    {matches && matches?.won.map((match, index) => {
                                        return (
                                            <div key={index} className='row-view items-center mt10'>
                                                <img src={match.img} alt={index} className='mr5' />
                                                <div>{match.firstname} {match.lastname}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='v-separator bg161616' />
                                <div className='p20'>
                                    <div className='row-view items-cetner'>
                                        <MdArrowForward size={20} color='#E92C2C' />
                                        <div className='red-text bold'>{matches?.lost?.length} Matches lost</div>
                                    </div>
                                    {matches && matches?.lost.map((match, index) => {
                                        return (
                                            <div key={index} className='row-view items-center mt10'>
                                                {matches?.img ?
                                                    <img src={match.img} alt={index} className='mr5' />
                                                    :
                                                    <div />
                                                }
                                                <div>{match.firstname} {match.lastname}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='column-view items-center justify-center p30'>
                            <img
                                src='https://storage.googleapis.com/mhm-medias/bo-hotels/Match_tracking.svg'
                                alt='Match_tracking'
                                className='icon w200'
                            />
                            <div className='mt20'>
                                <div className='bold text-center'>Matches tracking</div>
                                <div className='text-center'>See all my won and lost matches</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div >
    )
}