import img from '../../assets/images/profile-photo-round.png';

export const cells = [
  'Name',
  'Check-in',
  'Check-out',
  'Room',
  'Travelers',
  'Price',
  'Commissions',
  'State',
  // 'Hotel',
  'Booking number',
  'Control number'
]

export const arrivals = [
  {
    id: 1,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Done',
    reservationId: '33144345916',
  },
  {
    id: 2,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Done',
    reservationId: '33144345916',
  },
  {
    id: 3,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Pending',
    reservationId: '33144345916',
  },
  {
    id: 4,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Done',
    reservationId: '33144345916',
  },
  {
    id: 5,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Cancelled',
    reservationId: '33144345916',
  },
  {
    id: 6,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Pending',
    reservationId: '33144345916',
  }
];

export const departures = [
  {
    id: 1,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Done',
    reservationId: '33144345916',
  },
  {
    id: 2,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Done',
    reservationId: '33144345916',
  }
];

export const newBookings = [
  {
    id: 1,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Pending',
    reservationId: '33144345916',
  },
  {
    id: 2,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Pending',
    reservationId: '33144345916',
  },
  {
    id: 3,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Pending',
    reservationId: '33144345916',
  }
];

export const stayOvers = [
  {
    id: 1,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Done',
    reservationId: '33144345916',
  },
  {
    id: 2,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Done',
    reservationId: '33144345916',
  },
  {
    id: 3,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Done',
    reservationId: '33144345916',
  },
  {
    id: 4,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Done',
    reservationId: '33144345916',
  }
];

export const guestsRequests = [
  {
    id: 1,
    img: img,
    name: 'Harry Cover',
    checkIn: '28 Jul 2023',
    checkOut: '31 Jul 2023',
    roomType: 'Deluxe Room',
    guests: '4 guests',
    price: '€ 896.70',
    commission: '€ 806.40',
    state: 'Pending',
    reservationId: '33144345916',
  }
];

export const reservations = [
  {
    order_id: "5264356817",
    traveler_profile: {
      purpose: 'Business'
    },
    status: "COMPLETED",
    checkin: {
      date: "Fri, 07 Mar 2023",
      time: "3pm"
    },
    checkout: {
      date: "Sun, 09 Mar 2023",
      time: "12pm"
    },
    stay: "2",
    adults: "2",
    kids: "",
    advantages: [
      "View",
      "1 bathtub",
      "1 king size bed",
      "Breakfast included",
    ],
    room: {
      name: "Deluxe Room"
    },
    overral_information: [
      'Gluten free',
      'Seafood allergies',
      'Cyclist'
    ],
    requests: [
      'Gluten free',
      'View',
      'Silent room'
    ],
    minibar: [
      { article: 'Coca-cola', quantity: "6" },
      { article: 'Pear Liqueur', quantity: "1" },
      { article: 'Water bottles', quantity: "4" },
    ],
    music_list: [
      { title: 'Get Down On It', artist: "Kool & The Gang" },
      { title: 'Billie Jean', artist: "Michael Jackson" },
      { title: 'Believer', artist: "Imagine Dragons" },
    ],
    amount: "454",
    currency: "EUR"
  }
]