import React, { useEffect, useState } from 'react';
import noPictures from '../../../assets/images/NoPictures.svg'
import { Link } from 'react-router-dom';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { getHotelRoomList } from '../../../api/hotel_room';
import { Loader } from '../../../components/Generic/Loader';

export const HotelRooms = ({ hotelSub, setActiveTab, setActiveRoom }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [categories, setCategories] = useState(['All', 'Apartment', 'Bangalow', 'Chalet', 'Junior suite', 'Room', 'Suite', 'Villa'])
    const [rooms, setRooms] = useState(0);
    const [roomsCount, setRoomsCount] = useState(0);
    const [selectedRooms, setSelectedRooms] = useState('All');

    const handlePage = (page) => {
        setSelectedRooms(page)
    }

    const joinTags = (room) => {
        return [
            room?.accessibilityTags?.[0],
            room?.roomLocationTags?.[0],
            room?.roomViewTags?.[0],
            room?.bedTypeTags?.[0],
            room?.roomTypeTags?.[0],
            room?.mattressTypeTags?.[0],
            room?.pillowTypeTags?.[0],
            room?.extraTags?.[0],
            room?.tvPreferencesTags?.[0]
        ];
    };

    const renderRooms = () => {
        let renderList = []
        if (selectedRooms === 'All') {
            renderList = rooms
        } else {
            renderList = rooms?.filter(el => el?.categoryType === selectedRooms)
        }
        return (
            <div className="mt20">
                {renderList?.length > 0 ?
                    <>
                        {renderList?.map((room, index) => (
                            <div
                                key={index}
                                className='row-view border-container mb10 flex1 r8 cursor-pointer'
                                onClick={() => {
                                    setActiveRoom(room?.sub)
                                    setActiveTab(2)
                                }}
                            >
                                <div className='column-view flex4'>
                                    {room?.images?.length > 0 ?
                                        <img src={room?.images[0]} alt={`Room ${index}`} className='rl8' style={{ width: 400, height: 250, objectFit: 'cover' }} />
                                        :
                                        <img src={noPictures} alt={`Room ${index}`} className='rl8' style={{ width: 400, height: 250, objectFit: 'contain' }} />
                                    }
                                </div>
                                <div className='row-view flex1 justify-between p10'>
                                    <div className='column-view flex1'>
                                        <div className='size2 bold'>{room?.name}</div>
                                        <div className='f-size24 text-underline mt10'>Details & Services</div>
                                        <div className='row-view ml10 wrap mt10'>
                                            {joinTags(room).map((tag, tagIndex) => (
                                                <>
                                                    {tag &&
                                                        <div key={tagIndex} className='tag mr5 ml5 mt10 cursor-default' style={{ fontWeight: 'normal' }}>
                                                            {tag?.icon} {tag?.label}
                                                        </div>
                                                    }
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='self-end f-size24'>{room?.numberOfRooms} units</div>
                                </div>
                            </div>
                        ))}
                    </>
                    :
                    <div className='row-view border-container mb10 flex1 r8 cursor-pointer p20'>No {selectedRooms} yet...</div>
                }
            </div>
        )
    };

    useEffect(() => {
        getHotelRoomList(hotelSub, response => {
            if (response?.status === 'success') {
                setRooms(response?.content)
                setRoomsCount(response?.content?.length)
            }
            setIsLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {isLoading ?
                <Loader />
                :
                <>
                    <div className='row-view justify-between'>
                        <div>
                            <div className='size2 bold'>Categories</div>
                            <div className='size1 bold today'><span>{roomsCount} categories</span></div>
                        </div>
                        <div className='row-view justify-end'>
                            <Link
                                className='primary-button none-decoration-link row-view items-center'
                                onClick={() => setActiveTab(1)}
                            >
                                <AiOutlinePlusCircle color='white' size={20} className='mr10' />
                                <div>Add Category</div>
                            </Link>
                        </div>
                    </div>
                    <div className="row-view pb20 mt20 ml20">
                        <div className='row-view size2'>
                            {categories?.map((category, index) => {
                                return (
                                    <>
                                        {(category === 'All' || rooms?.filter(el => el?.categoryType === category)?.length !== 0) &&
                                            <div
                                                className={`row-view items-center mr50 cursor-pointer pv4 ${selectedRooms === category && 'black-text bold bb161616'}`}
                                                onClick={() => handlePage(category)}
                                                key={index}
                                            >
                                                <div className={`mr5`}>
                                                    <div>{category === 'All' ? rooms?.length : rooms?.filter(el => el?.categoryType === category)?.length} {category}</div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                )
                            })}
                        </div>

                    </div>

                    {renderRooms()}
                </>
            }
        </>
    )
}