import React from 'react';
import { MdCheckCircleOutline, MdClose } from 'react-icons/md';
import colors from '../../colors/colors';

export const CustomAlert = ({ message, handleClose }) => {
    return (
        <div className='row-view items-center bgWhite alert p20 r8'>
            <MdCheckCircleOutline className='mr10' size={24} color={colors.green} />
            <div className='bold mr10'>{message}</div>
            <MdClose size={20} className='cursor-pointer' onClick={() => handleClose()} />
        </div>
    );
}