import React, { useEffect, useRef, useState } from "react";

import logoTextblack from "../../assets/logos/logo-text-black.svg";
import { Link } from "react-router-dom";
import "./../../iltm2023.css";
import { BiSolidChevronDown } from "react-icons/bi";
import colors from "../../colors/colors";
import { MdClose } from "react-icons/md";
import { getUserListByRole } from "../../api/user";
import step1 from "../../assets/iltm2023/Step1.svg";
import { iltmUpdateUser } from "../../api/iltm";

export const SetProfile = ({
  setActiveSubTab,
  userContent,
  setUserContent,
  hotelContent,
}) => {
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    position: "",
    email: "",
    phone: [""],
  });

  useEffect(() => {
    setUser({
      ...user,
      ...userContent,
    });
  }, []);

  const handleInputText = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (user?.firstname === "" || user?.lastname === "" || user?.email === "") {
      alert(`Please fill the required fields in 'Your information' section!`);
      return;
    }
    iltmUpdateUser(user, user?.sub, (response) => {
      if (response?.status === "success") {
        setUserContent(response?.content);
        setActiveSubTab(1);
      } else {
        alert("Error");
      }
    });
  };

  return (
    <div
      className="column-view justify-between"
      style={{ padding: "2vw", height: "90vh" }}
    >
      <div className="flex02">
        <div className="column-view items-center flex03 mt20">
          <img
            src={logoTextblack}
            alt="logoTextWhite"
            style={{ width: "14rem" }}
          />
        </div>
        <div className="column-view items-center justify-center">
          <div className="mt20">
            <img src={step1} alt="step1" />
          </div>
          <div className="bold mt20">
            Hey {user?.firstname}, profile yourself
          </div>
          <div className="mt10">Verify your informations</div>
        </div>
      </div>
      <div className="flex1 column-view">
        <div className="border-container r8 mt20">
          <div className="row-view items-center justify-center p20 bb wrap">
            <div className="section-title flex04">First name*</div>
            <div className="row-view flex1 items-center">
              <input
                className="input-text"
                required
                name="firstname"
                placeholder="First name..."
                value={user?.firstname}
                onChange={(e) => handleInputText(e)}
              />
            </div>
          </div>
          <div className="row-view items-center justify-center p20 bb wrap">
            <div className="section-title flex04">Last name*</div>
            <div className="row-view flex1 items-center">
              <input
                className="input-text"
                required
                name="lastname"
                placeholder="Last name..."
                value={user?.lastname}
                onChange={(e) => handleInputText(e)}
              />
            </div>
          </div>
          <div className="row-view items-center justify-center p20 bb wrap">
            <div className="section-title flex04">Position*</div>
            <div className="row-view flex1 items-center">
              <input
                className="input-text"
                name="position"
                placeholder="Position..."
                value={user?.position}
                onChange={(e) => handleInputText(e)}
              />
            </div>
          </div>
          <div className="row-view items-center justify-center p20 bb wrap">
            <div className="section-title flex04">Hotel linked</div>
            <div className="row-view flex1 items-center wrap gap20">
              <div className="border-container bgFAFAFA row-view items-center justify-between r8 pv12 ph20 cursor-pointer">
                <div className="w200">{hotelContent?.name}</div>
              </div>
            </div>
          </div>
          <div className="row-view items-center p20 bb">
            <div className="section-title flex04">Email address*</div>
            <div className="row-view flex1 items-center">
              <input
                className="input-text"
                required
                type="email"
                name="email"
                placeholder="Email address..."
                value={user?.email}
                onChange={(e) => handleInputText(e)}
              />
            </div>
          </div>
          <div className="row-view items-center p20">
            <div className="section-title flex04">Phone number*</div>
            <div className="column-view flex1 justify-between">
              {user?.phone?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="row-view items-center gap20 wrap mb10"
                  >
                    <input
                      className="input-text"
                      name="phone"
                      placeholder="Phone number..."
                      value={item}
                      onChange={(e) => {
                        let phones = user?.phone;
                        phones[index] = e.target.value;
                        setUser({ ...user, phone: phones });
                      }}
                    />
                    {user?.phone?.length > 1 && (
                      <MdClose
                        className="cursor-pointer"
                        color={colors.primary}
                        size={25}
                        onClick={() => {
                          let res = user.phone;
                          res.splice(index, 1);
                          setUser({ ...user, phone: res });
                        }}
                      />
                    )}
                  </div>
                );
              })}
              <div className="row-view">
                <div
                  className="secondary-button borderless p12 grey-text"
                  onClick={() =>
                    setUser({ ...user, phone: [...user.phone, ""] })
                  }
                >
                  +Add phone
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row-view justify-center">
        <Link
          className="primary-button none-decoration-link row-view items-center mt50 ph100 mb50"
          style={{ borderRadius: "25px" }}
          onClick={() => handleSubmit()}
        >
          <div cla>Continue</div>
        </Link>
      </div>
    </div>
  );
};
