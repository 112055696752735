const getToken = async () => {
    return JSON.parse(localStorage.getItem('auth'));
};

export const getHotelAdvantageList = async (hotel_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/advantages/${hotel_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const updateHotelAdvantage = async (hotel_sub, advantages, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/advantages/${hotel_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "PATCH",
        body: JSON.stringify({ advantages })
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};