import React, { useState } from 'react';
import { NewHotel } from './NewHotel';
import { ThanksIltmAmadeus } from './ThanksIltmAmadeus';
import { ThanksIltmOther } from './ThanksIltmOther';

export const UnknownHotel = () => {
    const [activeSubTab, setActiveSubTab] = useState(0)
    return (
        <div>
            {activeSubTab === 0 && <NewHotel setActiveSubTab={setActiveSubTab} />}
            {activeSubTab === 1 && <ThanksIltmAmadeus />}
            {activeSubTab === 2 && <ThanksIltmOther />}
        </div>
    )
}