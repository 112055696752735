const useHotelApi = () => {
  const getToken = async () => JSON.parse(localStorage.getItem("auth"));

  const sendRequest = async (url, method, body, callback) => {
    const authToken = await getToken();
    let requestParams = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${authToken}`,
      },
      method,
    };
    if (method !== "GET" && method !== "DELETE") {
      requestParams.body = JSON.stringify(body);
    }
    fetch(url, requestParams)
      .then((response) => {
        if (!response.ok) {
          console.error(response);
        }
        return response.json();
      })
      .then((json) => {
        console.debug(json);
        callback(json);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const createHotel = (hotel, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels`;
    sendRequest(url, "POST", hotel, callback);
  };

  const requestHotel = (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/request/${sub}`;
    sendRequest(url, "PUT", null, callback);
  };

  const acceptRequestHotel = (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/request/accept/${sub}`;
    sendRequest(url, "PUT", null, callback);
  };

  const rejectRequestHotel = (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/request/reject/${sub}`;
    sendRequest(url, "PUT", null, callback);
  };

  const getHotelRequests = (callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/request/list`;
    sendRequest(url, "GET", null, callback);
  };

  const getNewHotelList = (callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/new/hotel/list`;
    sendRequest(url, "GET", null, callback);
  };

  const acceptNewHotel = (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/new/hotel/accept/${sub}`;
    sendRequest(url, "PUT", null, callback);
  };

  const rejectNewHotel = (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/new/hotel/reject/${sub}`;
    sendRequest(url, "PUT", null, callback);
  };

  const activeInactiveHotels = (hotels, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/active`;
    sendRequest(url, "PATCH", hotels, callback);
  };

  const getHotelListLazy = async (offset, limit, search, active, callback) => {
    let url = `${process.env.REACT_APP_API_URL}/hotels`;
    if (
      (offset !== undefined && offset !== "") ||
      (limit !== undefined && limit !== "")
    ) {
      url += "?";
      if (offset !== undefined && offset !== "") {
        url += `offset=${offset}`;
      }
      if (
        (offset === undefined || offset === "") &&
        limit === undefined &&
        limit === ""
      ) {
        url += `?limit=${limit}`;
      } else {
        url += `&limit=${limit}`;
      }
    }
    url += `&search=${search}`;
    if (active !== null && active !== undefined) url += `&active=${active}`;
    sendRequest(url, "GET", null, callback);
  };

  const searchHotels = async (search, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/search/${search}`;
    sendRequest(url, "GET", null, callback);
  };

  const getHotelMatches = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/matches/${sub}`;
    sendRequest(url, "GET", null, callback);
  };

  const getHotelTravelers = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/travelers/${sub}`;
    sendRequest(url, "GET", null, callback);
  };

  const getHotelReservations = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/reservations/${sub}`;
    sendRequest(url, "GET", null, callback);
  };

  const updateHotelContent = async (content, sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/${sub}`;
    sendRequest(url, "PATCH", content, callback);
  };

  const deleteHotel = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/${sub}`;
    sendRequest(url, "DELETE", null, callback);
  };

  const getHotelContent = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/content/${sub}`;
    sendRequest(url, "GET", null, callback);
  };

  const getHotel = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/${sub}`;
    sendRequest(url, "GET", null, callback);
  };

  const replaceHotelContent = async (content, sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/hotels/${sub}`;
    sendRequest(url, "PUT", content, callback);
  };

  return {
    createHotel,
    requestHotel,
    acceptRequestHotel,
    rejectRequestHotel,
    getHotelRequests,
    getNewHotelList,
    acceptNewHotel,
    rejectNewHotel,
    activeInactiveHotels,
    getHotelListLazy,
    searchHotels,
    getHotelMatches,
    getHotelTravelers,
    getHotelReservations,
    updateHotelContent,
    deleteHotel,
    getHotelContent,
    getHotel,
    replaceHotelContent,
  };
};

export default useHotelApi;
