

const getToken = async () => {
    return JSON.parse(localStorage.getItem('auth'));
}

export const createHotelRoom = async (hotel_sub, hotelRoom, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/rooms/${hotel_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "POST",
        body: JSON.stringify(hotelRoom)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getHotelRoomList = async (hotel_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/rooms/${hotel_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getHotelRoom = async (sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/rooms/room/${sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const updateHotelRoom = async (sub, hotelRoom, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/rooms/room/${sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "PATCH",
        body: JSON.stringify(hotelRoom)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const deleteHotelRoom = async (hotel_room_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/rooms/room/${hotel_room_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "DELETE",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};