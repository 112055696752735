import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

export const Messages = ({ messages, mySub, fetchMessages }) => {
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const bottomRef = useRef(null);

    useEffect(() => {
        console.log(fetchMessages)
        if (!fetchMessages)
            bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages])

    return (
        <div className=''>
            {messages?.map((message, index) => {
                return (
                    <div key={index} className={`row-view ph20 ${message?.sender === mySub && 'justify-end'}`}>
                        {message?.sender !== mySub ?
                            <div style={{ maxWidth: windowSize < 1200 ? "80%" : "60%", lineHeight: 1.4, letterSpacing: 1.2, marginBottom: 10 }}>
                                {(moment(message?.timestamp).diff(moment(messages[index - 1]?.timestamp), 'minutes') > 15 || moment(message?.timestamp).diff(moment(messages[index - 1]?.timestamp), 'minutes') < 0) && <div className='grey-text mt20'>{moment(message?.timestamp).format('LT')}</div>}
                                <div className='bgE9E9E9 p20 r8 mt5 break-word'>{message?.message}</div>
                            </div>
                            :
                            <div style={{ maxWidth: windowSize < 1200 ? "80%" : "60%", lineHeight: 1.4, letterSpacing: 1.2, marginBottom: 10 }}>
                                {(moment(message?.timestamp).diff(moment(messages[index - 1]?.timestamp), 'minutes') > 15 || moment(message?.timestamp).diff(moment(messages[index - 1]?.timestamp), 'minutes') < 0) && <div className='grey-text mt20'>{moment(message?.timestamp).format('LT')}</div>}
                                <div className='bgPrimary white-text p20 r8 mt5 break-word'>{message?.message}</div>
                            </div>
                        }
                    </div>
                )
            })}
            <div ref={bottomRef} />
        </div>
    )
}