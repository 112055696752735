const getToken = async () => {
    return JSON.parse(localStorage.getItem('auth'))
}

export const createOffer = async (hotel_sub, hotelOffer, callback) => {
    const authToken = await getToken()
    fetch(`${process.env.REACT_APP_API_URL}/hotel/offer/${hotel_sub}`, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${authToken}`,
        },
        method: 'POST',
        body: JSON.stringify(hotelOffer),
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response)
            }
            return response.json()
        })
        .then((json) => {
            console.debug(json)
            callback(json)
        })
        .catch((error) => {
            console.error(error)
        })
}

export const getHotelOffer = async (hotel_offer_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/offer/hotel_offer/${hotel_offer_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const updateHotelOffer = async (hotel_offer_sub, hotelOffer, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/offer/hotel_offer/${hotel_offer_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "PATCH",
        body: JSON.stringify(hotelOffer)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            callback(response.ok);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getHotelOfferList = async (hotel_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/offer/${hotel_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const deleteHotelOffer = async (hotel_offer_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotel/offer/hotel_offer/${hotel_offer_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "DELETE",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            callback(response.ok);
        })
        .catch((error) => {
            console.error(error);
        });
};
