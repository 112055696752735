import React, { useEffect, useRef, useState } from 'react'

import logoTextblack from '../../assets/logos/logo-text-black.svg'
import { Link } from 'react-router-dom'
import './../../iltm2023.css'
import { BiSolidChevronDown } from 'react-icons/bi'
import { iltmCheckUser, iltmSearchHotel } from '../../api/iltm'
import useHotelApi from "../../api/hotel";

export const HotelChoice = ({ setActiveTab, setUserContent, setHotelContent }) => {
    const { updateHotelContent, getHotel } = useHotelApi();
    const [hotelsOpen, setHotelsOpen] = useState(false)
    const [hotelsSearch, setHotelsSearch] = useState('')
    const [hotels, setHotels] = useState([])
    const [hotel, setHotel] = useState({})
    const [fullHotelContent, setFullHotelContent] = useState([]);
    const [user, setUser] = useState({
        firstname: '',
        lastname: '',
        email: '',
    })
    const hotelsRef = useRef(null)

    const retrieveHotels = (search) => {
        iltmSearchHotel(search, (response) => {
            if (response?.status === 'success') {
                setHotels(response?.content)
            }
        })
    }

    const handleInputText = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (hotelsOpen && hotelsRef.current && !hotelsRef.current.contains(event.target)) {
                setHotelsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        if (hotel?.sub) {
            getHotelContent(hotel?.sub);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [
        hotelsOpen,
        hotelsRef
    ]);

    const getHotelContent = (sub) => {
        if (sub) {
            getHotel(sub, (response) => {
                if (response?.status === "success") {
                    setFullHotelContent(response?.content);
                } else {
                }
            });
        }
    };

    const handleSubmit = () => {
        if (!hotel?.sub) {
            alert(`Please select your hotel or click on 'My hotel doesn't appear in the list'`)
            return
        }
        if (user?.firstname === '' || user?.lastname === '' || user?.email === '') {
            alert(`Please fill the required fields in 'Your information' section!`)
            return
        }

        iltmCheckUser(user, hotel?.sub, response => {

            if (response?.status === 'success') {

                let managers = [];
                managers = [...fullHotelContent?.manager.map((el) => el)];

                managers.push(response?.user?.sub)

                let newHotel = {
                    active: fullHotelContent?.active,
                    ...hotel,
                    manager: managers,
                };

                updateHotelContent(newHotel, hotel.sub, (response) => {
                    if (response?.status === "success") {
                    } else {
                        alert(response);
                    }
                });

                setHotelContent(hotel)
                setUserContent(response?.user)
                setActiveTab(2)
            } else {
                alert(response?.message)
            }
        })
    }

    return (
        <div className='column-view justify-between' style={{ padding: '2vw', height: '90vh' }}>
            <div className='flex02'>
                <div className="column-view items-center flex03 mt20">
                    <img
                        src={logoTextblack}
                        alt="logoTextWhite"
                        style={{ width: '14rem' }}
                    />
                </div>
            </div>
            <div className='flex1 column-view'>
                <div className="column-view items-center justify-center mt50">
                    <div className="bold">
                        To begin, which hotel are your connected to ?
                    </div>
                    <div className="mt10">Select your hotel</div>
                </div>
                <div className="subtitle mt50 ml50">Hotel information*</div>
                <div className="border-container r8 mt20">
                    <div className="row-view items-center justify-center p20 bb wrap">
                        <div className="section-title flex04">Hotel linked</div>
                        <div className="row-view flex1 items-center wrap gap20">
                            <div
                                className="relative w250"
                            >
                                {hotelsOpen ? (
                                    <input
                                        type="text"
                                        value={hotelsSearch}
                                        className="input-text "
                                        autoFocus
                                        onChange={(e) => {
                                            setHotelsSearch(e.target.value)
                                            retrieveHotels(e.target.value)
                                        }}
                                    />
                                ) : (
                                    <div
                                        className="border-container bgFAFAFA row-view items-center justify-between r8  pv12 ph20 cursor-pointer"
                                        onClick={() => setHotelsOpen(!hotelsOpen)}
                                    >
                                        <div>
                                            {hotel?.name
                                                ? hotel?.name
                                                : 'Select hotel'}
                                        </div>
                                        <BiSolidChevronDown />
                                    </div>
                                )}
                                <div
                                    className={`select-menu ${hotelsOpen && 'open'
                                        }`}
                                    style={{
                                        maxHeight: 300,
                                        overflow: 'scroll',
                                    }}
                                    ref={hotelsRef}
                                >
                                    {hotels?.length !== 0 ? (
                                        <>
                                            {hotels?.map((h, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="select-item"
                                                        onClick={() => {
                                                            setHotel(h)
                                                            setHotelsOpen(false)
                                                        }}
                                                    >
                                                        <span className="section-title">
                                                            {h?.name}
                                                            <span className="grey-text">
                                                                {' '}
                                                                - {h?.address?.city}
                                                            </span>
                                                        </span>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            <div className="select-item">
                                                No hotels found
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <Link
                                className="text-underline cursor-pointer"
                                style={{ borderRadius: '25px' }}
                                onClick={() => setActiveTab(3)}
                            >

                                My hotel doesn't appear in the list
                            </Link>
                        </div>
                    </div>
                    <div className="row-view items-center p20 wrap">
                        <div className="section-title flex04">Address</div>
                        <div className="row-view items-center flex1">
                            <div className="row-view wrap gap20">
                                <input
                                    className="input-text"
                                    name="address"
                                    placeholder="Address..."
                                    value={hotel?.address?.street}
                                    onChange={(e) =>
                                        setHotel({
                                            ...hotel,
                                            address: {
                                                ...hotel.address,
                                                street: e.target.value,
                                            },
                                        })
                                    }
                                />
                                <input
                                    className="input-text"
                                    name="city"
                                    placeholder="City..."
                                    value={hotel?.address?.city}
                                    onChange={(e) =>
                                        setHotel({
                                            ...hotel,
                                            address: {
                                                ...hotel.address,
                                                city: e.target.value,
                                            },
                                        })
                                    }
                                />
                                <input
                                    className="input-text"
                                    name="zipCode"
                                    placeholder="Zip code..."
                                    value={hotel?.address?.zipCode}
                                    onChange={(e) =>
                                        setHotel({
                                            ...hotel,
                                            address: {
                                                ...hotel.address,
                                                zipCode: e.target.value,
                                            },
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="subtitle ml50 mt50">Your information*</div>
                <div className="border-container r8 mt20">
                    <div className="row-view items-center p20 bb">
                        <div className="section-title flex04">First name*</div>
                        <div className="row-view flex1 items-center">
                            <input
                                className="input-text"
                                required
                                name="firstname"
                                placeholder="First name..."
                                value={user?.firstname}
                                onChange={(e) => handleInputText(e)}
                            />
                        </div>
                    </div>
                    <div className="row-view items-center p20 bb">
                        <div className="section-title flex04">Last name*</div>
                        <div className="row-view flex1 items-center">
                            <input
                                className="input-text"
                                required
                                name="lastname"
                                placeholder="Last name..."
                                value={user?.lastname}
                                onChange={(e) => handleInputText(e)}
                            />
                        </div>
                    </div>
                    <div className="row-view items-center p20">
                        <div className="section-title flex04">Email address*</div>
                        <div className="row-view flex1 items-center">
                            <input
                                className="input-text"
                                required
                                type="email"
                                name="email"
                                placeholder="Email address..."
                                value={user?.email}
                                onChange={(e) => handleInputText(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row-view justify-center">
                <button
                    className="primary-button none-decoration-link row-view items-center mt50 ph100 mb50"
                    style={{ borderRadius: '25px' }}
                    onClick={() => handleSubmit()}
                >
                    <div cla>Continue</div>
                </button>
            </div>
        </div>
    )
}
