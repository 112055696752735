import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';

import { MdSearch } from 'react-icons/md'
import { TbFilterPlus } from 'react-icons/tb'
import { Table } from '../../components/Table/Table'
import { getUserList } from '../../api/user'
import '../Offers/Offers.css'
import TableFooter from '../../components/Generic/TableFooter'
import useHotelApi from '../../api/hotel';
import { BiSolidChevronDown } from 'react-icons/bi';

const cells = [
    'Name',
    'Email',
    'Phone',
    'Last reservation',
    'State',
    'Amount (€)',
]

export const Travelers = ({ handleLogout }) => {
    const { getHotelListLazy, getHotelTravelers } = useHotelApi();
    const [currentPageData, setCurrentPageData] = useState(1)
    const [currentPageCountData, setCurrentPageCountData] = useState(5)
    const [travelers, setTravelers] = useState([])
    const [hotelsSearch, setHotelsSearch] = useState('');
    const [hotel, setHotel] = useState([])
    const [hotels, setHotels] = useState([])
    const [hotelsOpen, setHotelssOpen] = useState(false)
    const hotelsRef = useRef(null);

    const handlePaginationIncrement = () => {
        setCurrentPageData(currentPageData + 1)
    };

    const handlePaginationDecrement = () => {

        setCurrentPageData(
            currentPageData === 1
                ? currentPageData
                : currentPageData - 1
        )
    };

    const handlePerPageDropdownChange = (e) => {
        setCurrentPageCountData(e.target.value);

    };



    const amount = (item) => {
        return parseInt(item.amount);
    }

    const sum = (prev, next) => {
        return prev + next;
    }

    const formatData = (data) => {
        let res = []
        data.map((traveler) => {
            return res.push({
                id: traveler?.sub,
                img: traveler.photo || null,
                name: `${traveler?.firstname} ${traveler?.lastname}` || '',
                email: traveler?.email || '',
                phone: traveler?.phone || '',
                lastReservation: `${moment(traveler?.lastReservation?.checkin).format('ddd, DD MMM YYYY')}-${traveler?.lastReservation?.state}` || '___',
                state: traveler?.lastReservation?.state ?? '___',
                amount: `${traveler?.lastReservation?.amount}€` ?? '___',
            })
        })
        console.log(res)
        return res
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (hotelsOpen && hotelsRef.current && !hotelsRef.current.contains(event.target)) {
                setHotelssOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [
        hotelsOpen,
        hotelsRef
    ]);

    const retrieveHotels = (search) => {
        getHotelListLazy(0, 10, search, undefined, response => {
            if (response?.status === 'success') {
                let hotels = [{ name: 'All Hotels' }, ...response?.content?.hotels]
                setHotels(hotels)
                setHotel(response?.content?.hotels?.[0])
            }
        })
    }

    useEffect(() => {
        retrieveHotels(hotelsSearch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let token = localStorage.getItem('auth')
        if (token) {
            const expiration = JSON.parse(localStorage.getItem('expiration'))
            if (expiration < Date.now() / 1000) {
                handleLogout()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleLogout])

    useEffect(() => {
        getHotelTravelers(hotel?.sub, response => {
            setTravelers(formatData(response.content))
        })
    }, [hotel])

    return (
        <div className="page-container">
            <div className="row-view justify-between">
                <div>
                    <div className="size2 bold">Travelers</div>
                    <div className="size1 bold today">
                        <span>{travelers.length} travelers</span>
                    </div>
                </div>
                {/* <div className="row-view">
                    <div className="row-view">
                        <button className="secondary-button borderless row-view items-center">
                            <div className="mr5">
                                <TbFilterPlus />
                            </div>
                            <div>Filter</div>
                        </button>
                        <div className="secondary-search-bar-container row-view items-center justify-center">
                            <div className="mr10">
                                <MdSearch size={20} />
                            </div>
                            <input
                                type="text"
                                placeholder="Search travelers"
                                className="secondary-search-bar"
                            />
                        </div>
                    </div>
                </div> */}
            </div>

            <div className='relative' style={{ paddingTop: "2%", paddingBottom: "2%", display: "inline-block" }}>
                {hotelsOpen ?
                    <input
                        type='text'
                        value={hotelsSearch}
                        className='input-text w300'
                        autoFocus
                        onChange={(e) => {
                            setHotelsSearch(e.target.value)
                            retrieveHotels(e.target.value)
                        }}
                    />
                    :
                    <div
                        className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer'
                        onClick={() => setHotelssOpen(!hotelsOpen)}
                    >
                        <div>
                            {hotel?.name
                                ? hotel?.name
                                : 'Select hotel'}
                        </div>
                        <BiSolidChevronDown />
                    </div>
                }
                <div className={`select-menu ${hotelsOpen && 'open'}`} style={{ maxHeight: 300, overflow: "scroll" }} ref={hotelsRef}>
                    {hotels?.length !== 0 ? (
                        <>
                            {hotels?.map((h, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='select-item'
                                        onClick={() => {
                                            setHotel(h)
                                            setHotelssOpen(false)
                                        }}
                                    >
                                        <span className='section-title'>
                                            {h?.name}<span className='grey-text'> - {h?.address?.city}</span>
                                        </span>
                                    </div>
                                )
                            })}
                        </>
                    ) : (
                        <>
                            <div className='select-item'>
                                No hotels found
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="mt50 of-scroll">
                <Table cells={cells} data={travelers.length !== 0 ? travelers : 'No travelers yet...'} type='travelers' />
            </div>

            {/* <div className="row-view items-center justify-between mt50 ">
                <div className="row-view items-center">
                    <button className="secondary-button row-view p10 mr10">
                        <div className="mr5">25</div>
                        <PiCaretUpDownLight />
                    </button>
                    <div className="section-title">profile per page</div>
                </div>
                <div className="pagination">
                    <div
                        className="pagination--item"
                        onClick={() =>
                            setCurrentPageData(
                                currentPageData === 0
                                    ? currentPageData
                                    : currentPageData - 1
                            )
                        }
                    >
                        <img src={arrowLeft} alt="dashboard" className="icon" />
                    </div>
                    <div
                        className="pagination--item"
                        onClick={() => setCurrentPageData(currentPageData + 1)}
                    >
                        <img
                            src={arrowRight}
                            alt="dashboard"
                            fill="red"
                            className="icon pagination--color"
                        />
                    </div>
                </div>
            </div> */}

            {/* <TableFooter handlePaginationDecrement={handlePaginationDecrement} currentPageData={currentPageData}
                handlePaginationIncrement={handlePaginationIncrement} selectedOption={currentPageCountData} handlePerPageDropdownChange={handlePerPageDropdownChange} footerName={'Travellers'} /> */}

        </div>
    )
}
