import React, { useEffect, useRef, useState } from 'react'
import addImg from '../../../assets/icons/AddImgOrange.svg'
import { BiSolidChevronDown } from 'react-icons/bi'
import { BsFillQuestionCircleFill } from 'react-icons/bs'
import { MdClose, MdCheck, MdChevronLeft } from 'react-icons/md'
import colors from '../../../colors/colors'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { DtCalendar } from 'react-calendar-datetime-picker'
import { createService, deleteHotelService, getHotelService, updateHotelService } from '../../../api/hotel_service'
import { CustomAlertConfirm } from '../../../components/Generic/CustomAlertConfirm'
import { listServices } from '../../../utils/mock/HotelData'

export const ServiceTab = ({ serviceSub, setActiveTab, setActiveService }) => {
    const [myRoles] = useState(JSON.parse(localStorage.getItem('my_roles')))
    const hiddenFileInput = React.useRef(null);
    const [photoInput, setPhotoInput] = useState(null);
    const [serviceOpen, setServiceOpen] = useState(false)
    const [infoModal, setInfoModal] = useState(false)
    const serviceRef = useRef(null)
    const [newDate, setNewDate] = useState(null)
    const [showAlert, setShowAlert] = useState(false);

    const currentDate = moment();

    // Utilise la date formatée comme bon te semble dans ton composant
    const minDate = {
        year: parseInt(currentDate.format('YYYY')),
        month: parseInt(currentDate.format('MM')),
        day: parseInt(currentDate.format('DD'))
    };

    const [service, setService] = useState({
        active: false,
        images: [],
        dates: [],
        times: []
    })

    const handleInputPhoto = async (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = async () => {
                const img = new Image();
                img.src = reader.result;

                img.onload = async () => {
                    const width = img.width;
                    const height = img.height;
                    const isPortrait = width < height;
                    const rotatedImageDataURL = isPortrait ? await rotateImage(reader.result) : reader.result;
                    const compressedImageDataURL = await compressImage(rotatedImageDataURL, 0.4);
                    setService({
                        ...service,
                        images: [...service?.images ?? [], compressedImageDataURL]
                    })
                    setPhotoInput(null)
                };
            };

            reader.readAsDataURL(file);
        }
    };

    const rotateImage = async (imageUrl) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.height;
                canvas.height = img.width;

                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate((90 * Math.PI) / 180);
                ctx.drawImage(img, -img.width / 2, -img.height / 2);

                resolve(canvas.toDataURL('image/jpeg', 0.8));
            };
        });
    };

    const compressImage = async (imageUrl, quality) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                canvas.toBlob((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(blob);
                }, 'image/jpeg', quality);
            };
        });
    };

    const handlePhoto = (key) => {
        hiddenFileInput.current.click();
        setPhotoInput(key);
    };

    const handleInputText = (e) => {
        setService({ ...service, [e.target.name]: e.target.value })
    }

    const handleSubmit = () => {
        if (!service?.service || service?.service === '') {
            alert('Select a service type!');
            return
        }
        let dates = [
            moment(newDate?.from).format(),
            moment(newDate?.to).format()
        ]
        let newService = {
            ...service,
            dates: dates
        }
        updateHotelService(serviceSub, newService, response => {
            if (response?.status === 'success') {
                setActiveTab(0)
                setActiveService('')
            } else {
                alert(response?.message)
            }
        })
    }

    const handleDelete = () => {
        deleteHotelService(serviceSub, response => {
            if (response?.status === 'success') {
                setActiveTab(0)
                setActiveService('')
            } else {
                alert(response?.message)
            }
        })
    }

    useEffect(() => {
        getHotelService(serviceSub, response => {
            if (response?.status === 'success') {
                setService(response?.content)
                setNewDate({
                    from: {
                        day: moment(response?.content?.dates?.[0]).day(),
                        hour: moment(response?.content?.dates?.[0]).hour(),
                        minute: moment(response?.content?.dates?.[0]).minute(),
                        month: moment(response?.content?.dates?.[0]).month(),
                        year: moment(response?.content?.dates?.[0]).year()
                    },
                    to: {
                        day: moment(response?.content?.dates?.[1]).day(),
                        hour: moment(response?.content?.dates?.[1]).hour(),
                        minute: moment(response?.content?.dates?.[1]).minute(),
                        month: moment(response?.content?.dates?.[1]).month(),
                        year: moment(response?.content?.dates?.[1]).year()
                    }
                })
            }
        })
    }, [])

    useEffect(() => {
        function handleClickOutside(event) {
            if (serviceOpen && serviceRef.current && !serviceRef.current.contains(event.target)) {
                setServiceOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [
        serviceOpen,
        serviceRef
    ]);

    return (
        <div className="column-view">
            {showAlert && (
                <div
                    className='row-view filter-darker items-center justify-center'
                    onClick={() => {
                        if (showAlert) {
                            setShowAlert(false)
                        }
                    }}
                >
                    <CustomAlertConfirm
                        title="Do you want to proceed?"
                        message="By continuing the service will be permanently deleted"
                        onConfirm={() => handleDelete()}
                        onCancel={() => setShowAlert(false)}
                    />
                </div>
            )}
            <div className="row-view items-center">
                <MdChevronLeft
                    size={50}
                    className="cursor-pointer"
                    onClick={() => setActiveTab(0)}
                />
                <div className="title-secondary">{service?.name}</div>
            </div>

            <div className="column-view flex1">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <div className="subtitle mt50 ml50">General information*</div>
                    <div className="border-container r8 mt20">
                        <div className="row-view items-center p20 bb">
                            <div className='section-title flex04'>Select service*</div>
                            <div className='row-view flex1 items-center ml20'>
                                <div className='relative'>
                                    <div
                                        className='border-container bgFAFAFA row-view items-center justify-between r8 w300 pv12 ph20 cursor-pointer'
                                        onClick={() => setServiceOpen(!serviceOpen)}
                                    >
                                        <div className='row-view items-center size1 gap20 bold'>
                                            {service?.service && service?.service !== '' ? (
                                                <>
                                                    <img src={listServices?.filter(el => el?.name === service?.service)?.[0]?.icon} alt='ServiceIcon' />
                                                    <span>{service.service}</span>
                                                </>
                                            ) : (
                                                'Select service'
                                            )}
                                        </div>
                                        <BiSolidChevronDown />
                                    </div>
                                    <div className={`select-menu ${serviceOpen && 'open'}`} style={{ maxHeight: 300, overflow: "scroll" }}>
                                        {listServices?.length !== 0 ?
                                            <>
                                                {listServices?.map((s, index) => (
                                                    <div
                                                        key={index}
                                                        className={`select-item row-view`}
                                                        onClick={() => {
                                                            setService({ ...service, service: s.name })
                                                            setServiceOpen(!serviceOpen)
                                                        }}
                                                    >
                                                        <span className='section-title row-view items-center gap20 size1'>
                                                            <img src={s?.icon} alt={s?.name} /> {s?.name}
                                                        </span>
                                                    </div>
                                                ))}</>
                                            :
                                            <>
                                                <div
                                                    className='select-item'
                                                >
                                                    No service found
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row-view items-center p20 bb">
                            <div className="section-title flex04">Profile name*</div>
                            <div className="row-view items-center ml20 flex1">
                                <div>
                                    <input
                                        className="input-text w300"
                                        name="name"
                                        placeholder="Profile name..."
                                        value={service?.name}
                                        onChange={(e) => {
                                            handleInputText(e)
                                        }}
                                    />
                                </div>
                                <div
                                    className={`checkbox cursor-pointer r4 ml20 mr10 ${service?.active && 'selected'}`}
                                    onClick={() => {
                                        if (myRoles?.includes('admin'))
                                            setService({ ...service, active: !service.active })
                                    }}
                                >
                                    {service?.active && <MdCheck color='white' />}
                                </div>
                                <div className='bold mr10'>Set active</div>
                                <div className='relative row-view items-center'>
                                    <BsFillQuestionCircleFill color={colors.primary} size={20} onClick={() => setInfoModal(!infoModal)} />
                                    <div className={`info-modal ${infoModal && 'active'}`}>
                                        Set <span className='green-text bold'>"The hotel service"</span> active.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row-view items-center p20 bb">
                            <div className="section-title flex04">
                                Surface area
                            </div>
                            <div className="row-view flex1 items-center ml20">
                                <input
                                    className="input-text"
                                    name="size"
                                    placeholder="Surface..."
                                    value={service?.size}
                                    onChange={(e) => handleInputText(e)}
                                />
                            </div>
                        </div>
                        <div className="row-view items-center p20 bb">
                            <div className="section-title flex04">
                                About the room*
                            </div>
                            <div className="row-view flex1 ml20">
                                <textarea
                                    className="input-text flex1"
                                    rows={10}
                                    value={service?.description}
                                    name="description"
                                    placeholder="About the room..."
                                    onChange={(e) => handleInputText(e)}
                                />
                            </div>
                        </div>
                        <div className="row-view items-center p20 bb">
                            <div className="section-title flex04">Pictures*</div>
                            <div className='flex1 ofx-hidden'>
                                <div className='row-view flex1 items-center ofx-scroll'>
                                    {service?.images?.map((image, index) => (
                                        <div className='relative p10'>
                                            <img
                                                width={250}
                                                height={150}
                                                style={{ objectFit: 'cover' }}
                                                alt={index}
                                                key={index}
                                                src={image}
                                                className='r8'
                                            />
                                            <div
                                                className="close-icon"
                                                onClick={() => {
                                                    let imgs = service?.images
                                                    imgs.splice(index, 1)
                                                    setService({
                                                        ...service,
                                                        imgaes: imgs,
                                                    })
                                                }}
                                            >
                                                <MdClose size={20} color="white" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className={`row-view items-center gap20 ${service?.images?.length > 0 && 'mt20'}`}>
                                    <div
                                        className="row-view border-container b-dashed b2 r8 p20 cursor-pointer items-center justify-center"
                                        onClick={() => handlePhoto('images')}
                                    >
                                        <img src={addImg} alt="addImg" />
                                    </div>
                                    <div className="section-subtitle">
                                        (JPGs or PNGs of at least 1080x566 px)
                                    </div>
                                </div>
                            </div>
                        </div>

                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            ref={hiddenFileInput}
                            onChange={(e) => handleInputPhoto(e)}
                            style={{ display: 'none' }}
                        />
                        <div className="row-view items-center p20">
                            <div className="section-title flex04">
                                Dates & Opening hours*
                            </div>
                            <div className="row-view flex1 items-center ml20 w100" style={{ zIndex: 0 }}>
                                <DtCalendar minDate={minDate} value={newDate} type={'range'} withTime={true} onChange={setNewDate}
                                    inputClass='custom-input'
                                    daysClass='custom-days'
                                    headerClass='custom-header'
                                    autoClose={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row-view justify-between mt20">
                        <button className="primary-button bgRed" type="button" onClick={() => setShowAlert(true)}>
                            Delete
                        </button>
                        <div>
                            <button
                                className="secondary-button mr20"
                                type="button"
                                onClick={() => {
                                    setActiveTab(0)
                                    setActiveService('')
                                }}
                            >
                                Cancel
                            </button>
                            <button className="primary-button" type="submit">
                                Save
                            </button>
                        </div>
                    </div>
                </form >
            </div>
        </div>
    )
}
