const getToken = async () => {
    return JSON.parse(localStorage.getItem('auth'))
}

export const createRestaurant = async (hotel_sub, hotelRestaurant, callback) => {
    const authToken = await getToken()
    fetch(`${process.env.REACT_APP_API_URL}/hotels/restaurants/${hotel_sub}`, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${authToken}`,
        },
        method: 'POST',
        body: JSON.stringify(hotelRestaurant),
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response)
            }
            return response.json()
        })
        .then((json) => {
            console.debug(json)
            callback(json)
        })
        .catch((error) => {
            console.error(error)
        })
}

export const getHotelRestaurantList = async (hotel_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/restaurants/${hotel_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};
export const getHotelRestaurant = async (hotel_restaurant_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/restaurants/restaurant/${hotel_restaurant_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const updateHotelRestaurant = async (hotel_restaurant_sub, hotelRestaurant, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/restaurants/restaurant/${hotel_restaurant_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "PATCH",
        body: JSON.stringify(hotelRestaurant)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const deleteHotelRestaurant = async (hotel_restaurant_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/restaurants/restaurant/${hotel_restaurant_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "DELETE",
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};
