const getToken = async () => {
    return JSON.parse(localStorage.getItem('auth'));
}

export const getAllReservations = async (callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/reservation`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getReservationListLazy = async (offset, limit, callback) => {
    const authToken = await getToken();
    let url = `${process.env.REACT_APP_API_URL}/reservation/lazy`
    if ((offset !== undefined && offset !== '') || (limit !== undefined && limit !== '')) {
        url += '?'
        if (offset !== undefined && offset !== '') {
            url += `offset=${offset}`
        }
        if ((offset === undefined || offset === '') && (limit === undefined && limit === '')) {
            url += `?limit=${limit}`
        } else {
            url += `&limit=${limit}`
        }
    }
    fetch(url, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getReservationContent = async (reservation_sub, callback) => {
    const authToken = await getToken();
    fetch(`${process.env.REACT_APP_API_URL}/hotels/reservation/${reservation_sub}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authToken}`
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};