/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { BiSolidChevronDown } from 'react-icons/bi';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { MdAccountCircle, MdCheck, MdCheckCircle, MdClose, MdRestaurant, MdSend } from 'react-icons/md';
import { HiClock } from 'react-icons/hi2';
import { FiRefreshCw } from 'react-icons/fi';
import hotelIcon from '../../assets/icons/Hotel.svg';
import { sendInvite } from '../../api/user';
import colors from '../../colors/colors';
import moment from 'moment';
import useHotelApi from '../../api/hotel';

const roles = ['Manager', 'Accountant', 'Staff'];

const handleSelect = (key, value) => {
    // setStaff({ ...staff, [key]: [value] });
};

export const Table = ({ cells, data, type, edit, currentPage, retrieveData, setActiveTab, setActiveItem, updates, setUpdates }) => {
    const { acceptRequestHotel, rejectRequestHotel, acceptNewHotel, rejectNewHotel } = useHotelApi();
    const [rolesOpen, setRolesOpen] = useState(false);
    const [sub, setSub] = useState(null);
    const [renderCells, setRenderCells] = useState([]);
    const [renderData, setRenderData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isAnimation, setAnimation] = useState(false);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        setRenderCells(cells);
        setRenderData(data);
        if (type === 'hotels') {
            if (edit) {
                setRenderCells([...cells, '']);
                let arr = renderData;
                arr.map((el) => {
                    el['edit'] = '';
                });
                setRenderData(arr);
            } else {
                setRenderCells(cells);
                setRenderData(data);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cells, data, edit, type]);

    const handleEditHotel = (el) => {
        if (updates?.filter(_ => _?.sub === el?.id)?.length > 0) {
            let res = updates;
            res = res.filter(_ => _?.sub !== el?.id);
            setUpdates(res);
        } else {
            setUpdates([...updates, { sub: el?.id, active: !el?.active }]);
        }
    };

    return (
        <div style={{ border: '1px solid #F6F6F6', borderRadius: 8 }}>
            <table className="table">
                <thead>
                    <tr>
                        {renderCells.map((cell, index) => {
                            return <th key={index}>{cell}</th>;
                        })}
                    </tr>
                </thead>
                {typeof (renderData) === 'object' ?
                    <tbody>
                        {renderData.map((el, idx) => (
                            <tr
                                key={idx}
                                style={type === 'hotels' && updates?.filter(_ => _?.sub === el.id).length > 0 ? { backgroundColor: '#F6F6F6' } : { backgroundColor: '#FFFFFF' }}
                            >
                                {Object.entries(el).map(([key, value], index) => {
                                    if (key === 'sent') { } else if (key === 'img') {
                                        switch (type) {
                                            case 'reservations':
                                                return (
                                                    <td key={index} className='tr'>
                                                        <Link
                                                            className="row-view items-center table-link black-text p4"
                                                            to={`/reservations/${el.id}`}
                                                            state={{ currentPage: currentPage ? currentPage : 0 }}
                                                        >
                                                            {value !== null ?
                                                                <img
                                                                    src={value}
                                                                    alt="img"
                                                                    className="r100 mr10"
                                                                    style={{ width: 30, height: 30, objectFit: 'cover' }}
                                                                ></img>
                                                                :
                                                                <MdAccountCircle color='#999999' size={30} className='mr10' />
                                                            }
                                                            {el.name}
                                                        </Link>
                                                    </td>
                                                );
                                            case 'hotels':
                                                return (
                                                    <td key={index} className='tr'>
                                                        <Link
                                                            className="row-view items-center table-link black-text p4"
                                                            to={`/hotels/profile/${el.id}`}
                                                        >
                                                            {value !== null ?
                                                                <img
                                                                    src={value}
                                                                    alt="img"
                                                                    className="r100 mr10"
                                                                    style={{ width: 30 }}
                                                                ></img>
                                                                :
                                                                <img
                                                                    src={hotelIcon}
                                                                    alt="hotelIcon"
                                                                    className="mr10"
                                                                    style={{ width: 30 }}
                                                                ></img>
                                                            }
                                                            {el.name}
                                                        </Link>
                                                    </td>
                                                );
                                            case 'staff':
                                                return (
                                                    <td key={index} className='tr'>
                                                        <Link
                                                            className="row-view items-center table-link black-text p4"
                                                            to={`/staff/profile/${el.id}`}
                                                            state={{ role: el.role }}
                                                        >
                                                            {value !== null ?
                                                                <img
                                                                    src={value}
                                                                    alt="img"
                                                                    className="r100 mr10"
                                                                ></img>
                                                                :
                                                                <MdAccountCircle color='#999999' size={30} className='mr10' />
                                                            }
                                                            <div className='w200 ellipsis'>{el.name}</div>
                                                        </Link>
                                                    </td>
                                                );
                                            case 'Requested':
                                                return (
                                                    <td key={index} className=''>
                                                        <div className='ellipsis bold'>{el.name}</div>
                                                    </td>
                                                );
                                            case 'travelers':
                                                return (
                                                    <td key={index} className='tr'>
                                                        <Link
                                                            className="row-view items-center table-link black-text p4"
                                                            to={`/travelers/profile/${el.id}`}
                                                        >
                                                            {value !== null ?
                                                                <img
                                                                    src={value}
                                                                    alt="img"
                                                                    className="mr10"
                                                                    style={{ width: 30, height: 30, objectFit: 'cover', borderRadius: 100 }}
                                                                ></img>
                                                                :
                                                                <MdAccountCircle color='#999999' size={30} className='mr10' />
                                                            }
                                                            {el.name}
                                                        </Link>
                                                    </td>
                                                );
                                            case 'profiles':
                                                return (
                                                    <td key={index} className='tr'>
                                                        <Link
                                                            className="row-view items-center table-link black-text p4"
                                                            onClick={() => {
                                                                setActiveTab(2);
                                                                setActiveItem(el.id);
                                                            }}
                                                        >
                                                            {value !== null ?
                                                                <img
                                                                    src={value}
                                                                    alt="img"
                                                                    className="r100 mr10"
                                                                    style={{ width: 30 }}
                                                                ></img>
                                                                :
                                                                <MdAccountCircle color='#999999' size={30} className='mr10' />
                                                            }
                                                            {el.name}
                                                        </Link>
                                                    </td>
                                                );
                                            case 'services':
                                                return (
                                                    <td key={index} className='tr' style={{ minWidth: '350px' }}>
                                                        <Link
                                                            className="row-view items-center table-link black-text p4"
                                                            onClick={() => {
                                                                setActiveTab(2);
                                                                setActiveItem(el.id);
                                                            }}
                                                        >
                                                            {value !== null ?
                                                                <img
                                                                    src={value}
                                                                    alt="img"
                                                                    className="r100 mr10"
                                                                    style={{ width: 50 }}
                                                                ></img>
                                                                :
                                                                <MdAccountCircle color='#999999' size={30} className='mr10' />
                                                            }
                                                            {el.name}
                                                        </Link>
                                                    </td>
                                                );
                                            case 'restaurants':
                                                return (
                                                    <td key={index} className='tr'>
                                                        <Link
                                                            className="row-view items-center table-link black-text p4"
                                                            onClick={() => {
                                                                setActiveTab(2);
                                                                setActiveItem(el.id);
                                                            }}
                                                        >
                                                            {value !== null ?
                                                                <img
                                                                    src={value}
                                                                    alt="img"
                                                                    className="r100 mr10"
                                                                    style={{ width: 50, height: 50 }}
                                                                ></img>
                                                                :
                                                                <MdRestaurant color='#999999' size={30} className='mr10' />
                                                            }
                                                            {el.name}
                                                        </Link>
                                                    </td>
                                                );
                                            default:
                                                return (
                                                    <td
                                                        className="row-view items-center table-link black-text tr"
                                                        key={index}
                                                    >
                                                        <div className="p4">
                                                            {value !== null && (
                                                                <img
                                                                    src={value}
                                                                    alt="img"
                                                                    className="r100 mr10"
                                                                ></img>
                                                            )}
                                                            {el.name}
                                                        </div>
                                                    </td>
                                                );
                                        }
                                    } else if (key === 'active' && type === 'hotels') {
                                        return (
                                            <td key={index}>
                                                {value ? (
                                                    <div className=''>
                                                        <AiOutlineCheckCircle
                                                            size={30}
                                                            color={colors.primary}
                                                            className="p4 "
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className=''>

                                                        <AiOutlineCloseCircle
                                                            size={30}
                                                            color={colors.secondary}
                                                            className="p4"
                                                        />
                                                    </div>
                                                )}
                                            </td>
                                        );
                                    } else if (key !== 'name' && key !== 'id' && key !== 'active') {
                                        if (typeof value === 'boolean') {
                                            if (key === 'invited') {
                                                return;
                                            } else if (key === 'verified') {
                                                return (
                                                    <td key={index}>
                                                        {value ?
                                                            <div className='row-view items-center'>
                                                                <MdCheckCircle size={25} color={colors.green} className='mr5' />
                                                                <div className='column-view'>
                                                                    <span className='bold'>Registered</span>
                                                                    <span className='grey-text'>{el.created_at}</span>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div>
                                                                {el.invited ?
                                                                    <div className='row-view items-center'>
                                                                        <HiClock size={25} color={colors.yellow} className='mr5' />
                                                                        <div className='column-view'>
                                                                            <span className='bold'>Sent</span>
                                                                            <span className='grey-text'>{el.created_at}</span>
                                                                            <div className='row-view items-center cursor-pointer'
                                                                                onClick={() => {
                                                                                    setSelected(el.id);
                                                                                    setIsLoading(true);
                                                                                    if (!isLoading) {
                                                                                        sendInvite({ email: el?.email }, response => {
                                                                                            setIsLoading(false);
                                                                                            setSelected(null);
                                                                                            if (response?.status === 'success') {
                                                                                                retrieveData();
                                                                                            } else {
                                                                                                alert('Error');
                                                                                            }
                                                                                        });
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <span className='primary-text-color bold mr5'>Resend</span>
                                                                                <FiRefreshCw color={colors.primary} className={`${isLoading && 'spin'}`} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div
                                                                        className='primary-button row-view items-center justify-center p12 r100'
                                                                        onClick={() => {
                                                                            setSelected(el.id);
                                                                            setIsLoading(true);
                                                                            if (!isLoading) {
                                                                                sendInvite({ email: el?.email }, response => {
                                                                                    setIsLoading(false);
                                                                                    if (response?.status === 'success') {
                                                                                        setAnimation(true);
                                                                                        setTimeout(() => {
                                                                                            retrieveData();
                                                                                        }, 600);
                                                                                    } else {
                                                                                        alert('Error');
                                                                                    }
                                                                                });
                                                                            }
                                                                        }}
                                                                    >
                                                                        <span className='mr10'>Send invitation</span>
                                                                        <div>
                                                                            {el.id === selected ?
                                                                                <>
                                                                                    {isLoading ?
                                                                                        <div className='loader' style={{ width: 10, height: 10, borderWidth: 4, borderTopColor: '#999' }} />
                                                                                        :
                                                                                        <MdSend size={20} className={`${isAnimation && el.id === selected && "send-email-animation mr5"}`} />
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <MdSend size={20} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </td>
                                                );
                                            } else if (key === 'availability') {
                                                return (
                                                    <td key={index}>
                                                        {value ? (
                                                            <div className=''>
                                                                <AiOutlineCheckCircle
                                                                    size={30}
                                                                    color={colors.primary}
                                                                    className="p4 "
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className=''>

                                                                <AiOutlineCloseCircle
                                                                    size={30}
                                                                    color={colors.secondary}
                                                                    className="p4"
                                                                />
                                                            </div>
                                                        )}
                                                    </td>
                                                );
                                            } else {
                                                return (
                                                    <td key={index}>
                                                        {value ? (
                                                            <AiOutlineCheckCircle
                                                                size={20}
                                                                color={colors.primary}
                                                                className="p4"
                                                            />
                                                        ) : (
                                                            <AiOutlineCloseCircle
                                                                size={20}
                                                                color={colors.secondary}
                                                                className="p4"
                                                            />
                                                        )}
                                                    </td>
                                                );
                                            }
                                        } else {
                                            switch (type) {
                                                case 'reservations':
                                                    if (key === 'reservationId') {
                                                        return (
                                                            <td key={index}>
                                                                <Link
                                                                    className="table-link p4"
                                                                    to={`/reservations/${el.id}`}
                                                                >
                                                                    {value.split('-')[1] + '-' + value.split('-')[2]}
                                                                </Link>
                                                            </td>
                                                        );
                                                    } else if (key === 'state') {
                                                        switch (value) {
                                                            case 'Confirmed':
                                                                return (
                                                                    <td key={index}>
                                                                        <div className="primary-text-color2 bold p4">
                                                                            {value}
                                                                        </div>
                                                                    </td>
                                                                );
                                                            // case 'Pending':
                                                            //     return (
                                                            //         <td key={index}>
                                                            //             <div className="orange-text bold p4">
                                                            //                 {value}
                                                            //             </div>
                                                            //         </td>
                                                            //     )
                                                            case 'Cancelled':
                                                                return (
                                                                    <td key={index}>
                                                                        <div className="red-text bold p4">
                                                                            {value}
                                                                        </div>
                                                                    </td>
                                                                );
                                                            default:
                                                                return (
                                                                    <td key={index}>
                                                                        <div className="orange-text bold p4">
                                                                            {value}
                                                                        </div>
                                                                    </td>
                                                                );
                                                        }
                                                    } else {
                                                        return (
                                                            <td key={index}>
                                                                <div className="p4">
                                                                    {key !== 'user_sub' && value}
                                                                </div>
                                                            </td>
                                                        );
                                                    }
                                                case 'hotels':
                                                    if (key === 'website') {
                                                        return (
                                                            <td key={index} className='of-ellipsis' style={{ maxWidth: 200 }}>
                                                                {value !== '___' ?
                                                                    <Link
                                                                        className="table-link p4"
                                                                        to={`${value}`}
                                                                        target="_blank"
                                                                    >
                                                                        {value}
                                                                    </Link>
                                                                    :
                                                                    <div
                                                                        className="p4"
                                                                    >
                                                                        {value}
                                                                    </div>
                                                                }
                                                            </td>
                                                        );
                                                    } else if (
                                                        key === 'edit' &&
                                                        edit
                                                    ) {
                                                        return (
                                                            <td key={index}>
                                                                <div className="row-view justify-evenly items-center p4 r8 bgPrimary">
                                                                    {/* <FiEdit
                                                                        size={20}
                                                                        color="white"
                                                                        className="cursor-pointer"
                                                                    /> */}
                                                                    {/* <div
                                                                        style={{
                                                                            width: 2,
                                                                            backgroundColor:
                                                                                'white',
                                                                            height: 15,
                                                                        }}
                                                                    /> */}
                                                                    <div onClick={() => handleEditHotel(el)}>
                                                                        {updates?.filter(_ => _?.sub === el.id)?.length !== 0 ?
                                                                            <>
                                                                                {updates?.filter(_ => _?.sub === el.id)?.[0]?.active ?
                                                                                    <FiEyeOff
                                                                                        size={
                                                                                            20
                                                                                        }
                                                                                        color="white"
                                                                                        className="cursor-pointer"
                                                                                    />
                                                                                    :
                                                                                    <FiEye
                                                                                        size={
                                                                                            20
                                                                                        }
                                                                                        color="white"
                                                                                        className="cursor-pointer"
                                                                                    />
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {el.active ?
                                                                                    <FiEyeOff
                                                                                        size={
                                                                                            20
                                                                                        }
                                                                                        color="white"
                                                                                        className="cursor-pointer"
                                                                                    />
                                                                                    :
                                                                                    <FiEye
                                                                                        size={
                                                                                            20
                                                                                        }
                                                                                        color="white"
                                                                                        className="cursor-pointer"
                                                                                    />
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                    {/* <div
                                                                        style={{
                                                                            width: 2,
                                                                            backgroundColor:
                                                                                'white',
                                                                            height: 15,
                                                                        }}
                                                                    /> */}
                                                                    {/* <FiTrash2
                                                                        size={20}
                                                                        color="white"
                                                                        className="cursor-pointer"
                                                                    /> */}
                                                                </div>
                                                            </td>
                                                        );
                                                    } else {
                                                        return (
                                                            <td key={index}>
                                                                <div className="p4">
                                                                    {value}
                                                                </div>
                                                            </td>
                                                        );
                                                    }
                                                case 'newHotels':
                                                    if (key === 'state') {
                                                        return (
                                                            <td key={index} className='row-view gap20' style={{ maxWidth: 100 }}>
                                                                <div
                                                                    className='primary-button row-view items-center justify-center p12 r100'
                                                                    onClick={() => {
                                                                        setSelected(el.id);
                                                                        setIsLoading(true);
                                                                        if (!isLoading) {
                                                                            acceptNewHotel(el?.id, response => {
                                                                                setIsLoading(false);
                                                                                if (response?.status === 'success') {
                                                                                    setAnimation(true);
                                                                                    setTimeout(() => {
                                                                                        retrieveData();
                                                                                    }, 600);
                                                                                } else {
                                                                                    alert('Error');
                                                                                }
                                                                            });
                                                                        }
                                                                    }}
                                                                >
                                                                    <span className='mr10'>Accept</span>
                                                                    <MdCheck size={20} />
                                                                </div>
                                                                <div
                                                                    className='secondary-button row-view items-center justify-center p12 r100'
                                                                    onClick={() => {
                                                                        setSelected(el.id);
                                                                        setIsLoading(true);
                                                                        if (!isLoading) {
                                                                            rejectNewHotel(el?.id, response => {
                                                                                setIsLoading(false);
                                                                                if (response?.status === 'success') {
                                                                                    setAnimation(true);
                                                                                    setTimeout(() => {
                                                                                        retrieveData();
                                                                                    }, 600);
                                                                                } else {
                                                                                    alert('Error');
                                                                                }
                                                                            });
                                                                        }
                                                                    }}
                                                                >
                                                                    <span className='mr10'>Reject</span>
                                                                    <MdClose size={20} />
                                                                </div>
                                                            </td>
                                                        );
                                                    } else {
                                                        return (
                                                            <td key={index}>
                                                                <div className="p4">
                                                                    {value}
                                                                </div>
                                                            </td>
                                                        );
                                                    }
                                                case 'hotelRequests':
                                                    if (key === 'state') {
                                                        return (
                                                            <td key={index} className='row-view gap20' style={{ maxWidth: 100 }}>
                                                                <div
                                                                    className='primary-button row-view items-center justify-center p12 r100'
                                                                    onClick={() => {
                                                                        setSelected(el.id);
                                                                        setIsLoading(true);
                                                                        if (!isLoading) {
                                                                            acceptRequestHotel(el?.id, response => {
                                                                                setIsLoading(false);
                                                                                if (response?.status === 'success') {
                                                                                    setAnimation(true);
                                                                                    setTimeout(() => {
                                                                                        retrieveData();
                                                                                    }, 600);
                                                                                } else {
                                                                                    alert('Error');
                                                                                }
                                                                            });
                                                                        }
                                                                    }}
                                                                >
                                                                    <span className='mr10'>Accept</span>
                                                                    <MdCheck size={20} />
                                                                </div>
                                                                <div
                                                                    className='secondary-button row-view items-center justify-center p12 r100'
                                                                    onClick={() => {
                                                                        setSelected(el.id);
                                                                        setIsLoading(true);
                                                                        if (!isLoading) {
                                                                            rejectRequestHotel(el?.id, response => {
                                                                                setIsLoading(false);
                                                                                if (response?.status === 'success') {
                                                                                    setAnimation(true);
                                                                                    setTimeout(() => {
                                                                                        retrieveData();
                                                                                    }, 600);
                                                                                } else {
                                                                                    alert('Error');
                                                                                }
                                                                            });
                                                                        }
                                                                    }}
                                                                >
                                                                    <span className='mr10'>Reject</span>
                                                                    <MdClose size={20} />
                                                                </div>
                                                            </td>
                                                        );
                                                    } else {
                                                        return (
                                                            <td key={index}>
                                                                <div className="p4">
                                                                    {value}
                                                                </div>
                                                            </td>
                                                        );
                                                    }
                                                case 'staff':
                                                    if (key === 'email') {
                                                        return (
                                                            <td key={index}>
                                                                <Link
                                                                    className="table-link p4"
                                                                    to={`mailto:${value}`}
                                                                    target="_blank"
                                                                >
                                                                    {value}
                                                                </Link>
                                                            </td>
                                                        );
                                                    } else if (
                                                        key === 'role' &&
                                                        edit
                                                    ) {
                                                        return (
                                                            <td key={index}>
                                                                <div className="relative">
                                                                    <div
                                                                        className="border-container bgPrimary white-text row-view items-center justify-between w120 p4 r8 cursor-pointer"
                                                                        onClick={() => {
                                                                            setRolesOpen(
                                                                                !rolesOpen
                                                                            );
                                                                            setSub(
                                                                                el.id
                                                                            );
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            {value
                                                                                ? value
                                                                                : 'Select role'}
                                                                        </div>
                                                                        <BiSolidChevronDown />
                                                                    </div>
                                                                    <div
                                                                        className={`select-menu ${rolesOpen &&
                                                                            el.id ===
                                                                            sub &&
                                                                            'open'
                                                                            }`}
                                                                    >
                                                                        {roles.length !==
                                                                            0 ? (
                                                                            <>
                                                                                {roles.map(
                                                                                    (
                                                                                        role,
                                                                                        idx
                                                                                    ) => {
                                                                                        return (
                                                                                            <div
                                                                                                key={
                                                                                                    idx
                                                                                                }
                                                                                                className="select-item"
                                                                                                onClick={() => {
                                                                                                    handleSelect(
                                                                                                        'role',
                                                                                                        role
                                                                                                    );
                                                                                                    setRolesOpen(
                                                                                                        false
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <span className="section-title">
                                                                                                    {
                                                                                                        role
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className="select-item">
                                                                                    No
                                                                                    roles
                                                                                    found
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        );
                                                    } else if (key === 'role') {
                                                        return (
                                                            <td key={index}>
                                                                <div className="p4">
                                                                    {value[0]?.toUpperCase() + value?.slice(1)}
                                                                </div>
                                                            </td>
                                                        );
                                                    } else if (key === 'position') {
                                                        return (
                                                            <td key={index}>
                                                                <div className="p4 ellipsis w300">
                                                                    {value}
                                                                </div>
                                                            </td>
                                                        );
                                                    } else if (key === 'hotel') {
                                                        return (
                                                            <td key={index}>
                                                                <div className="p4">
                                                                    {value}
                                                                </div>
                                                            </td>
                                                        );
                                                    } else if (key === 'created_at' || key === 'invitedAt') {
                                                        return;
                                                    }
                                                    else {
                                                        return (
                                                            <td key={index}>
                                                                <div className="p4 w200 ellipsis">
                                                                    {value}
                                                                </div>
                                                            </td>
                                                        );
                                                    }
                                                case 'Requested':
                                                    if (key === 'send') {
                                                        return (
                                                            <td>
                                                                {/* <div
                                                                    className='primary-button row-view items-center justify-center p12 r100'
                                                                    onClick={() => {
                                                                        setSelected(el.id)
                                                                        setIsLoading(true)
                                                                        if (!isLoading) {
                                                                            let user = {
                                                                                email: el?.email,
                                                                                firstname: el?.name?.split(' ')?.[0] ?? '',
                                                                                lastname: el?.name?.split(' ')?.[1] ?? '',
                                                                                position: el?.position ?? '',
                                                                                source: el?.source ?? ''
                                                                            }
                                                                            sendInvite(user, response => {
                                                                                setIsLoading(false)
                                                                                if (response?.status === 'success') {
                                                                                    setAnimation(true)
                                                                                    setTimeout(() => {
                                                                                        retrieveData()
                                                                                    }, 600)
                                                                                } else {
                                                                                    alert('Error')
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                                                    <span className='mr10'>Send invitation</span>
                                                                    <div>
                                                                        {el.id === selected ?
                                                                            <>
                                                                                {isLoading ?
                                                                                    <div className='loader' style={{ width: 10, height: 10, borderWidth: 4, borderTopColor: '#999' }} />
                                                                                    :
                                                                                    <MdSend size={20} className={`${isAnimation && el.id === selected && "send-email-animation mr5"}`} />
                                                                                }
                                                                            </>
                                                                            :
                                                                            <MdSend size={20} />
                                                                        }
                                                                    </div>
                                                                </div> */}
                                                                {el.invited ?
                                                                    <div className='row-view items-center'>
                                                                        <HiClock size={25} color={colors.yellow} className='mr5' />
                                                                        <div className='column-view'>
                                                                            <span className='bold'>Sent</span>
                                                                            <span className='grey-text'>{el.invitedAt}</span>
                                                                            <div className='row-view items-center cursor-pointer'
                                                                                onClick={() => {
                                                                                    setSelected(el.id);
                                                                                    setIsLoading(true);
                                                                                    if (!isLoading) {
                                                                                        let user = {
                                                                                            email: el?.email,
                                                                                            source: el?.source ?? ''
                                                                                        };
                                                                                        sendInvite(user, response => {
                                                                                            setIsLoading(false);
                                                                                            setSelected(null);
                                                                                            if (response?.status === 'success') {
                                                                                                retrieveData();
                                                                                            } else {
                                                                                                alert('Error');
                                                                                            }
                                                                                        });
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <span className='primary-text-color bold mr5'>Resend</span>
                                                                                <FiRefreshCw color={colors.primary} className={`${isLoading && 'spin'}`} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div
                                                                        className='primary-button row-view items-center justify-center p12 r100'
                                                                        onClick={() => {
                                                                            setSelected(el.id);
                                                                            setIsLoading(true);
                                                                            if (!isLoading) {
                                                                                let user = {
                                                                                    email: el?.email,
                                                                                    firstname: el?.name?.split(' ')?.[0] ?? '',
                                                                                    lastname: el?.name?.split(' ')?.[1] ?? '',
                                                                                    position: el?.position ?? '',
                                                                                    source: el?.source ?? ''
                                                                                };
                                                                                sendInvite(user, response => {
                                                                                    setIsLoading(false);
                                                                                    if (response?.status === 'success') {
                                                                                        setAnimation(true);
                                                                                        setTimeout(() => {
                                                                                            retrieveData();
                                                                                        }, 600);
                                                                                    } else {
                                                                                        alert('Error');
                                                                                    }
                                                                                });
                                                                            }
                                                                        }}
                                                                    >
                                                                        <span className='mr10'>Send invitation</span>
                                                                        <div>
                                                                            {el.id === selected ?
                                                                                <>
                                                                                    {isLoading ?
                                                                                        <div className='loader' style={{ width: 10, height: 10, borderWidth: 4, borderTopColor: '#999' }} />
                                                                                        :
                                                                                        <MdSend size={20} className={`${isAnimation && el.id === selected && "send-email-animation mr5"}`} />
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <MdSend size={20} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </td>
                                                        );
                                                    } else if (key === 'email') {
                                                        return (
                                                            <td key={index}>
                                                                <Link
                                                                    className="table-link p4"
                                                                    to={`mailto:${value}`}
                                                                    target="_blank"
                                                                >
                                                                    {value}
                                                                </Link>
                                                            </td>
                                                        );
                                                    } else if (key === "invitedAt") {
                                                        return;
                                                    } else {
                                                        return (
                                                            <td key={index}>
                                                                <div className="p4 ellipsis">
                                                                    {value}
                                                                </div>
                                                            </td>
                                                        );
                                                    }
                                                case 'travelers':
                                                    if (key === 'email') {
                                                        return (
                                                            <td key={index}>
                                                                <Link
                                                                    className="table-link p4"
                                                                    to={`mailto:${value}`}
                                                                    target="_blank"
                                                                >
                                                                    {value}
                                                                </Link>
                                                            </td>
                                                        );
                                                    } else if (key === 'state') {
                                                        return (
                                                            <td key={index}>
                                                                <div className={`p4 w200 bold ${value === 'Cancelled' ? 'red-text' : 'primary-text-color2'}`}>
                                                                    {value}
                                                                </div>
                                                            </td>
                                                        );
                                                    } else {
                                                        return (
                                                            <td key={index}>
                                                                <div className="p4 w200">
                                                                    {value}
                                                                </div>
                                                            </td>
                                                        );
                                                    }
                                                case 'profiles':
                                                    if (key === 'period') {
                                                        let dates = value;
                                                        return (
                                                            <td key={index}>
                                                                <div className='row-view gap20'>
                                                                    <div className='column-view mr20'>
                                                                        <div><span className='bold'>From</span> {moment(dates[0]).format('ll')}</div>
                                                                    </div>
                                                                    <div className='column-view'>
                                                                        <div><span className='bold'>To</span> {moment(dates[1]).format('ll')}</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        );
                                                    } else {
                                                        let [tag, length] = value.split(',');
                                                        return (
                                                            <td key={index}>
                                                                <div className="row-view items-center">
                                                                    <div className='mr10'>{tag}</div>
                                                                    {length > 1 && <div className='white-text bold bgPrimary r100 text-center' style={{ padding: '6px 10px' }}> +{length - 1} </div>}
                                                                </div>
                                                            </td>
                                                        );
                                                    }
                                                case 'services':
                                                    if (key === 'dates') {
                                                        let dates = value.split(',');
                                                        return (
                                                            <td key={index}>
                                                                <div className='row-view gap20'>
                                                                    <div className='column-view mr20'>
                                                                        <div><span className='bold'>From</span> {moment(dates[0]).format('ll')}</div>
                                                                        <div><span className='bold'>at</span> {moment(dates[0]).format('LT')}</div>
                                                                    </div>
                                                                    <div className='column-view'>
                                                                        <div><span className='bold'>From</span> {moment(dates[1]).format('ll')}</div>
                                                                        <div><span className='bold'>at</span> {moment(dates[1]).format('LT')}</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        );
                                                    } else if (key === 'informations') {
                                                        let numbers = value.split('|');
                                                        return (

                                                            <td key={index}>
                                                                <div>
                                                                    <div className='column-view pv12 ph10 brdr8' style={{ backgroundColor: '#EF70561A' }}>
                                                                        <div>Created on : <span className='bold'>{numbers[0]}</span></div>
                                                                        <div>Number of bookings : <span className='bold'>{numbers[1]}</span></div>
                                                                        <div>Number of cancellations : <span className='bold'>{numbers[2]}</span></div>
                                                                        <div>Total revenue : <span className='bold'>{numbers[3]}€</span></div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        );
                                                    }
                                                    break;
                                                case 'restaurants':
                                                    if (key === 'opening') {
                                                        let opening = {};
                                                        if (value) {
                                                            JSON.parse(value);
                                                        }
                                                        return (
                                                            <td key={index}>
                                                                <div className="row-view items-center">
                                                                    {opening?.events?.length > 0 &&
                                                                        <div>
                                                                            <h3 className='bold'>By events</h3>
                                                                            <div className='row-view items-center mr20 mt10'>
                                                                                <div className='mr10'>{opening?.events?.[0]?.icon} {opening?.events?.[0]?.label}</div>
                                                                                {opening?.events?.length > 1 && <div className='white-text bold bgPrimary r100 text-center' style={{ padding: '6px 10px' }}> +{opening?.events?.length - 1} </div>}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {opening?.days?.length > 0 &&
                                                                        <div>
                                                                            <h3 className='bold'>By days</h3>
                                                                            <div className='row-view items-center mr20 mt10'>
                                                                                <div className='mr10'>{opening?.days?.[0]}</div>
                                                                                {opening?.days?.length > 1 && <div className='white-text bold bgPrimary r100 text-center' style={{ padding: '6px 10px' }}> +{opening?.days?.length - 1} </div>}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </td>
                                                            // <td key={index}>
                                                            //     <div className="row-view items-center">
                                                            //         <div className='mr10'>{eventsTag}</div>
                                                            //         {eventsLength > 1 && <div className='white-text bold bgPrimary r100 text-center' style={{ padding: '6px 10px' }}> +{eventsLength - 1} </div>}
                                                            //         {daysTag && daysLength &&
                                                            //             <>
                                                            //                 <div className='mr10'>{daysTag}</div>
                                                            //                 {daysLength > 1 && <div className='white-text bold bgPrimary r100 text-center' style={{ padding: '6px 10px' }}> +{daysLength - 1} </div>}
                                                            //             </>
                                                            //         }
                                                            //     </div>
                                                            // </td>
                                                        );
                                                    } else if (key === 'informations') {
                                                        let numbers = value.split('|');
                                                        return (

                                                            <td key={index}>
                                                                <div>
                                                                    <div className='column-view pv12 ph10 brdr8' style={{ backgroundColor: '#EF70561A' }}>
                                                                        <div>Created on : <span className='bold'>{numbers[0]}</span></div>
                                                                        <div>Number of bookings : <span className='bold'>{numbers[1]}</span></div>
                                                                        <div>Number of cancellations : <span className='bold'>{numbers[2]}</span></div>
                                                                        <div>Total revenue : <span className='bold'>{numbers[3]}€</span></div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        );
                                                    } else if (key === 'foodType') {
                                                        return (
                                                            <td key={index}>
                                                                <div className="p4 w120 ellipsis">
                                                                    {value}
                                                                </div>
                                                            </td>
                                                        );
                                                    }
                                                    break;
                                                default:
                                                    return (
                                                        <td key={index}>
                                                            <div className="p4 w200 ellipsis">
                                                                {value}
                                                            </div>
                                                        </td>
                                                    );
                                            }
                                        }
                                    }
                                })}
                            </tr>
                        ))}
                    </tbody>
                    :
                    <div className='p10'>{renderData}</div>
                }
            </table>
        </div>
    );
};
