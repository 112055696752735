import React, { useEffect, useState } from 'react';

import logoTextblack from '../../assets/logos/logo-text-black.svg';
import { LoginForm } from './LoginForm';
import { ForgotPasswordFrom } from './ForgotPasswordForm';
import { forgotPassword, verifyPhone } from '../../api/auth';
import { MdClose } from 'react-icons/md';


export const Login = ({ handleLogin, errorMessage }) => {
    const [activeForm, setActiveForm] = useState('login');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [pinCode, setPinCode] = useState('');

    const handleForgotPassword = (email) => {
        forgotPassword(email, response => {
            if (response.status === 'success') {
                alert(`Request sent to ${email}`)
                setActiveForm('login');
            } else {
                alert(response?.message)
            }
        })
    }

    const handleVerify = () => {
        let content = {
            phone: phoneNumber,
            pin_code: pinCode
        }
        verifyPhone(content, response => {
            if (response?.status === 'success') {
                setShowAlert(false)
                handleLogin(email, password)
            } else {
                alert(response?.message)
            }
        })
    }

    useEffect(() => {
        if (errorMessage === 'Authentication failed, account not verified') {
            setShowAlert(true)
        }
    }, [errorMessage])

    return (
        <div className='column-view h100vh items-center justify-between'>
            {showAlert && (
                <div
                    className='row-view filter-darker items-center justify-center'
                >
                    <div>
                        <div className='bgWhite p20 r8'>
                            <div className='row-view justify-between'>
                                <div className='subtitle'>Please verify your account</div>
                                <MdClose size={30} onClick={() => setShowAlert(false)} className='cursor-pointer' />
                            </div>
                            <div className='mt10'>You need to verify your account, enter the code you recieved by SMS</div>
                            <div>or click resend to recieve a new one</div>
                            <form
                                className='column-view'
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    handleVerify()
                                }}
                            >
                                <input
                                    className='input-text mt10'
                                    type='text'
                                    placeholder='Phone number'
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    required
                                />
                                <input
                                    className='input-text mt10'
                                    type='text'
                                    placeholder='Pin code'
                                    value={pinCode}
                                    onChange={(e) => setPinCode(e.target.value)}
                                    required
                                />
                                <button className='primary-button mt10' type='submit'>Send</button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            <div className='column-view items-center flex03 mt20'>
                <img
                    src={logoTextblack}
                    alt='logoTextWhite'
                    className='logo'
                />
            </div>
            <div className='row-view items-center flex03'>
                <div className='title column-view text-center'>
                    <span>
                        Luxury is matching the perfect customer
                    </span>
                    <span>
                        with your hotel for a specific trip
                    </span>
                </div>
            </div>
            <div className='flex1'>
                {activeForm === 'login' ?
                    <LoginForm
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                        handleLogin={handleLogin}
                        setActiveForm={setActiveForm}
                        errorMessage={errorMessage}
                    />
                    :
                    <ForgotPasswordFrom
                        email={email}
                        setEmail={setEmail}
                        handleForgotPassword={handleForgotPassword}
                        setActiveForm={setActiveForm}
                    />
                }
            </div>
            <div className='column-view flex04 text-center justify-end pv20' >
                <div>
                    By continuing, you agree to the {' '}
                    <a
                        href='https://mymatchingcompany.com/mentions-legales/'
                        target='_blank'
                        rel="noreferrer"
                        className='link'
                    >
                        Terms of Service
                    </a>
                    {' '} and {' '}
                    <a
                        href='https://mymatchingcompany.com/mentions-legales/'
                        target='_blank'
                        rel="noreferrer"
                        className='link'
                    >
                        Privacy policy
                    </a>
                </div>
                <div className='mt20'>
                    All rights reserved.
                </div>
                <div>
                    ©Myhotelmatch {new Date().getFullYear()}
                </div>
            </div>
        </div>
    )
}