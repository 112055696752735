export const reviewsData = [
    // {
    //     score: {
    //         staff: 4.7,
    //         equipments: 4.1
    //     },
    //     author: 'Harry Cover',
    //     room: 'Deluxe Room',
    //     date: 'Jul 31, 2023',
    //     title: 'A great experience',
    //     comment: 'Lorem ipsum dolor sit amet consectetur. Senectus faucibus auctor varius metus est imperdiet arcu dolor justo. Dignissim varius aliquet nam blandit sit. Aliquet nunc felis rhoncus sollicitudin scelerisque id nullam nulla. Donec sit diam magna pharetra dolor id. Egestas est a duis ut tellus tortor pelenteque. Lectus phasellus eu est egestas velit vestibulum dignissim varius aliquet var...'
    // },
    // {
    //     score: {
    //         staff: 4.9,
    //         equipments: 4.3
    //     },
    //     author: 'By Warren Fusi',
    //     room: 'Deluxe Suite',
    //     date: 'Jul 28, 2023',
    //     title: 'A beautiful discovery',
    //     comment: 'Lorem ipsum dolor sit amet consectetur. Senectus faucibus auctor varius metus est imperdiet arcu dolor justo. Dignissim varius aliquet nam blandit sit. Aliquet nunc felis rhoncus sollicitudin scelerisque id nullam nulla. Donec sit diam magna pharetra dolor id. Egestas est a duis ut tellus tortor pelenteque. Lectus phasellus eu est egestas velit vestibulum dignissim varius aliquet var...'
    // },
    // {
    //     score: {
    //         staff: 4.7,
    //         equipments: 4.1
    //     },
    //     author: 'By Iva Murakami',
    //     room: 'Deluxe Room',
    //     date: 'Jul 28, 2023',
    //     title: 'Great night in Prague',
    //     comment: 'Lorem ipsum dolor sit amet consectetur. Senectus faucibus auctor varius metus est imperdiet arcu dolor justo. Dignissim varius aliquet nam blandit sit. Aliquet nunc felis rhoncus sollicitudin scelerisque id nullam nulla. Donec sit diam magna pharetra dolor id. Egestas est a duis ut tellus tortor pelenteque. Lectus phasellus eu est egestas velit vestibulum dignissim varius aliquet var...'
    // },
    // {
    //     score: {
    //         staff: 4.9,
    //         equipments: 4.3
    //     },
    //     author: 'By Estella Smit',
    //     room: 'Deluxe Suite',
    //     date: 'Jul 27, 2023',
    //     title: 'Excellent',
    //     comment: 'Lorem ipsum dolor sit amet consectetur. Senectus faucibus auctor varius metus est imperdiet arcu dolor justo. Dignissim varius aliquet nam blandit sit. Aliquet nunc felis rhoncus sollicitudin scelerisque id nullam nulla. Donec sit diam magna pharetra dolor id. Egestas est a duis ut tellus tortor pelenteque. Lectus phasellus eu est egestas velit vestibulum dignissim varius aliquet var...'
    // },
    // {
    //     score: {
    //         staff: 4.7,
    //         equipments: 4.1
    //     },
    //     author: 'By Robert Fox',
    //     room: 'Deluxe Room',
    //     date: 'Jul 26, 2023',
    //     title: 'I recommend',
    //     comment: 'Lorem ipsum dolor sit amet consectetur. Senectus faucibus auctor varius metus est imperdiet arcu dolor justo. Dignissim varius aliquet nam blandit sit. Aliquet nunc felis rhoncus sollicitudin scelerisque id nullam nulla. Donec sit diam magna pharetra dolor id. Egestas est a duis ut tellus tortor pelenteque. Lectus phasellus eu est egestas velit vestibulum dignissim varius aliquet var...'
    // },
]