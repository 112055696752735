import React, { useState } from 'react';
import { SetProfile } from './SetProfile';
import { SetProfileTag } from './SetProfileTag';
import { ThanksIltm } from './ThanksIltm';

export const RegisteredHotel = ({ userContent, setUserContent, hotelContent }) => {
    const [activeSubTab, setActiveSubTab] = useState(0)
    return (
        <div>
            {activeSubTab === 0 && <SetProfile setActiveSubTab={setActiveSubTab} userContent={userContent} setUserContent={setUserContent} hotelContent={hotelContent} />}
            {activeSubTab === 1 && <SetProfileTag setActiveSubTab={setActiveSubTab} userContent={userContent} setUserContent={setUserContent} hotelContent={hotelContent} />}
            {activeSubTab === 2 && <ThanksIltm />}
        </div>
    )
}