import React from 'react';
import LogoBlackText from '../../assets/logos/logo-text-black.svg';
import { GoalProgressLine } from '../../components/ProgressLine/GoalProgressLine';
import { MdChevronLeft } from 'react-icons/md';
import { hotel_profile_tags } from '../../utils/mock/HotelData';

const TagsTab = ({ setActiveTab, staff, setStaff }) => {
    const containsObject = (obj, list) => {
        return list.some(item => item.icon === obj.icon && item.label === obj.label);
    };

    const handleTag = (key, value) => {
        if (staff[key] === undefined) {
            setStaff({ ...staff, [key]: [value] });
            return;
        }
        if (containsObject(value, staff[key])) {
            setStaff({ ...staff, [key]: staff[key].filter((el) => el?.icon !== value?.icon && el?.label !== value?.label) });
        } else {
            setStaff({ ...staff, [key]: [...staff[key], value] });
        }
    };

    return (
        <div className='page-wrapper-tab'>
            <div className='text-center'>
                <img src={LogoBlackText} alt='LogoBlackText' className='welcome-logo mb20' />
                <GoalProgressLine rate={2} max={3} />
                <MdChevronLeft size={'4rem'} style={{ position: 'absolute', top: '10rem', left: '16vw' }} onClick={() => setActiveTab(prev => prev - 1)} />
                <h2 className='bold mt20'>Hey {staff?.firstname}! Profile yourself</h2>
                <h3 className='mt10'>Before profiling your hotel, we would like to know more about you</h3>
            </div>
            <div className='border-container r8 mt20'>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Appreciated for</div>
                    <div className='row-view wrap flex1'>
                        {hotel_profile_tags.profiling_user.preferences.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view items-center tag mr20 mt10 mb10 ${staff?.tagAppreciations && containsObject(item, staff?.tagAppreciations) && 'selected'}`}
                                    onClick={() => handleTag('tagAppreciations', item)}
                                >
                                    <div className='mr10'>{item.icon}</div>
                                    <div>{item.label}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Spoken languages</div>
                    <div className='row-view wrap flex1'>
                        {hotel_profile_tags.profiling_user.spoken_languages.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view items-center tag mr20 mt10 mb10 ${staff?.tagLanguages && containsObject(item, staff?.tagLanguages) && 'selected'}`}
                                    onClick={() => handleTag('tagLanguages', item)}
                                >
                                    <div className='mr10'>{item.icon}</div>
                                    <div>{item.label}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Music style</div>
                    <div className='row-view wrap flex1'>
                        {hotel_profile_tags.profiling_user.music_brand_identity.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view items-center tag mr20 mt10 mb10 ${staff?.tagMusicStyle && containsObject(item, staff?.tagMusicStyle) && 'selected'}`}
                                    onClick={() => handleTag('tagMusicStyle', item)}
                                >
                                    <div className='mr10'>{item.icon}</div>
                                    <div>{item.label}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Favorite food type</div>
                    <div className='row-view wrap flex1'>
                        {hotel_profile_tags.profiling_user.favorite_food_type.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view items-center tag mr20 mt10 mb10 ${staff?.tagFoodType && containsObject(item, staff?.tagFoodType) && 'selected'}`}
                                    onClick={() => handleTag('tagFoodType', item)}
                                >
                                    <div className='mr10'>{item.icon}</div>
                                    <div>{item.label}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Food restrictions</div>
                    <div className='row-view wrap flex1'>
                        {hotel_profile_tags.profiling_user.food_restrictions.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view items-center tag mr20 mt10 mb10 ${staff?.tagFoodRestrictions && containsObject(item, staff?.tagFoodRestrictions) && 'selected'}`}
                                    onClick={() => handleTag('tagFoodRestrictions', item)}
                                >
                                    <div className='mr10'>{item.icon}</div>
                                    <div>{item.label}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='row-view items-center p20'>
                    <div className='section-title flex04'>Allergies</div>
                    <div className='row-view wrap flex1'>
                        {hotel_profile_tags.profiling_user.allergies.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`row-view items-center tag mr20 mt10 mb10 ${staff?.tagAllergies && containsObject(item, staff?.tagAllergies) && 'selected'}`}
                                    onClick={() => handleTag('tagAllergies', item)}
                                >
                                    <div className='mr10'>{item.icon}</div>
                                    <div>{item.label}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div>
                <div
                    className='primary-button r100 w300'
                    onClick={() => setActiveTab(prev => prev + 1)}
                >
                    Continue
                </div>
                <div className='column-view flex04 text-center justify-end pv20' >
                    <div>
                        By continuing, you agree to the {' '}
                        <a
                            href='https://mymatchingcompany.com/mentions-legales/'
                            target='_blank'
                            rel="noreferrer"
                            className='link'
                        >
                            Terms of Service
                        </a>
                        {' '} and {' '}
                        <a
                            href='https://mymatchingcompany.com/mentions-legales/'
                            target='_blank'
                            rel="noreferrer"
                            className='link'
                        >
                            Privacy policy
                        </a>
                    </div>
                    <div className='mt20'>
                        All rights reserved.
                    </div>
                    <div>
                        ©Myhotelmatch {new Date().getFullYear()}
                    </div>
                </div>
            </div>
        </div >
    );
};

export default TagsTab;