import React, { useEffect, useRef, useState } from 'react';
import { HiChevronDoubleLeft, HiChevronDoubleRight, HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { PiCaretUpDownLight } from 'react-icons/pi';

const limit = [5, 10, 15, 20, 25]

const Pages = ({ currentPage, totalPages, onPageChange }) => {
    const displayPages = [];

    displayPages.push(1);

    if (currentPage > 3) {
        displayPages.push('...');
        displayPages.push(currentPage - 1);
    } else if (currentPage === 3) {
        displayPages.push(2);
    }

    if (currentPage !== 1 && currentPage !== totalPages) {
        displayPages.push(currentPage);
    }

    if (currentPage < totalPages - 2) {
        displayPages.push(currentPage + 1);
        displayPages.push('...');
    } else if (currentPage === totalPages - 2) {
        displayPages.push(totalPages - 1);
    }

    if (totalPages > 1) {
        displayPages.push(totalPages);
    }

    return (
        <div className='row-view items-center'>
            {displayPages.map((page, index) => (
                <div key={index}>
                    {page === '...' ? (
                        <span className='bold f-size32 cursor-pointer'>{page}</span>
                    ) : (
                        <button className={`borderless p10 r8 mh5 bold f-size16 cursor-pointer ${currentPage === page ? 'bgPrimary white-text' : 'bgWhite'}`} onClick={() => onPageChange(page)}>{page}</button>
                    )}
                </div>
            ))}
        </div>
    );
}

export const Pagination = ({ type, currentPage, setCurrentPage, itemsPerPage, pagesNb, setItemsPerPage, path, retrieveData, searchText }) => {
    const navigate = useNavigate();
    const [pagingOpen, setPagingOpen] = useState(false);
    const pagingRef = useRef(null)

    const handlePageChange = (newPage) => {
        // navigate(`/${path}?page=${newPage}&limit=${itemsPerPage}&search=${searchText}`)
        setCurrentPage(newPage);
        retrieveData((newPage - 1) * itemsPerPage, itemsPerPage, searchText);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (pagingOpen && pagingRef.current && !pagingRef.current.contains(event.target)) {
                setPagingOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [
        pagingOpen,
        pagingRef
    ]);

    return (
        <div className='row-view items-center justify-between mt50'>
            <div className='flex1' />

            <div className='flex1 row-view items-center justify-center'>
                <div className='' onClick={() => {
                    if (currentPage === 50) {
                        retrieveData(0, itemsPerPage, searchText);
                        setCurrentPage(1);
                        // navigate(`/${path}?page=1&limit=${itemsPerPage}&search=${searchText}`)
                    }
                    if (currentPage > 50) {
                        retrieveData((currentPage - 51) * itemsPerPage, itemsPerPage, searchText)
                        setCurrentPage(currentPage - 50)
                        // navigate(`/${path}?page=${currentPage - 50}&limit=${itemsPerPage}&search=${searchText}`)
                    }
                }
                }>
                    <HiChevronDoubleLeft data-tooltip-id="tooltip-50" size={30} className='cursor-pointer' color={currentPage < 50 ? '#DADADA' : '#000000'} />
                    <ReactTooltip
                        id="tooltip-50"
                        place="top"
                        content="-50"
                    />
                </div>
                <div className='mr10' onClick={() => {
                    if (currentPage !== 1) {
                        if (currentPage === 2) {
                            retrieveData(0, itemsPerPage, searchText);
                            setCurrentPage(1);
                            // navigate(`/${path}?page=1&limit=${itemsPerPage}&search=${searchText}`)
                        } else {
                            retrieveData((currentPage - 2) * itemsPerPage, itemsPerPage, searchText);
                            setCurrentPage(currentPage - 1);
                            // navigate(`/${path}?page=${currentPage - 1}&limit=${itemsPerPage}&search=${searchText}`)
                        }
                    }
                }
                }>
                    <HiChevronLeft size={35} className='cursor-pointer' color={currentPage === 1 ? '#DADADA' : '#000000'} />
                </div>
                <div className='mr10'>
                    <Pages currentPage={currentPage} totalPages={pagesNb} onPageChange={handlePageChange} />
                </div>
                <div className='' onClick={() => {
                    if (currentPage !== pagesNb) {
                        retrieveData((currentPage) * itemsPerPage, itemsPerPage, searchText)
                        setCurrentPage(currentPage + 1);
                        // navigate(`/${path}?page=${currentPage + 1}&limit=${itemsPerPage}&search=${searchText}`)
                    }
                }}>
                    <HiChevronRight size={35} className='cursor-pointer' color={currentPage === pagesNb ? '#DADADA' : '#000000'} />
                </div>
                <div className='' onClick={() => {
                    if (currentPage <= pagesNb - 50) {
                        retrieveData((currentPage + 49) * itemsPerPage, itemsPerPage, searchText)
                        setCurrentPage(currentPage + 50)
                        // navigate(`/${path}?page=${currentPage + 50}&limit=${itemsPerPage}&search=${searchText}`)
                    }
                }
                }>
                    <HiChevronDoubleRight data-tooltip-id="tooltip+50" size={30} className='cursor-pointer' color={currentPage > pagesNb - 50 ? '#DADADA' : '#000000'} />
                    <ReactTooltip
                        id="tooltip+50"
                        place="top"
                        content="+50"
                    />
                </div>
            </div>

            <div className='flex1 row-view items-center justify-end'>
                <div className='relative secondary-button row-view p10 mr10' onClick={() => setPagingOpen(!pagingOpen)}>
                    <div className='row-view'>{itemsPerPage}</div>
                    <div className={`select-menu ${pagingOpen && 'open'}`} style={{ top: "100%", left: 0, marginTop: 5 }} ref={pagingRef}>
                        {limit.map((l, i) => {
                            return (
                                <div
                                    key={i}
                                    className='select-item p4 text-center'
                                    onClick={() => {
                                        setItemsPerPage(l);
                                        // navigate(`/${path}?page=${currentPage}&limit=${l}&search=${searchText}`)
                                        retrieveData((currentPage - 1) * l, l, searchText);
                                    }}
                                >
                                    {l}
                                </div>
                            )
                        })}
                    </div>
                    <PiCaretUpDownLight />
                </div>
                <div className='section-title'>{type} per page</div>
            </div>
        </div>
    )
}