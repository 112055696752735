import React from 'react';
import { Link } from 'react-router-dom';

export const ForgotPasswordFrom = ({ email, setEmail, handleForgotPassword, setActiveForm }) => {
    return (
        <form
            className='login-container'
            onSubmit={(e) => {
                e.preventDefault();
                handleForgotPassword(email);
            }}
        >
            <div>Email address</div>
            <input
                required
                className='input-text mt10'
                type='email'
                placeholder='Email address'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <button
                type='submit'
                className={`primary-button mt20 ${email.length === 0 ? 'opacity60' : ''}`}
                disabled={email.length === 0}
            >
                Reset password
            </button>
            <Link className='mt20 link none-decoration-link text-center' onClick={() => setActiveForm('login')}>
                Back to login?
            </Link>
        </form>
    );
};