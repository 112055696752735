import React, { useRef, useState } from "react";
import LogoBlackText from "../../assets/logos/logo-text-black.svg";
import { useNavigate } from "react-router-dom";
import { GoalProgressLine } from "../../components/ProgressLine/GoalProgressLine";
import { MdChevronLeft, MdClose } from "react-icons/md";
import addImg from "../../assets/icons/AddImgOrange.svg";
import { updateUser } from "../../api/user";

const PhotoTab = ({ setActiveTab, staff, setStaff }) => {
  const navigate = useNavigate();
  const [photoInput, setPhotoInput] = useState(null);
  const hiddenFileInput = useRef(null);

  const handlePhoto = (key) => {
    hiddenFileInput.current.click();
    setPhotoInput(key);
  };

  const handleInputPhoto = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = async () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = async () => {
          const width = img.width;
          const height = img.height;
          const isPortrait = width < height;
          const compressedImageDataURL = await compressImage(
            reader.result,
            0.4
          );
          let key = photoInput;
          switch (key) {
            case "photo":
              setStaff({
                ...staff,
                photo: compressedImageDataURL,
              });
              break;
            case "businessCard":
              setStaff({
                ...staff,
                businessCard: compressedImageDataURL,
              });
              break;
            default:
              break;
          }
          setPhotoInput(null);
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const compressImage = async (imageUrl, quality) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(
          (blob) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          },
          "image/jpeg",
          quality
        );
      };
    });
  };

  const handleInputText = (e) => {
    setStaff({ ...staff, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    let sub = JSON.parse(localStorage.getItem("sub"));

    console.log(sub);
    console.log(staff);
    updateUser(sub, staff, (response) => {
      if (response?.status === "success") {
        navigate("/dashboard");
      } else {
        alert("Error updating profile");
      }
    });
  };

  return (
    <div className="page-wrapper-tab">
      <div className="text-center">
        <img
          src={LogoBlackText}
          alt="LogoBlackText"
          className="welcome-logo mb20"
        />
        <GoalProgressLine rate={3} max={3} />
        <MdChevronLeft
          size={"4rem"}
          style={{ position: "absolute", top: "10rem", left: "16vw" }}
          onClick={() => setActiveTab((prev) => prev - 1)}
        />
        <h2 className="bold mt20">Hey {staff?.firstname}! Profile yourself</h2>
        <h3 className="mt10">
          Before profiling your hotel, we would like to know more about you
        </h3>
      </div>
      <div className="column-view items-center r8 mt20">
        <div className="row-view">
          <div className="column-view items-center mr20">
            <div className="row-view">
              {!staff?.photo ? (
                <div
                  className="border-container b-dashed b2 r8 p20"
                  onClick={(e) => handlePhoto("photo")}
                >
                  <img src={addImg} alt="addImg" />
                </div>
              ) : (
                <div className="p20 relative">
                  <img
                    className="r8"
                    src={staff?.photo}
                    alt="staffPhoto"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    className="close-icon"
                    onClick={() => setStaff({ ...staff, photo: null })}
                  >
                    <MdClose size={20} color="white" />
                  </div>
                </div>
              )}
            </div>
            <div className="subtitle mt10">Profile picture</div>
            <div className="section-subtitle mt5">(JPG or PNG)</div>
            <input
              type="file"
              accept="image/png, image/jpeg"
              ref={hiddenFileInput}
              onChange={(e) => handleInputPhoto(e)}
              style={{ display: "none" }}
            />
          </div>
          <div className="column-view items-center ml20">
            <div className="row-view">
              {!staff?.businessCard ? (
                <div
                  className="border-container b-dashed b2 r8 p20 mr20"
                  onClick={(e) => handlePhoto("businessCard")}
                >
                  <img src={addImg} alt="addImg" />
                </div>
              ) : (
                <div className="p20 mr20 relative">
                  <img
                    className="r8"
                    src={staff?.businessCard}
                    alt="businessCard"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    className="close-icon"
                    onClick={() => setStaff({ ...staff, businessCard: null })}
                  >
                    <MdClose size={20} color="white" />
                  </div>
                </div>
              )}
            </div>
            <div className="subtitle mt10">Business card</div>
            <div className="section-subtitle mt5">(JPG or PNG)</div>
            <input
              type="file"
              accept="image/png, image/jpeg"
              ref={hiddenFileInput}
              onChange={(e) => handleInputPhoto(e)}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div className="p20 mt20">
          <div className="section-title flex04">Linkedin link (Optional)</div>
          <div className="row-view flex1 items-center">
            <input
              className="input-text w300 ph20 mt10"
              required
              name="linkedin"
              placeholder="Your Linkedin link..."
              value={staff?.linkedin}
              onChange={(e) => handleInputText(e)}
            />
          </div>
        </div>
      </div>
      <div>
        <h3>
          Please note that your profile <b>needs to be verified</b> in order to
          gain access to your <b>new back-office</b>. Verification is done with
          the hotel owner. Once verified, we will get back to you as soon as
          possible to <b>give you your new access</b>.
        </h3>
        <div className="primary-button r100 w300" onClick={handleSubmit}>
          Continue
        </div>
        <div className="column-view flex04 text-center justify-end pv20">
          <div>
            By continuing, you agree to the{" "}
            <a
              href="https://mymatchingcompany.com/mentions-legales/"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://mymatchingcompany.com/mentions-legales/"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              Privacy policy
            </a>
          </div>
          <div className="mt20">All rights reserved.</div>
          <div>©Myhotelmatch {new Date().getFullYear()}</div>
        </div>
      </div>
    </div>
  );
};

export default PhotoTab;
