import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

export const LoginForm = ({ email, setEmail, password, setPassword, handleLogin, setActiveForm, errorMessage }) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <form
            className='login-container'
            onSubmit={(e) => {
                e.preventDefault();
                handleLogin(email, password);
            }}
        >
            <div>Email address</div>
            <input
                required
                className='input-text mt10'
                type='email'
                placeholder='Email address'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <div className='mt10'>Password</div>
            <div className='column-view relative'>
                <input
                    required
                    className={`input-text mt10`}
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div className='absolute cursor-pointer' style={{ right: 10, top: '50%', transform: 'translateY(-25%)' }} onClick={() => setShowPassword(prev => !prev)}>
                    {showPassword ?
                        <IoMdEyeOff size={20} />
                        :
                        <IoMdEye size={20} />
                    }
                </div>
            </div>

            <button
                type='submit'
                className={`primary-button mt20 ${password?.length === 0 || email.length === 0 ? 'opacity60' : ''}`}
                disabled={password?.length === 0 || email.length === 0}
            >
                Login
            </button>
            {errorMessage !== '' && <div className='error-message mt10'>{errorMessage}</div>}
            <Link className='mt20 link none-decoration-link text-center' onClick={() => setActiveForm('forgot')}>
                Forgot your password?
            </Link>
        </form>
    );
};