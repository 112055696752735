export const iltmSearchHotel = async (search, callback) => {
    let url = `${process.env.REACT_APP_API_URL}/iltm/hotel/${search}`
    fetch(url, {
        headers: {
            "Content-Type": "application/json"
        },
        method: "GET"
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const iltmCheckUser = async (user, hotelSub, callback) => {
    let url = `${process.env.REACT_APP_API_URL}/iltm/user/${hotelSub}`
    fetch(url, {
        headers: {
            "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(user)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const iltmUpdateUser = async (user, sub, callback) => {
    let url = `${process.env.REACT_APP_API_URL}/iltm/user/update/${sub}`
    fetch(url, {
        headers: {
            "Content-Type": "application/json"
        },
        method: "PATCH",
        body: JSON.stringify(user)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const iltmRegister = async (data, callback) => {
    let url = `${process.env.REACT_APP_API_URL}/iltm/register`
    fetch(url, {
        headers: {
            "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(data)
    })
        .then((response) => {
            if (!response.ok) {
                console.error(response);
            }
            return response.json();
        })
        .then((json) => {
            console.debug(json);
            callback(json);
        })
        .catch((error) => {
            console.error(error);
        });
};