import React, { useEffect, useState } from 'react';

import addImg from '../../assets/icons/AddImgOrange.svg';

import { MdChevronLeft, MdClose, MdCheck } from 'react-icons/md';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { BiSolidChevronDown } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import useHotelApi from '../../api/hotel';
import colors from '../../colors/colors';
import { createUser } from '../../api/user';
import { DtCalendar } from 'react-calendar-datetime-picker'
import moment from 'moment';

export const AddOffer = ({ handleLogout }) => {
    const { getHotelListLazy } = useHotelApi();
    const navigate = useNavigate();
    const [infoModal, setInfoModal] = useState(false);
    const [hotelsSearch] = useState('');
    const [details, setDetails] = useState([]);
    const [hotel, setHotel] = useState({});
    const [hotels, setHotels] = useState([]);
    const [manager, setManager] = useState({});
    const [roomOpen, setRoomOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);
    const [rooms, setRooms] = useState({});
    const [profiles, setprofiles] = useState({});
    const currentDate = moment();
    const [newDate, setNewDate] = useState(null);
    const [hotelSub, setHotelSub] = useState('')

    const minDate = {
        year: parseInt(currentDate.format('YYYY')),
        month: parseInt(currentDate.format('MM')),
        day: parseInt(currentDate.format('DD'))
    };

    const [offer, setOffer] = useState({
        room: '',
        details: '',
        discount: '',
        available: false,
    })

    const hiddenFileInput = React.useRef(null);

    const handlePhoto = () => {
        hiddenFileInput.current.click();
    };

    const handleInputPhoto = (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;

                img.onload = () => {
                    if (img.width >= 150 && img.height >= 150) {
                        setOffer({ ...offer, photo: e.target.result });
                    } else {
                        alert('Image size must be at least 150x150 pixels ')
                    }
                }
            }

            reader.readAsDataURL(fileUploaded);
        }
    };

    const handleInputText = (e) => {
        setOffer({ ...offer, [e.target.name]: e.target.value });
    }

    const handleSelect = (key, value) => {
        setOffer({ ...offer, [key]: [value] });
    }

    const handleSubmit = () => {
        if (details?.length === 0) {
            alert("Select a role!")
            return 0;
        }

        let user = {
            ...offer,
            details: details
        }

        if (!manager?.sub && details?.includes('hotelStaff')) {
            alert("Select a manager !")
            return 0;
        }

        user["manager_sub"] = manager.sub

        if (hotel?.sub) {
            user["hotel_sub"] = hotel.sub
        }

        createUser(user, response => {
            if (response?.status === 'success') {
                navigate(-1);
            } else {
                alert(response?.message);
            }
        })
    }

    const handleRoomsProfiles = () => {

        // getHotelRoomList(hotelSub, response => {
        //     if (response?.status === 'success') {
        //         setRooms(response?.content)
        //         setRoomsCount(response?.content?.length)
        //         console.log(response)
        //     }
        // })

        // getHotelProfileList(hotelSub, response => {
        //     if (response?.status === 'success') {
        //         setProfiles(response?.content)
        //         setProfilesCount(response?.content?.length)
        //     }
        // })
    }

    useEffect(() => {
        let token = (localStorage.getItem('auth'));
        if (token) {
            const expiration = JSON.parse(localStorage.getItem('expiration'));
            if (expiration < Date.now() / 1000) {
                handleLogout();
            }
        }
        retrieveHotels(hotelsSearch)
        handleRoomsProfiles(hotelSub)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleLogout]);

    const retrieveHotels = (search) => {
        getHotelListLazy(0, 10, search, undefined, response => {
            if (response?.status === 'success') {
                setHotels(response?.content?.hotels)
            }
        })
    }

    const handleRemoveSelect = (key, value) => {
        if (offer[key].includes(value)) {
            setOffer({ ...offer, [key]: offer[key].filter(el => el !== value) })
        }
    }
    return (
        <form
            className={`page-container`}
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            {infoModal &&
                <div
                    className='filter-darker'
                    onClick={() => {
                        if (infoModal) {
                            setInfoModal(false);
                        }
                    }}
                />
            }
            <div className='row-view items-center'>
                <MdChevronLeft
                    size={50}
                    onClick={() => navigate(-1)}
                />
                <div className='title-secondary'>Add Offer</div>
            </div>

            <div className='subtitle mt50 ml50'>General information*</div>
            <div className='border-container r8 mt20'>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Offer name*</div>
                    <div className='row-view flex1 items-center'>
                        <input className='input-text w300 ph20' required name='offername' placeholder='Offer name...' value={offer?.offerName} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Picture</div>
                    <div className='row-view flex1 items-center'>
                        <div className='row-view items-center'>
                            {!offer?.photo ?
                                <div
                                    className='border-container b-dashed b2 r8 p20 mr20'
                                    onClick={(e) => handlePhoto(e)}
                                >
                                    <img
                                        src={addImg}
                                        alt='addImg'
                                    />
                                </div>
                                :
                                <div
                                    className='p20 mr20 relative'
                                    onClick={(e) => handlePhoto(e)}
                                >
                                    <img
                                        className='r8'
                                        src={offer?.photo}
                                        alt="offerPhoto"
                                        style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                                    />
                                    <div
                                        className='close-icon'
                                        onClick={() => setOffer({ ...offer, photo: null })}
                                    >
                                        <MdClose size={20} color='white' />
                                    </div>
                                </div>
                            }
                            <div className='section-subtitle'>(JPGs or PNGs of at least 150x150px)</div>
                        </div>
                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            ref={hiddenFileInput}
                            onChange={(e) => handleInputPhoto(e)}
                            style={{ display: 'none' }}
                        />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Room & Rates</div>
                    <div className='row-view flex1 items-center'>
                        <div className="relative">
                            <div
                                className="border-container bgFAFAFA row-view items-center justify-between r8 pv12 ph20 cursor-pointer" style={{ paddingRight: '11vw' }}
                                onClick={() => {
                                    setRoomOpen(!roomOpen);
                                    handleRoomsProfiles(hotelSub);
                                    console.log(hotelSub)
                                }}
                            >
                                <div>Select rooms</div>
                                <BiSolidChevronDown />
                            </div>
                            <div className={`select-menu ${roomOpen && 'open'}`}>
                                {rooms?.length !== 0 && rooms.length ? (
                                    <>
                                        {rooms?.map((room, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="select-item"
                                                    onClick={() => {
                                                        handleSelect(
                                                            'room',
                                                            room
                                                        )
                                                        setRoomOpen(false)
                                                    }}
                                                >
                                                    <span className="section-title">
                                                        {room.name}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <div className="select-item">
                                            No room found
                                        </div>
                                    </>
                                )}
                            </div>
                            {offer?.room?.length !== 0 && offer?.room?.map((r, i) => {
                                return (
                                    <div key={i} className='border-container r8 row-view items-center justify-between w300 ph20 pv12 mt10'>
                                        <div>{r.name} </div>
                                        <MdClose className='cursor-pointer' onClick={() => handleRemoveSelect('room', r)} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Details*</div>
                    <div className='column-view flex1'>
                        <div className='bgFAFAFA row-view items-center w300 p20 b r8 cursor-pointer' onClick={() => setDetails(['basicDeal'])}>
                            <div className={`checkbox r4 mr10 ${details && details?.includes('basicDeal') && 'selected'}`}>
                                {details?.includes('basicDeal') && <MdCheck color='white' />}
                            </div>
                            <div>Basic deal</div>
                        </div>
                        <div className='bgFAFAFA row-view items-center w300 p20 mt10 b r8 cursor-pointer' onClick={() => setDetails(['minimumStay'])}>
                            <div className={`checkbox r4 mr10 ${details && details?.includes('minimumStay') && 'selected'}`}>
                                {details?.includes('minimumStay') && <MdCheck color='white' />}
                            </div>
                            <div>Minimum stay</div>
                        </div>
                        <div className='bgFAFAFA row-view items-center w300 p20 mt10 b r8 cursor-pointer' onClick={() => setDetails(['creditCardRequired'])}>
                            <div className={`checkbox r4 mr10 ${details && details?.includes('creditCardRequired') && 'selected'}`}>
                                {details?.includes('creditCardRequired') && <MdCheck color='white' />}
                            </div>
                            <div>Credit card required</div>
                        </div>
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Discount*</div>
                    <div className='row-view flex1 items-center'>
                        <input className='input-text w300 ph20' name='discount' placeholder='Ex 12 %' value={offer?.position} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className="row-view items-center p20 bb">
                    <div className="section-title flex04">Target audience*</div>
                    <div className='row-view flex1 items-center'>
                        <div className="relative">
                            <div
                                className="border-container bgFAFAFA row-view items-center justify-between r8 pv12 ph20 cursor-pointer" style={{ paddingRight: '9vw' }}
                                onClick={() => setProfileOpen(!profileOpen)}
                            >
                                <div>Select hotel profile</div>
                                <BiSolidChevronDown />
                            </div>
                            <div className={`select-menu ${profileOpen && 'open'}`}>
                                {profiles?.length !== 0 && profiles.length ? (
                                    <>
                                        {profiles.map((profile, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="select-item"
                                                    onClick={() => {
                                                        handleSelect(
                                                            'profile',
                                                            profile
                                                        )
                                                        setProfileOpen(false)
                                                    }}
                                                >
                                                    <span className="section-title">
                                                        {profile.name}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <div className="select-item">
                                            No profile found
                                        </div>
                                    </>
                                )}
                            </div>
                            {offer?.profile?.length !== 0 && offer?.profile?.map((r, i) => {
                                return (
                                    <div key={i} className='border-container r8 row-view items-center justify-between w300 ph20 pv12 mt10'>
                                        <div>{r.name} </div>
                                        <MdClose className='cursor-pointer' onClick={() => handleRemoveSelect('profile', r)} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Availability*</div>
                    <div className='row-view flex1 items-center'>
                        <div className='row-view items-center w300'>
                            <div className={`toggle mr10 ${offer?.available && 'checked'}`} onClick={() => setOffer({ ...offer, available: !offer?.available })}>
                                <div className={`toggle-span ${offer?.available && 'checked'}`} />
                            </div>
                            <div className='section-title mr10'>Available</div>
                            <div className='relative row-view items-center'>
                                <BsFillQuestionCircleFill color={colors.primary} size={20} onClick={() => setInfoModal(!infoModal)} />
                                <div className={`info-modal ${infoModal && 'active'}`}>
                                    Is this hotelier unavailable or on vacation? Update its status to <span className='primary-text-color bold'>"not available"</span> to notify every member. The hotelier will be <span className='primary-text-color bold'>hidden in the application</span> during this period.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row-view items-center p20'>
                    <div className='section-title flex04'>Dates*</div>
                    <div className='row-view flex1 items-center'>
                        <DtCalendar minDate={minDate} type={'range'} withTime={true} onChange={setNewDate}
                            inputClass='custom-input'
                            daysClass='custom-days'
                            headerClass='custom-header'
                            autoClose={false}
                        />
                    </div>
                </div>
            </div>

            <div className='row-view justify-end mt20'>
                <button className='secondary-button mr20' type='reset' onClick={() => navigate(-1)}>Cancel</button>
                <button className='primary-button' type='submit'>Create Offer</button>
            </div>
        </form >
    )
}