import React, { useState } from 'react';

import logoTextblack from '../../assets/logos/logo-text-black.svg';
import { resetPassword } from '../../api/auth';
import { useNavigate } from 'react-router-dom';
import { SetPasswordForm } from '../../components/Generic/SetPasswordForm';


export const SetPassword = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleVerify = () => {
        let data = {
            email: email,
            newPassword: password,
            pinCode: pinCode
        }
        resetPassword(data, response => {
            if (response?.status === 'success') {
                navigate('/')
            } else {
                setErrorMessage(response?.message)
            }
        })
    }

    return (
        <div className='column-view h100vh items-center justify-between'>
            <div className='column-view items-center flex03 mt20'>
                <img
                    src={logoTextblack}
                    alt='logoTextWhite'
                    className='logo'
                />
            </div>
            <div className='row-view items-center flex03'>
                <div className='title column-view text-center'>
                    <span>
                        You requested to <span className='bold'>reset your password</span>!
                    </span>
                    <span className='title-secondary2 mt20'>
                        Use your Pin Code to set your new password
                    </span>
                </div>
            </div>
            <div className='flex1'>
                <SetPasswordForm
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                    pinCode={pinCode}
                    setPinCode={setPinCode}
                    errorMessage={errorMessage}
                    handleVerify={handleVerify}
                />
            </div>
            <div className='column-view flex04 text-center justify-end pv20' >
                <div>
                    By continuing, you agree to the {' '}
                    <a
                        href='https://mymatchingcompany.com/mentions-legales/'
                        target='_blank'
                        rel="noreferrer"
                        className='link'
                    >
                        Terms of Service
                    </a>
                    {' '} and {' '}
                    <a
                        href='https://mymatchingcompany.com/mentions-legales/'
                        target='_blank'
                        rel="noreferrer"
                        className='link'
                    >
                        Privacy policy
                    </a>
                </div>
                <div className='mt20'>
                    All rights reserved.
                </div>
                <div>
                    ©Myhotelmatch {new Date().getFullYear()}
                </div>
            </div>
        </div>
    )
}