import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '../../../components/Generic/Loader';
import { listAdvantages } from '../../../utils/mock/HotelData';
import { getHotelAdvantageList, updateHotelAdvantage } from '../../../api/hotel_advantage';
import { CustomAlert } from '../../../components/Generic/CustomAlert';

const Advantages = ({ hotel_sub }) => {
    const [prevAdvantages, setPrevAdvantages] = useState([]);
    const [advantages, setAdvantages] = useState([]);
    const [isDisabled, setDisabled] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [addAdvantageTag, setAddAdvantageTag] = useState('');
    const [addAdvantageTagFocused, setAddAdvantageTagFocused] = useState(false);
    const addAdvantageTagRef = useRef(null);
    const [showAlert, setShowAlert] = useState(false);
    const [isFadingOut, setIsFadingOut] = useState(false);

    const containsObject = (obj, list) => {
        return list?.some(item => item?.icon === obj?.icon && item?.label === obj?.label);
    };

    const handleTag = (value) => {
        if (containsObject(value, advantages)) {
            setAdvantages(advantages?.filter(el => el.icon !== value.icon && el.label !== value.label));
        } else {
            setAdvantages([...advantages, value]);
        }
    };

    const handleSubmit = () => {
        updateHotelAdvantage(hotel_sub, advantages, response => {
            if (response?.status === 'success') {
                setPrevAdvantages([...advantages]);
                setShowAlert(true);
                setTimeout(() => { setIsFadingOut(true); }, 3000);
            }
        });
    };

    useEffect(() => {
        setLoading(false);
        getHotelAdvantageList(hotel_sub, response => {
            if (response?.status === 'success') {
                setAdvantages([...response?.content]);
                setPrevAdvantages([...response?.content]);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDisabled(JSON.stringify(prevAdvantages) === JSON.stringify(advantages));
    }, [prevAdvantages, advantages]);

    useEffect(() => {
        if (isFadingOut) {
            setTimeout(() => {
                setShowAlert(false);
                setIsFadingOut(false);
            }, 500);
        }
    }, [isFadingOut]);

    return (
        <div>
            <>
                {isLoading ?
                    <Loader />
                    :
                    <>
                        {showAlert && (
                            <div className={`row-view alert-container justify-center ${isFadingOut ? 'fade-out-from-bottom' : 'fade-in-from-top'}`}>
                                <CustomAlert
                                    message="Advantages updated successfully!"
                                    handleClose={() => {
                                        setIsFadingOut(true);
                                    }}
                                />
                            </div>
                        )}
                        <div className='row-view justify-between'>
                            <div>
                                <div className='size2 bold'>Advantages</div>
                                <div className='size1 bold today'><span>{prevAdvantages?.length} advantages</span></div>
                            </div>
                        </div>
                        <div className='row-view items-center border-container p20 r8 mt20'>
                            <div className='section-title flex04'>Advantages</div>
                            <div className="flex1">
                                <div>
                                    <div className="row-view items-center wrap flex1">
                                        {listAdvantages.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={`row-view tag mr20 mt10 mb10 ${advantages && containsObject(item, advantages) &&
                                                        'selected'
                                                        }`}
                                                    onClick={() => handleTag(item)}
                                                >
                                                    <div>{item.icon} {item.label}</div>
                                                </div>
                                            );
                                        })}
                                        {advantages?.filter(el => !containsObject(el, listAdvantages))?.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={`row-view tag mr20 mt10 mb10 ${advantages && containsObject(item, advantages) &&
                                                        'selected'
                                                        }`}
                                                    onClick={() => handleTag(item)}
                                                >
                                                    <div>{item.icon} {item.label}</div>
                                                </div>
                                            );
                                        })}
                                        <input
                                            type='text'
                                            ref={addAdvantageTagRef}
                                            placeholder={!addAdvantageTagFocused && '+Add tag'}
                                            className={`borderless none-outline mr20 mt10 mb10 ${addAdvantageTagFocused && 'tag'}`}
                                            size={addAdvantageTag.length || 4}
                                            value={addAdvantageTag}
                                            onChange={(e) => setAddAdvantageTag(e.target.value)}
                                            onFocus={() => setAddAdvantageTagFocused(true)}
                                            onBlur={() => {
                                                let tag = { icon: '🏷️', label: addAdvantageTag };
                                                if (addAdvantageTag.length < 1 || containsObject(tag, advantages)) {
                                                    setAddAdvantageTag('');
                                                    setAddAdvantageTagFocused(false);
                                                    addAdvantageTagRef.current.blur();
                                                    return;
                                                }
                                                let tags = advantages ?? [];
                                                let prevTags = prevAdvantages ?? [];
                                                tags.push(tag);
                                                setAdvantages(tags);
                                                setDisabled(JSON.stringify(prevTags) === JSON.stringify(tags));
                                                setAddAdvantageTag('');
                                                setAddAdvantageTagFocused(false);
                                                addAdvantageTagRef.current.blur();
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    addAdvantageTagRef.current.blur();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row-view justify-end mt20'>
                            <div
                                className={`primary-button ${isDisabled ? 'opacity60' : ''}`}
                                onClick={() => {
                                    if (!isDisabled) {
                                        handleSubmit();
                                    }
                                }}
                            >
                                Save modifications
                            </div>
                        </div>
                    </>
                }
            </>
        </div>
    );
};

export default Advantages;