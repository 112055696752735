import React from 'react';
import LogoBlackText from '../../assets/logos/logo-text-black.svg';
import { GoalProgressLine } from '../../components/ProgressLine/GoalProgressLine';
import { MdChevronLeft, MdClose } from 'react-icons/md';
import colors from '../../colors/colors';

const PersonaleInformationTab = ({ setActiveTab, staff, setStaff }) => {
    const handleInputText = (e) => {
        setStaff({ ...staff, [e.target.name]: e.target.value });
    };

    return (
        <div className='page-wrapper-tab'>
            <div className='text-center'>
                <img src={LogoBlackText} alt='LogoBlackText' className='welcome-logo mb20' />
                <GoalProgressLine rate={1} max={3} />
                <MdChevronLeft size={'4rem'} style={{ position: 'absolute', top: '10rem', left: '16vw' }} onClick={() => setActiveTab(prev => prev - 1)} />
                <h2 className='bold mt20'>Hey {staff?.firstname}! Profile yourself</h2>
                <h3 className='mt10'>Verify information</h3>
            </div>
            <div className='border-container r8 mt20'>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>First name*</div>
                    <div className='row-view flex1 items-center'>
                        <input className='input-text w300 ph20' required name='firstname' placeholder='First name...' value={staff?.firstname} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Last name*</div>
                    <div className='row-view flex1 items-center'>
                        <input className='input-text w300 ph20' required name='lastname' placeholder='Last name...' value={staff?.lastname} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Position</div>
                    <div className='row-view flex1 items-center'>
                        <input className='input-text w300 ph20' name='position' placeholder='Position...' value={staff?.position} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className='row-view items-center p20 bb'>
                    <div className='section-title flex04'>Email address*</div>
                    <div className='row-view flex1 items-center'>
                        <input disabled className='input-text w300 ph20' required name='email' placeholder='Email address...' value={staff?.email} onChange={(e) => handleInputText(e)} />
                    </div>
                </div>
                <div className="row-view items-center p20">
                    <div className="section-title flex04">Phone number*</div>
                    <div className='column-view flex1 justify-between'>
                        {staff?.phone && staff?.phone?.map((item, index) => {
                            return (
                                <div key={index} className='row-view items-center gap20 wrap mb10'>
                                    <input
                                        className="input-text w300 ph20"
                                        name="phone"
                                        placeholder="Phone number..."
                                        value={item}
                                        onChange={(e) => {
                                            let phones = staff?.phone;
                                            phones[index] = e.target.value;
                                            setStaff({ ...staff, phone: phones });
                                        }}
                                    />
                                    {staff?.phone?.length > 1 && <MdClose className='cursor-pointer' color={colors.primary} size={25} onClick={() => setStaff({ ...staff, phone: staff.phone.splice(item, 1) })} />}
                                </div>
                            );
                        })}
                        <div className='row-view'>
                            <div className='secondary-button borderless p12 grey-text' onClick={() => setStaff({ ...staff, phone: [...staff?.phone, ''] })}>+Add phone</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div
                    className='primary-button r100 w300'
                    onClick={() => setActiveTab(prev => prev + 1)}
                >
                    Continue
                </div>
                <div className='column-view flex04 text-center justify-end pv20' >
                    <div>
                        By continuing, you agree to the {' '}
                        <a
                            href='https://mymatchingcompany.com/mentions-legales/'
                            target='_blank'
                            rel="noreferrer"
                            className='link'
                        >
                            Terms of Service
                        </a>
                        {' '} and {' '}
                        <a
                            href='https://mymatchingcompany.com/mentions-legales/'
                            target='_blank'
                            rel="noreferrer"
                            className='link'
                        >
                            Privacy policy
                        </a>
                    </div>
                    <div className='mt20'>
                        All rights reserved.
                    </div>
                    <div>
                        ©Myhotelmatch {new Date().getFullYear()}
                    </div>
                </div>
            </div>
        </div >
    );
};

export default PersonaleInformationTab;